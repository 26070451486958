import React, { useEffect, createContext, useState } from 'react';
import { useSelector } from 'react-redux'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getListV2, getListV3, getListV9 } from '../../../services';
import { getFechaFin, getFechaFinApi, getFechaInicio, getFechaInicioApi } from '../../../services/Utilitario';
import { toast } from 'react-toastify';

export const ReporteFacturaContext = createContext();

const ReporteFacturaProvider = (props) => {

    const identificacionUser = useSelector(store => store.app.user.usuario);
    const idUsuario = useSelector(store => store.app.user.identificacion);

    const empresa = useSelector(store => store.empresa.empresa);
    const [start, setStart] = useState(0);

    const initialStatePaginacion = {
        pagina: 1,
        _totalpaginas: 1,
        _totalelementos: 0,
        _links: [],
        _inicioRegistro: 1,
        _totalEnPagina: 0,
        _paginaActual: 1
    }
    const [Paginacion, setPaginacion] = useState(initialStatePaginacion);
    const [InfoTrazabilidad, setInfoTrazabilidad] = useState("");
    const [CorreosId, setCorreosId] = useState('');
    const [EditCorreos, setEditCorreos] = useState([]);
    const [documentos, setDocumentos] = useState([]);
    const [cargandoData, setCargandoData] = React.useState(false);
    const [AnularElement, setAnularElement] = useState(null);
    const [AutorizarElement, setAutorizarElement] = useState(null);
    const [IconOrder, setIconOrder] = useState(3);


    const formik = useFormik({
        initialValues: {
            establecimiento: '',
            puntoEmision: '',
            secuencial: '',
            estado: '',
            cliente: '',
            tipoFecha: '1',
            fechaFin: getFechaFin(),
            fechaInicio: getFechaInicio(),
            TipoDocumento: '',
            Cantidad: 50
        },
        validationSchema: Yup.object({
            /*establecimiento: Yup.string()
                .max(3, 'Tamaño del campo 3'),
            puntoEmision: Yup.string()
                .max(3, 'Tamaño del campo 3'),*/
            /*secuencial: Yup.string()
                .max(9, 'Tamaño del campo 9'),*/
            receptor: Yup.string()
                .max(20, 'Tamaño del campo 20'),
            estado: Yup.string()
                .max(300, 'Tamaño del campo 300'),
            tipoFecha: Yup.string()
                .required('El Tipo de fecha es obligatorio'),
        }),
        onSubmit: async obj => {
            try {
            
                const fechaInicio = obj.fechaInicio;
                const fechaFin = obj.fechaFin;
                setCargandoData(true);

                if (isSameMonth(fechaInicio, fechaFin)) {
                    await fetchData(obj.TipoDocumento, obj.Cantidad, obj.tipoFecha, fechaInicio, fechaFin, obj.establecimiento, obj.puntoEmision, obj.estado, obj.cliente, obj.secuencial);
                } else {
                    toast.info("Las fechas seleccionadas no son correctas. Por favor, selecciona fechas dentro de 31 días.");
                }
            } catch (error) {
                // console.log(error)
                setCargandoData(false);
            }
            setCargandoData(false);
        }
    });

    function isSameMonth(startDate, endDate) {
        const start = new Date(startDate + 'T00:00:00');
        const end = new Date(endDate + 'T00:00:00');

        const timeDifference = Math.abs(end - start);
        const dayDifference = timeDifference / (1000 * 3600 * 24); 

        return dayDifference <= 31;
    }

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async (
        TipoDocumento = "",
        Cantidad = 50,
        tipoFecha = 1,
        fechaInicio = getFechaInicio(),
        fechaFin = getFechaFin(),
        establecimiento = "",
        puntoEmision = "",
        estado = "",
        cliente = "",
        secuencial = ""
    ) => {
        try {
            const res = await getListV9(
                
                'api/DocumentoElectronicoRecibido/',
                    
                `${idUsuario}?fechaInicio=${getFechaInicioApi(fechaInicio)}&fechaFin=${getFechaFinApi(fechaFin)}&TipoFecha=${tipoFecha}&TipoDocumento=${TipoDocumento}&Pagina=${Paginacion.pagina}&Cantidad=${Cantidad}&ClienteRuc=${identificacionUser}`
            );
            if (!(res === undefined)) {
                setPaginacion({
                    ...Paginacion,
                    _totalpaginas: res._totalpaginas,
                    _totalelementos: res._totalelementos,
                    _links: res._links,
                    _inicioRegistro: res._inicioRegistro,
                    _totalEnPagina: res._totalEnPagina,
                    _paginaActual: res._paginaActual
                })
                setDocumentos(res._embedded);
                setIconOrder(3);
            }
        } catch (error) {
            setPaginacion(initialStatePaginacion);
            setDocumentos([]);
            setCargandoData(false);
        }
    }

    return (
        <ReporteFacturaContext.Provider value={{ start, setStart, documentos, cargandoData, formik, Paginacion, setPaginacion, initialStatePaginacion, InfoTrazabilidad, setInfoTrazabilidad, EditCorreos, setEditCorreos, CorreosId, setCorreosId, AnularElement, setAnularElement, IconOrder, setIconOrder, AutorizarElement, setAutorizarElement }}>
            {
                props.children
            }
        </ReporteFacturaContext.Provider>
    )
}

export default ReporteFacturaProvider;