import { useSelector } from "react-redux";
import {
  ModalStatusConfiguracion,
  changeConfiguracionModal,
} from "./modalConfiguracion.Slice";
import { TrazabilidadModal } from "./TrazabilidadModal";
import { MedidasModal } from "./MedidasModal";
import { ValoracionModal } from "./ValoracionModal";
import { useDispatch } from "react-redux";
import { ConfiguracionModalOff } from "./modalConfiguracion.Slice";
import { useEffect } from "react";

export const InventarioOpciones = () => {
  const ConfiguracionModalStatus = useSelector(
    (state) => state.modalConfiguracion.modalStatus
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname !== "/inventario/configuracion") {
      dispatch(ConfiguracionModalOff());
    }
  });

  return (
    <>
      <section
        style={{ padding: "10px" }}
        className="w-full lg:h-[400px] h-fit mt-5 relative bg-white rounded-lg"
      >
        <div
          className="bg-blue-900 rounded-lg p-12"
          style={{ height: "240px", width: "100%" }}
        >
          <h1 className="text-3xl text-white font-semibold">
            Configuracion del Inventario
          </h1>
          <div
            className="grid sm:grid-cols-1 gap-10 lg:grid-cols-3 bg-transparent md:grid-cols-2 h-full"
            style={{ width: "100%", padding: "50px" }}
          >
            <button
              onClick={() =>
                dispatch(
                  changeConfiguracionModal(ModalStatusConfiguracion.medidas)
                )
              }
              className="dark:bg-gray-700 gap-3 flex flex-col text-center bg-gray-50 hover:bg-[#E5F4FD] items-center justify-center rounded-lg shadow-xl hover:drop-shadow-xl divide-y divide-gray-200  hover:scale-105 duration-300 transition ease-in-out delay-150"
              style={{ minHeight: "170px", height: "200px", minWidth: "300px" }}
            >
              <img src="/ruler-2.png" alt="Medidas" className="w-24 h-24" />
              Unidades de Medida
            </button>
            <button
              onClick={() =>
                dispatch(
                  changeConfiguracionModal(
                    ModalStatusConfiguracion.trazabilidad
                  )
                )
              }
              className="dark:bg-gray-700 gap-3 flex flex-col text-center bg-gray-50 hover:bg-[#E5F4FD]  items-center justify-center rounded-lg shadow-xl hover:drop-shadow-xl divide-y divide-gray-200  hover:scale-105 duration-300 transition ease-in-out delay-150"
              style={{ minHeight: "150px", height: "200px", minWidth: "300px" }}
            >
              <img src="/route.png" alt="Tranzabilidad" className="w-24 h-24" />
              Trazabilidad
            </button>
            <button
              onClick={() =>
                dispatch(
                  changeConfiguracionModal(ModalStatusConfiguracion.valoracion)
                )
              }
              className="dark:bg-gray-700 gap-3 flex flex-col text-center bg-gray-50 hover:bg-[#E5F4FD] items-center justify-center rounded-lg shadow-xl hover:drop-shadow-xl divide-y divide-gray-200 hover:scale-105 duration-300 transition ease-in-out delay-150"
              style={{ minHeight: "150px", height: "200px", minWidth: "300px" }}
            >
              <img
                src="/checkup-list.png"
                alt="Valoracion"
                className="w-24 h-24"
              />
              Valoracion
            </button>
          </div>
        </div>
      </section>
      {ConfiguracionModalStatus === ModalStatusConfiguracion.medidas && (
        <MedidasModal />
      )}
      {ConfiguracionModalStatus === ModalStatusConfiguracion.trazabilidad && (
        <TrazabilidadModal />
      )}
      {ConfiguracionModalStatus === ModalStatusConfiguracion.valoracion && (
        <ValoracionModal />
      )}
    </>
  );
};
