import React, { useContext } from "react";
import NumberFormat from "react-number-format";
import { redondear, roundNumber } from "../../../services/Utilitario";

import { EmitirGuiaRemisionContext } from "./EmitirGuiaRemisionProvider";
import {
  TableStyle,
  TableStyleDiv,
  TableStyleTBody,
  TableStyleTHead,
} from "../../../features/Constantes";

const ListaDetalle = () => {
  const {
    DestinatariosList,
    setDestinatariosList,
    setActivarModalDetalle,
    setActivacion,
    setItemDetalle,
  } = useContext(EmitirGuiaRemisionContext);

  const handleDeleteDestinatario = (index) => {
    DestinatariosList.splice(index, 1);
    setDestinatariosList([...DestinatariosList]);
  };

  const handleDeleteElement = (index, indexItem) => {
    DestinatariosList[index].detalles.splice(indexItem, 1);
    setDestinatariosList([...DestinatariosList]);
  };

  const handleEditElement = (index, indexItem) => {
    let data = DestinatariosList[index].detalles[indexItem];
    data.index = index;
    data.indexItem = indexItem;

    setItemDetalle(data);
    setActivarModalDetalle(true);
    setActivacion(true);
  };

  console.log("DestinatariosList",DestinatariosList)

  return (
    <>
      {DestinatariosList.length < 1 ? (
        <></>
      ) : (
        <div className="border rounded-lg p">
          <div className="text-sm py-1.5  px-2 text-left bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-t-lg font-medium leading-6 text-white flex justify-between">
            <h3>Detalle / Destinatario</h3>
          </div>

          <div className="px-4 p-4">
            <div className={TableStyleDiv + " whitespace-nowrap"}>
              <table className={TableStyle}>
                <thead className={TableStyleTHead}>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3  text-sm font-semibold  sm:pl-6 w-44 "
                    >
                      Código
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3  text-sm font-semibold  sm:pl-6 w-[1800px] "
                    >
                      Detalle
                    </th>
                    <th className=" px-3 py-3.5  text-sm  font-semibold w-44 ">
                      Cant.
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-sm font-semibold sm:hidden sm:table-cell md:table-cell lg:table-cell w-44"
                    >
                      Opciones
                    </th>
                  </tr>
                </thead>
                <tbody className={TableStyleTBody}>
                  {DestinatariosList.map((dest, index) => {
                    return (
                      <>
                        <tr key={index} className="">
                          <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm ">
                            {dest.RazonSocialDestinatario} -{" "}
                            {dest.IdentificacionDestinatario} -{" "}
                            {dest.CodDocSustento === "01"
                              ? "Factura:"
                              : dest.CodDocSustento === null
                              ? "No Aplica Documento"
                              : "Otro:"}
                            {dest.NumDocSustento}
                            <button
                              onClick={() => handleDeleteDestinatario(index)}
                              className="text-red-500 ml-5 hover:text-red-600 text-xs "
                              style={{ verticalAlign: "top" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                        {dest.detalles.length === 0 ? (
                          <>
                            <tr>
                              <td
                                colSpan="4"
                                className="py-4 pl-4 sm:pl-6 pr-3 text-sm "
                              >
                                No hay detalles
                              </td>
                            </tr>
                          </>
                        ) : (
                          <>
                            {dest.detalles.map((el, index2) => {
                              return (
                                <>
                                  <tr key={index2}>
                                    <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm ">
                                      {el.codigoPrincipal}
                                    </td>
                                    <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm  truncate  w-[300px]  max-w-[300px] ">
                                      {el.descripcion}{" "}
                                      {el.informacionAdicionalServicioFactura === undefined
                                        ? null
                                        : el.informacionAdicionalServicioFactura === undefined
                                        ? null
                                        : el.informacionAdicionalServicioFactura.map(
                                            (el2, index) => (
                                              <span className="block font-medium text-xs text-gray-500">
                                                {el2.nombre} - {el2.valor}
                                              </span>
                                            )
                                          )}
                                    </td>

                                    <td className=" px-3 py-2 text-sm  lg:table-cell w-44 ">
                                      <NumberFormat
                                        decimalScale={6}
                                        value={el.cantidad}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                    </td>

                                    <td className=" py-3.5 text-sm flex items-center w-44 justify-center text-center">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleEditElement(index, index2)
                                        }
                                        className="text-gray-400 hover:text-gray-600 text-xs mr-2 focus:outline-none focus:shadow-outline-grayduration-150
       ease-in-out"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editDetalleGuiaModal"
                                        style={{ verticalAlign: "top" }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-5 w-5"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                          />
                                        </svg>
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleDeleteElement(index)
                                        }
                                        className="text-red-500 hover:text-red-600 text-xs "
                                        style={{ verticalAlign: "top" }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-5 w-5"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                          />
                                        </svg>
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </>
                        )}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ListaDetalle;