import { useContext } from "react";
import { getFechaV1, roundToTwo } from "../../../../services/Utilitario";
import useVistaPrevia from "../../hooks/useVistaPrevia";
import { CalcularImpuestosList } from "../services/impuestos";
import { EmitirFacturaContext } from "../EmitirFacturaProvider";
import { useSelector } from "react-redux";
import useFacturaExportacion from "./FacturaExportacion/useFacturaExportacion";

const useVistaPreviaFactura = () => {
  const {
    DetalleList,
    Totales,
    ActiveReembolso,
    ReembolsoList,
    FormaPagoList,
    InformacionAdicionalList,
    methods,
  } = useContext(EmitirFacturaContext);

  const empresa = useSelector((store) => store.empresa.empresa);

  const {
    GetVistaPreviaFactura: GetVistaPreviaRequest,
    GetVistaPreviaFacturaPTVenta,
    IsLoading,
    OpenVistaPrevia,
  } = useVistaPrevia();

  const { IsActiveExportacion } = useFacturaExportacion();

  const ParseVistaPreviaRequest = async () => {

    try {
      const data = methods.getValues();

      if (!IsActiveExportacion) data.exportacion = null;



      const ModelReembolso = ReembolsoList.map((x) => {
        let iva0 = "";
        let iva12 = "";
        let iva15 = "";
        let iva5 = "";
        let iva8 = "";
        let iva13 = "";
      
        x.DataReembolso.forEach((y) => {
          switch (y.Impuesto.porcentaje) {
            case 0.12:
              iva12 = y.SubTotalReembolso;
              break;
            case 0.0:
              iva0 = y.SubTotalReembolso;
              break;
            case 0.15:
              iva15 = y.SubTotalReembolso;
              break;
            case 0.05:
              iva5 = y.SubTotalReembolso;
              break;
            case 0.08:
              iva8 = y.SubTotalReembolso;
              break;
            case 0.13:
              iva13 = y.SubTotalReembolso;
              break;
            default:
              break;
          }
        });
        return {
          AutorizacionComprobante: x.AutorizacionComprobante,
          FechaEmisionComprobante: x.FechaEmisionComprobante,
          TipoDocumento: x.TipoDocumento,
          TipoProveedor: x.TipoProveedor,
          RucProveedor: x.RucProveedor,
          NumeroComprobante: x.NumeroComprobante,
          IdIva12: "A5B8F607-8D38-4150-97AF-C591F83678D8",
          IdIva0: "81BAF871-E113-48CF-8AA8-670CB43494B6",
          IdIva15: "BED68281-CF9C-458E-9E0F-517A8FB02528",
          IdIva5: "2ED52B34-BA6C-475B-A82D-0F7C933412A7",
          IdIva8: "20FA15DF-58A0-4546-9DB1-BEE1CCCACF3E",
          IdIva13: "47BFE2B8-3630-4F76-8727-2D6808B862AC",
          Iva12: iva12,
          Iva0: iva0,
          Iva15: iva15,
          Iva5: iva5,
          Iva8: iva8,
          Iva13: iva13,
        };
      });





      let parsedInfo = {
        IdentificacionCliente: data.clienteIdentificacion,
        RazonSocial: data.clienteRazonSocial,
        Direccion: data.clienteDireccion,
        TelefonoCelular: data.clienteTelefono,
        Correo: data.clienteEmail,
        FechaEmision: getFechaV1(data.fechaEmision),
        GuiaRemision: data.guiaRemision,
        Establecimiento: data.establecimiento,
        PuntoEmision: data.puntoEmision,
        Secuencial: data.secuencial,
        ImporteTotal:
        roundToTwo(Totales.importeTotal +
          (Number(data.exportacion?.fleteInternacional) || 0) +
          (Number(data.exportacion?.seguroInternacional) || 0) +
          (Number(data.exportacion?.gastosAduaneros) || 0) +
          (Number(data.exportacion?.gastosTransporteOtros) || 0)),
        TotalSinImpuestos: Totales.totalSinImpuesto,
        TotalDescuento: Totales.totalDescuentos,
        ListaImpuesto: await CalcularImpuestosList(DetalleList, empresa,Totales),
        TotalTotalIva0: 0,
        TotalTotalIva: roundToTwo(Totales.totalIva),
        TotalNoObjetoImpuesto: 0,
        TotalExentoIva: 0,
        TotalBaseIva0: Totales.subTotalIva0,
        TotalBaseIva: roundToTwo(Totales.subTotalIva),
        TotalBaseNoObjetoImpuesto: Totales.subTotalNoObjetoIva,
        TotalBaseExentoIva: Totales.subTotalExentoIva,
        Ambiente: process.env.REACT_APP_ENVIRONMENT,
        IdEmpresa: empresa.idEmpresa,
        RucEmpresa: empresa.ruc,
        CodDoc: 1,
        Reembolsos: ActiveReembolso ? [...ModelReembolso] : null,
        exportacion: IsActiveExportacion ? { ...data.exportacion } : null,
        InformacionAdicionalFactura: InformacionAdicionalList.map((x) => {
          return {
            Descripcion: x.nombre,
            Valor: x.valor,
          };
        }),
        FormaPagoFactura: FormaPagoList.map((x) => {
          return {
            UnidaDeTiempo: x.tiempoPago,
            Plazo: x.plazoPago,
            Total: x.valor,
            IdFormaPagoSri: x.tipoPago,
          };
        }),
        DetalleFactura: DetalleList.map((e) => {
          return {
            Descripcion: e.descripcion,
            Cantidad: e.cantidad,
            CodigoPrincipal: e.codigoPrincipal,
            CodigoAuxiliar: e.codigoAuxiliar,
            PrecioUnitario: e.precioUnitario,
            Descuento: e.descuento,
            ImpuestoCodigoIva: 2,
            ImpuestoCodigoPorcentajeIva: e.idImpuestoIvaNavigation.codigo,
            PrecioTotalSinImpuesto: roundToTwo(e.subTotal),
            ImpuestoBaseImponibleIva: roundToTwo(e.subTotal),
            ImpuestoValorIva:
              e.idImpuestoIvaNavigation.porcentaje === 0
                ? 0
                : roundToTwo(e.iva),
            Tarifa: roundToTwo(e.idImpuestoIvaNavigation.porcentaje * 100),
            DetalleServicioFactura:
              e.servicioDetalle === undefined
                ? []
                : e.servicioDetalle === null
                ? []
                : e.servicioDetalle.map((x) => {
                    return {
                      Descripcion: x.nombre,
                      Valor: x.valor,
                    };
                  }),
          };
        }),
        IdImpuestoNoObjectoImpuesto: DetalleList.find(
          (e) =>
            e.idImpuestoIva.toUpperCase() ===
            "8E7CF164-C66B-4312-945F-57AE08C86A33"
        )
          ? "8E7CF164-C66B-4312-945F-57AE08C86A33"
          : null,
        IdImpuestoExentoIva: DetalleList.find(
          (e) =>
            e.idImpuestoIva.toUpperCase() ===
            "F03C7F10-2773-4505-ACCB-B2FA88D62049"
        )
          ? "F03C7F10-2773-4505-ACCB-B2FA88D62049"
          : null,
        IdImpuestoIVA12: DetalleList.find(
          (e) =>
            e.idImpuestoIva.toUpperCase() ===
            "A5B8F607-8D38-4150-97AF-C591F83678D8"
        )
          ? "A5B8F607-8D38-4150-97AF-C591F83678D8"
          : null,
        IdImpuestoIVA0: DetalleList.find(
          (e) =>
            e.idImpuestoIva.toUpperCase() ===
            "81BAF871-E113-48CF-8AA8-670CB43494B6"
        )
          ? "81BAF871-E113-48CF-8AA8-670CB43494B6"
          : null,
      };
      if (data.clienteEmail !== null)
        if (data.clienteEmail.trim() !== "")
          parsedInfo.InformacionAdicionalFactura.push({
            Valor: data.clienteEmail,
            Descripcion: "email",
          });

          console.log(parsedInfo);
      return parsedInfo;
    } catch (error) {
      console.log("EOOOOOOOOOOOR" + error);
    }
  };

  const getVistaPreviaFactura = async () => {
   
    const factModel = await ParseVistaPreviaRequest();
    //console.log(factModel);
    const req = await GetVistaPreviaRequest(factModel);
    //console.log(req);
    OpenVistaPrevia(req);
  };

  const getVistaPreviaFacturaPTVenta = async () => {
    const factModel = await ParseVistaPreviaRequest();
    //console.log(factModel);
    const req = await GetVistaPreviaFacturaPTVenta(factModel);
    //console.log(req);
    OpenVistaPrevia(req);
  };

  return {
    getVistaPreviaFacturaPTVenta,
    getVistaPreviaFactura,
    IsLoading,
  };
};

export default useVistaPreviaFactura;