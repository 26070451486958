import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";

import { StyleInput, StyleSelect } from "../../../../features/Constantes";
import { getListV2 } from "../../../../services";
import MesageErrorTable from "../../../../features/components/MesageWarningTable";
import { useForm } from "react-hook-form";
import { roundToTwo } from "../../../../services/Utilitario";
import { ModalAddImpuestoContext } from "./ModalAddImpuestoProvider";
import { toast } from "react-toastify";

const FormaPagoSection = () => {
  const {
    setFormaPagoList,
    FormaPagoList,
    TotalImpToFormPago,
    RentaList
  } = useContext(ModalAddImpuestoContext);

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useForm("onSubmit");

  const empresa = useSelector((store) => store.empresa.empresa);

  const [TiposPago, setTiposPago] = useState([]);
  const [ValorPorPagar, setValorPorPagar] = useState(0);
  const [IsLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      let data = await getListV2(empresa, "GetFormasPago");
      setTiposPago(data._embedded);
      let defaultValue = data._embedded[0];
      setValue(
        "tipoPago",
        `${defaultValue.idFormaPagoSri}&${defaultValue.descripcion}`
      );
    })();
  }, [empresa]);

  useEffect(() => {
    calcularValorPagar();
  }, [TotalImpToFormPago, FormaPagoList]);


  const handleDeleteElement = (index) => {
    FormaPagoList.splice(index, 1);
    setFormaPagoList([...FormaPagoList]);

  };

  const handleAddFormaPago = (e) => {
    setIsLoading(true);
    let data = {
      tipoPago: getValues().tipoPago.split("&")[0],
      descripcionTipoPago: getValues().tipoPago.split("&")[1],
      valor: roundToTwo(ValorPorPagar),
    };

    if (data.valor < 0) {
      toast('Asegurate de dar un valor mayor a 0', { type: 'info', autoClose: 2000, closeOnClick: true });
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      return;
    }
    
    if (isNaN(data.valor)) {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      return;
    }

    if (data.valor === 0) {
      let formaPagoWithZero = FormaPagoList.filter((el) => el.valor === 0);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000)
      if (formaPagoWithZero.length > 0) return;
    }

    let newValues = [...FormaPagoList, data];
    setFormaPagoList(newValues);

    setTimeout(() => {
      setIsLoading(false);
    }, 2000)
  };

  /* Recibe el arreglo con los pagos para poder generar el valor a pagar */
  const calcularValorPagar = () => {
    let importeTotal = RentaList.reduce((total, el) => {
      let porcentajeImpuesto = JSON.parse(el.TipoImpuesto).porcentaje;
      return total + roundToTwo(el.BaseImponible * (porcentajeImpuesto + 1));
    }, 0);

    let total = FormaPagoList.reduce((total, el) => total + Number(el.valor), 0);

    setValorPorPagar(roundToTwo(importeTotal) - roundToTwo(total));
    return roundToTwo(importeTotal) - roundToTwo(total);

  };
  return (
    <div className="relative z-0 mb-2 w-full group bg-gray-50">
      <div className="w-full grid md:grid-cols-6 md:gap-2 mb-2 ">
        <div className="relative z-0 mb-2 col-span-4 w-full group">
          <label className="block text-sm font-medium text-black dark:text-white"> Tipo </label>
          <select
            type="text"
            className={StyleSelect}
            placeholder=" "
            name="tipoPago"
            id="tipoPago"
            {...register("tipoPago", {
              required: {
                value: true,
                message: "Nombre es requerido",
              },
              maxLength: {
                value: 14,
                message: "No más de 14 carácteres!",
              },
              minLength: {
                value: 14,
                message: "No más de 14 carácteres!",
              },
            })}
          >
            {TiposPago.map((el, index) => (
              <option
                key={index}
                value={`${el.idFormaPagoSri}&${el.descripcion}`}
              >
                {" "}
                {el.descripcion}{" "}
              </option>
            ))}
          </select>
        </div>


        <div className="relative z-0 mb-2 w-full group">
          <label className="block text-sm font-medium text-black dark:text-white">
            {" "}
            Valor{" "}
          </label>
          <input
            type="number"
            className={StyleInput}
            name="valor"
            id="valor"
            value={roundToTwo(ValorPorPagar)}
            onChange={(e) => setValorPorPagar(Number(e.target.value))}
          />
        </div>

        <div className="relative  z-0 pt-4 w-full group">
          <button
            type="submit"
            disabled={IsLoading}
            className=" flex  dark:hover:bg-gray-700 hover:bg-blue-700 bg-blue-600  rounded-lg p-2 items-center justify-between px-2   text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
            onClick={handleAddFormaPago}
          >
            {
              IsLoading ? "Cargando..." : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 dark:stroke-white text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              )
            }
          </button>
        </div>
      </div>
   
      {FormaPagoList.length > 0 &&
       <div className="rounded-md border  relative overflow-hidden ">
       <table className="w-full text-sm text-left text-gray-500 divide-y divide-gray-300">
         <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
           <tr>
             <th scope="col" className="py-1 px-6 ">
               Forma de pago
             </th>

             <th scope="col" className="py-1 px-6 ">
               Valor
             </th>
             <th scope="col" className="py-1 px-6 "></th>
           </tr>
         </thead>
         <tbody className="divide-y divide-gray-300">
           {FormaPagoList.length === 0 ? (
             <MesageErrorTable colSpan="4" message="Información requerida" />
           ) : null}
           {FormaPagoList.map((el, index) => (
             <tr key={index} className="bg-white dark:bg-gray-800">
               <th
                 scope="row"
                 className="py-0 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
               >
                 {el.descripcionTipoPago}
               </th>

               <td className="py-1 px-6">${(el.valor)}</td>
               <td className="py-1 px-6 text-red-500 hover:text-red-600 ">
                 <button onClick={() => handleDeleteElement(index)}>
                   <svg
                     xmlns="http://www.w3.org/2000/svg"
                     className="h-5 w-5"
                     fill="none"
                     viewBox="0 0 24 24"
                     stroke="currentColor"
                     strokeWidth="2"
                   >
                     <path
                       strokeLinecap="round"
                       strokeLinejoin="round"
                       d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                     />
                   </svg>
                 </button>
               </td>
             </tr>
           ))}
         </tbody>
       </table>
     </div>
      }
     
    </div>
  );
};

export default FormaPagoSection;
