import React from "react";
import useSecuencialLoader from "./hooks/SecuencialLoader/useSecuencialLoader";
import {
  getNoventaDiasAtras,
  rellenarCeros,
} from "../../../services/Utilitario";
import InputDate from "../../../features/components/common/InputDate";
import InputText from "../../../features/components/common/InputText";
import { useFormContext } from "react-hook-form";
import ActiveReembolsoButton from "../../../features/hooks/Reembolso/ActiveReembolsoButton";
import ActiveFacturaExportacionButton from "./hooks/FacturaExportacion/ActiveFacturaExportacionButton";

const HeaderFacturaOtros = (props) => {

  const { setValue } = useFormContext();

  return (
    <>
      <div className=" grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-2 w-full max-w-2xl  text-left">
        <div>
          <InputText
            onChange={(e) => {
              let valor = e.target.value.replace(/^(\d{3})(\d{3}).*/, "$1-$2-");
              setValue("guiaRemision", valor);
            }}
            maxLength={17}
            name={"guiaRemision"}
            title={"Guía remisión:"}
          />
        </div>
       
        {props.ShowExtendedButtons && (
        
        <div className="lg:flex mx-2 gap-1 items-end ">
          <div className="lg:block ">
            <ActiveReembolsoButton />
          </div>
          <div className="lg:block ">
            <ActiveFacturaExportacionButton />
          </div>
        </div>
    
    )}
      
     
      </div>
    </>
  );
};

export default HeaderFacturaOtros;
