import React, { useEffect, createContext, useState } from 'react';
import { useSelector } from 'react-redux'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getList, getListV2 } from '../../../services';
import { getFechaFin, getFechaFinApi, getFechaInicio, getFechaInicioApi } from '../../../services/Utilitario';

export const ReporteFacturaContext = createContext();

const ReporteFacturaUsuarioProvider = (props) => {
     
    const empresa = useSelector(store => store.empresa.empresa);
    const usuario = useSelector(store => store.app.user);
    const [start, setStart] = useState(0);

    const initialStatePaginacion = {
        pagina: 1,
        _totalpaginas: 1,
        _totalelementos: 0,
        _links: [],
        _inicioRegistro:0,
        _totalEnPagina:0
    }
    const [Paginacion, setPaginacion] = useState(initialStatePaginacion);
    const [InfoTrazabilidad, setInfoTrazabilidad] = useState("");
    const [CorreosId, setCorreosId] = useState('');
    const [EditCorreos, setEditCorreos] = useState([]);
    const [documentos, setDocumentos] = useState([])
    const [cargandoData, setCargandoData] = React.useState(false)

    const formik = useFormik({
        initialValues: {
            establecimiento: '',
            puntoEmision: '',
            secuencial: '',
            estado: '',
            cliente: '',
            tipoFecha: '3',
            fechaFin: getFechaFin(),
            fechaInicio: getFechaInicio(),
            TipoDocumento: '',
            Cantidad: 50
        },
        validationSchema: Yup.object({
            /*establecimiento: Yup.string()
                .max(3, 'Tamaño del campo 3'),
            puntoEmision: Yup.string()
                .max(3, 'Tamaño del campo 3'),*/
            /*secuencial: Yup.string()
                .max(9, 'Tamaño del campo 9'),*/
            receptor: Yup.string()
                .max(20, 'Tamaño del campo 20'),
            estado: Yup.string()
                .max(300, 'Tamaño del campo 300'),
            tipoFecha: Yup.string()
                .required('El Tipo de fecha es obligatorio'),
        }),
        onSubmit: async obj => {
            try {
                setCargandoData(true)

                await fetchData(obj.TipoDocumento, obj.Cantidad, obj.tipoFecha, obj.fechaInicio, obj.fechaFin, obj.establecimiento, obj.puntoEmision, obj.estado, obj.cliente, obj.secuencial);
                setCargandoData(false)
            } catch (error) {
              //  console.log(error)
            }

            setCargandoData(false)
        }
    });


    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async (
        TipoDocumento = "",
        Cantidad = 50,
        tipoFecha = 3,
        fechaInicio = getFechaInicio(),
        fechaFin = getFechaFin(),
        establecimiento = "",
        puntoEmision = "",
        estado = "",
        cliente = "",
        secuencial = ""
    ) => {
        try {
            const res = await getList(
                `api/DocumentoElectronico/BuscarDocumentoElectronicoUsuarioRecibido/${usuario.identificacion}/${empresa.idEmpresa}/${usuario.usuario}?Establecimiento=${establecimiento}&start=${start}&PuntoEmision=${puntoEmision}&fechaInicio=${getFechaInicioApi(fechaInicio)}&fechaFin=${getFechaFinApi(fechaFin)}&secuencial=${secuencial}&Estado=${estado}&TipoFecha=${tipoFecha}&Receptor=${cliente}&TipoDocumento=${TipoDocumento}&Pagina=${Paginacion.pagina}&Cantidad=${Cantidad}`
            )

            if (!(res === undefined)) {
                setPaginacion({
                    ...Paginacion,
                    _totalpaginas: res._totalpaginas,
                    _totalelementos: res._totalelementos,
                    _links: res._links,
                    _inicioRegistro:res._inicioRegistro,
                    _totalEnPagina:res._totalEnPagina
                })
                setDocumentos(res._embedded)

            }
            
        } catch (error) {
            setPaginacion(initialStatePaginacion);
            setDocumentos([]);
            setCargandoData(false);
           
            //console.log(error)
     
        }
    }

    return (
        <ReporteFacturaContext.Provider value={{start,setStart, documentos, cargandoData, formik, Paginacion, setPaginacion, initialStatePaginacion, InfoTrazabilidad, setInfoTrazabilidad, EditCorreos, setEditCorreos, CorreosId, setCorreosId }}>
            {
                props.children
            }
        </ReporteFacturaContext.Provider>
    )
}

export default ReporteFacturaUsuarioProvider;