import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import Logoo from "../../img/isologo.png";
import { getList, login, post } from "../../services";
import fondocubo from "../../img/fondo.jpg";
import cubos from "../../img/cubo.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import MesageError from "../../features/components/MessageError";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { loadUser, logout } from "../../redux/app.slice";
import { cargarEmpresas } from "../../features/NavBar/navBar.slice";
const Registro = () => {
  const loginIn = useSelector((store) => store.app.loginIn);
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      Identificacion: "",
      Username: "",
      Nombre: "",
      Email: "",
      Clave: "",
      ConfirmarClave: "",
    },
    validationSchema: Yup.object({
      Identificacion: Yup.string()
        .required()
        .matches(/^[0-9]+$/, "Cedula Incorrecta")
        .min(10, "min 10")
        .max(10, "max 10"),
      Username: Yup.string().required(),
      Nombre: Yup.string().required(),
      Email: Yup.string().email().required(),
      Clave: Yup.string()
        .required("Contraseña Requerida")
        .matches(
          /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/,
          "La contraseña debe contener letras Mayusculas y Minusculas caracteres especiales y números"
        ),
      ConfirmarClave: Yup.string()
        .required("Confirmar Contraseña Requerida")
        .oneOf([Yup.ref("Clave"), null], "Las contraseñas no coinciden"),
    }),
    onSubmit: async (obj) => {
      try {
        onSubmit(obj);
      } catch (error) {
       // console.log(error);
      }
    },
  });

  const handlesShow = () => {
    setShowPass(!showPass);
  };
  useEffect(() => {
    if (loginIn) navigate("/");
  });

  useEffect(() => {
    if (loginIn) navigate("/");
  }, [loginIn]);

  const loginUser = async (user, pass) => {
    dispatch(logout());

    const resp = await login(user, pass);
    if (resp.data._embedded.token) {
      localStorage.setItem("token", resp.data._embedded.token);
      axios.defaults.headers.Authorization = `Bearer ${resp.data._embedded.token}`;

      var usuarioLink = resp.data._links.find(
        (obj) => obj.descripcion === "obtener-usuario"
      );
      var empresasLink = resp.data._links.find(
        (obj) => obj.descripcion === "obtener-empresas-usuario"
      );

      dispatch(
        loadUser(
          (
            await axios.get(
              usuarioLink.enlace.replaceAll("http://", "https://")
            )
          ).data._embedded
        )
      );

      var empresas = await getList(
        empresasLink.enlace.replaceAll("http://", "https://")
      );

      dispatch(cargarEmpresas(empresas._embedded));

      navigate("/");
    }
  };

  const onSubmit = async (temp) => {
    try {
      var idToast = toast.loading("Registrando Espere...");
      let res = await post("/api/cuentas/registrar", temp);

      if (res.data === "repetido")
        toast.update(idToast, {
          render: "Intente con otra Identificación",
          type: "warning",
          isLoading: false,
          autoClose: 2000,
        });

      if (res.data === "ok") {
        toast.update(idToast, {
          render: "Registrado con Exito",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });

        setTimeout(() => {
          // navigate("/login");
          loginUser(temp.Identificacion, temp.Clave);
        }, 2000);
        return;
      }

      if (res.data === "error")
        toast.update(idToast, {
          render: "Error al registrar",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
    } catch (error) {
      if (error.code === "ERR_BAD_REQUEST") {
      }
      if (error.code === "ERR_NETWORK") {
        toast.update(idToast, {
          render: "Correo repetido, ingrese otro correo",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
        return;
      }
      if (error.code === "auth/user-not-found") {
      }

      if (error.code === "auth/wrong-password") {
      }

      toast.update(idToast, {
        render: "Error al registrar intente mas tarde",
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    }
  };

  return (
    <>
      <div className="flex bg-white dark:bg-gray-900  justify-between min-h-screen font-sans">
     

        <div className="relative overflow-hidden w-8/12 bg-center bg-cover hidden lg:block sm:hidden md:block xl:block ">
          <img
            alt="ad"
            className="absolute inset-0 h-full w-full  object-cover"
            src={fondocubo}
          />
          <div className=" justify-center w-full">
            <svg
              viewBox="0 0 558 558"
              fill="none"
              aria-hidden="true"
              className="animate-spin-slower inset-0 my-auto mx-auto absolute w-3/5 overflow-y-hidden"
            >
              <defs>
                <linearGradient
                  id=":R3b9m:"
                  x1="79"
                  y1="16"
                  x2="105"
                  y2="237"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#13B5C8"></stop>
                  <stop offset="1" stopColor="#13B5C8" stopOpacity="0"></stop>
                </linearGradient>
              </defs>
              <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Error icon</span>
              </div>

          

              <path
                opacity=".2"
                d="M1 279C1 125.465 125.465 1 279 1s278 124.465 278 278-124.465 278-278 278S1 432.535 1 279Z"
                stroke="#13B5C8"
              ></path>
              <path
                d="M1 279C1 125.465 125.465 1 279 1"
                stroke="url(#:R3b9m:)"
                strokeLinecap="round"
              ></path>
            </svg>
          </div>
          <img
            alt="ad"
            className="absolute inset-0 my-auto mx-auto w-5/12 floating "
            src={cubos}
          />
        </div>
        <div className=" max-w-2xl ">
          {/* <!-- flex-1 mx-auto max-w-2xl --> */}
          <div className="flex flex-col px-8 pt-10 lg:px-14 xl:px-24">
            <div className="inline-flex  ">
              <img
                src={Logoo}
                className=" self-center w-16 float-left mr-2 block"
                alt="asd"
              />
              <span
                className=" my-4 block truncate dark:text-white text-3xl font-body"
                style={{ fontWeight: 800 }}
              >
                Intuito S.A.
              </span>
            </div>

            <div className="pt-5 pb-6">
              <h1 className="text-3xl dark:text-white font-bold text-left tracking-wide leading-loose whitespace-nowrap">
                Registrarte
              </h1>
              <span className="font-light text-gray-500 dark:text-gray-300">
                De manera fácil
              </span>

              <div className="flex justify-between items-center pt-2">
                <hr className="w-full border-gray-400" />

                <hr className="w-full border-gray-400" />
              </div>

              <div className="w-full ml-4 text-center h-5 mt-3 text-red-500"></div>

              {/* <form onSubmit={handleSubmit(onSubmit)}> */}

              <form
                onSubmit={(e) => {
                  formik.handleSubmit(e);
                }}
              >
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="relative z-0 mb-6 w-full group">
                    <div className="absolute inset-y-0 left-0 pl-3 pt-6 flex items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 fill-gray-500"
                        viewBox="0 0 576 512"
                      >
                        <path d="M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm0 400H303.2c.9-4.5.8 3.6.8-22.4 0-31.8-30.1-57.6-67.2-57.6-10.8 0-18.7 8-44.8 8-26.9 0-33.4-8-44.8-8-37.1 0-67.2 25.8-67.2 57.6 0 26-.2 17.9.8 22.4H48V144h480v288zm-168-80h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0-64h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0-64h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm-168 96c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64z" />
                      </svg>
                    </div>

                    <label className="dark:text-gray-400 ">Cédula</label>
                    <input
                      type="text"
                      className={
                        formik.touched.Identificacion &&
                        formik.errors.Identificacion
                          ? ` is-invalid
                              block 
                              w-full
                              px-4 py-2  
                              text-xl 
                              font-normal
                              pl-10
                            
                              border focus:outline-none
                              rounded-lg transition
                              form-control dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:border-blue-600 border-gray-500 text-gray-700 dark:bg-gray-700 dark:focus:text-white dark:border-gray-600  dark:placeholder-gray-400 dark:text-white focus:text-gray-700`
                          : `block 
                              w-full
                              px-4 py-2  
                              text-xl 
                              font-normal
                              pl-10
                            
                              border focus:outline-none
                              rounded-lg transition
                              form-control dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:border-blue-600 border-gray-500 text-gray-700 dark:bg-gray-700 dark:focus:text-white dark:border-gray-600  dark:placeholder-gray-400 dark:text-white focus:text-gray-700`
                      }
                      placeholder=" "
                      name="Identificacion"
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="relative z-0 mb-6 w-full group">
                    <div className="absolute inset-y-0 left-0 pl-3 pt-6 flex items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 stroke-gray-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                        />
                      </svg>
                    </div>
                    <label className="dark:text-gray-400 ">
                      Nombre de usuario
                    </label>
                    <input
                      type="text"
                      className={
                        formik.touched.Username && formik.errors.Username
                          ? ` is-invalid
                          block 
                          w-full
                          px-4 py-2  
                          text-xl 
                          font-normal
                          pl-10
                        
                          border focus:outline-none
                          rounded-lg transition
                          form-control dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:border-blue-600 border-gray-500 text-gray-700 dark:bg-gray-700 dark:focus:text-white dark:border-gray-600  dark:placeholder-gray-400 dark:text-white focus:text-gray-700`
                          : `block 
                          w-full
                          px-4 py-2  
                          text-xl 
                          font-normal
                          pl-10
                        
                          border focus:outline-none
                          rounded-lg transition
                          form-control dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:border-blue-600 border-gray-500 text-gray-700 dark:bg-gray-700 dark:focus:text-white dark:border-gray-600  dark:placeholder-gray-400 dark:text-white focus:text-gray-700`
                      }
                      placeholder=" "
                      name="Username"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="relative z-0 mb-6 w-full group">
                  <div className="absolute inset-y-0 left-0 pl-3 pt-6 flex items-center ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                      className="w-6 h-6 fill-gray-500"
                    >
                      <path d="M630.6 364.9l-90.3-90.2c-12-12-28.3-18.7-45.3-18.7h-79.3c-17.7 0-32 14.3-32 32v79.2c0 17 6.7 33.2 18.7 45.2l90.3 90.2c12.5 12.5 32.8 12.5 45.3 0l92.5-92.5c12.6-12.5 12.6-32.7.1-45.2zm-182.8-21c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24c0 13.2-10.7 24-24 24zM48 463.8v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 7.7 0 15.1 1.3 22.2 3.3v-49c-7.3-1.2-14.6-2.2-22.2-2.2-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 303.9 0 364 0 438.3v25.6c0 26.5 21.5 48 48 48h352c9.7 0 18.7-2.9 26.3-7.9l-40.1-40.1H48zm176-175.9c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.4 80 144c0 79.5 64.5 143.9 144 143.9zM224 48c52.9 0 96 43 96 96 0 52.9-43.1 96-96 96s-96-43.1-96-96c0-53 43.1-96 96-96z" />
                    </svg>
                  </div>
                  <label className="dark:text-gray-400 ">
                    Nombres Completos
                  </label>
                  <input
                    type="text"
                    className={
                      formik.touched.Nombre && formik.errors.Nombre
                        ? ` is-invalid
                        block 
                        w-full
                        px-4 py-2  
                        text-xl 
                        font-normal
                        pl-10
                      
                        border focus:outline-none
                        rounded-lg transition
                        form-control dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:border-blue-600 border-gray-500 text-gray-700 dark:bg-gray-700 dark:focus:text-white dark:border-gray-600  dark:placeholder-gray-400 dark:text-white focus:text-gray-700`
                        : `block 
                        w-full
                        px-4 py-2  
                        text-xl 
                        font-normal
                        pl-10
                      
                        border focus:outline-none
                        rounded-lg transition
                        form-control dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:border-blue-600 border-gray-500 text-gray-700 dark:bg-gray-700 dark:focus:text-white dark:border-gray-600  dark:placeholder-gray-400 dark:text-white focus:text-gray-700`
                    }
                    placeholder=" "
                    name="Nombre"
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="relative z-0 mb-6 w-full group">
                  <div className="absolute inset-y-0 left-0 pl-3 pt-6 flex items-center ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="w-6 h-6 fill-gray-500"
                    >
                      <path d="M504 232C504 95.751 394.053 8 256 8 118.94 8 8 118.919 8 256c0 137.059 110.919 248 248 248 52.926 0 104.681-17.079 147.096-48.321 5.501-4.052 6.423-11.924 2.095-17.211l-15.224-18.597c-4.055-4.954-11.249-5.803-16.428-2.041C339.547 442.517 298.238 456 256 456c-110.28 0-200-89.72-200-200S145.72 56 256 56c109.469 0 200 65.02 200 176 0 63.106-42.478 98.29-83.02 98.29-19.505 0-20.133-12.62-16.366-31.463l28.621-148.557c1.426-7.402-4.245-14.27-11.783-14.27h-39.175a12.005 12.005 0 0 0-11.784 9.735c-1.102 5.723-1.661 8.336-2.28 13.993-11.923-19.548-35.878-31.068-65.202-31.068C183.412 128.66 120 191.149 120 281.53c0 61.159 32.877 102.11 93.18 102.11 29.803 0 61.344-16.833 79.749-42.239 4.145 30.846 28.497 38.01 59.372 38.01C451.467 379.41 504 315.786 504 232zm-273.9 97.35c-28.472 0-45.47-19.458-45.47-52.05 0-57.514 39.56-93.41 74.61-93.41 30.12 0 45.471 21.532 45.471 51.58 0 46.864-33.177 93.88-74.611 93.88z" />
                    </svg>
                  </div>
                  <label className="dark:text-gray-400 ">
                    Correo Eléctronico
                  </label>
                  <input
                    type="text"
                    onChange={formik.handleChange}
                    className={
                      formik.touched.Email && formik.errors.Email
                        ? ` is-invalid
                        block 
                        w-full
                        px-4 py-2  
                        text-xl 
                        font-normal
                        pl-10
                      
                        border focus:outline-none
                        rounded-lg transition
                        form-control dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:border-blue-600 border-gray-500 text-gray-700 dark:bg-gray-700 dark:focus:text-white dark:border-gray-600  dark:placeholder-gray-400 dark:text-white focus:text-gray-700`
                        : `block 
                        w-full
                        px-4 py-2  
                        text-xl 
                        font-normal
                        pl-10
                      
                        border focus:outline-none
                        rounded-lg transition
                        form-control dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:border-blue-600 border-gray-500 text-gray-700 dark:bg-gray-700 dark:focus:text-white dark:border-gray-600  dark:placeholder-gray-400 dark:text-white focus:text-gray-700`
                    }
                    placeholder=" "
                    name="Email"
                  />
                </div>

                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="relative z-0 mb-6 w-full group">
                    <div className="absolute i left-0 pl-3 pt-8 flex items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 stroke-gray-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                        />
                      </svg>
                    </div>

                    <div className="absolute  right-0 pr-3 pt-8 flex items-center ">
                      <button type="button" onClick={handlesShow}>
                        {showPass ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                            className="w-6 h-6 fill-gray-500"
                          >
                            <path d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                            className="w-6 h-6 fill-gray-500"
                          >
                            <path d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z" />
                          </svg>
                        )}
                      </button>
                    </div>
                    <label className="dark:text-gray-400 ">Contraseña</label>
                    <input
                      type={showPass ? "text" : "password"}
                      className={
                        formik.touched.Clave && formik.errors.Clave
                          ? ` 
                              block 
                              w-full
                              px-4 py-2  
                              text-xl 
                              font-normal
                              pl-10
                            
                              border focus:outline-none
                              rounded-lg transition
                              form-control dark:focus:ring-red-500 dark:focus:border-red-500 focus:border-red-600 border-red-500 text-gray-700 dark:bg-gray-700 dark:focus:text-white dark:border-gray-600  dark:placeholder-gray-400 dark:text-white focus:text-gray-700`
                          : `block 
                              w-full
                              px-4 py-2  
                              text-xl 
                              font-normal
                              pl-10
                            
                              border focus:outline-none
                              rounded-lg transition
                              form-control dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:border-blue-600 border-gray-500 text-gray-700 dark:bg-gray-700 dark:focus:text-white dark:border-gray-600  dark:placeholder-gray-400 dark:text-white focus:text-gray-700`
                      }
                      placeholder=" "
                      name="Clave"
                      onChange={formik.handleChange}
                    />

                    {formik.touched.Clave && formik.errors.Clave ? (
                      <MesageError message={formik.errors.Clave} />
                    ) : null}
                  </div>
                  <div className="relative z-0 mb-6 w-full group">
                    <label className="dark:text-gray-400 ">
                      Confirmar Contraseña
                    </label>
                    <div className="absolute left-0 pl-3 pt-2 flex items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 stroke-gray-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                        />
                      </svg>
                    </div>
                    <div className="absolute  right-0 pr-3 pt-2 flex items-center ">
                      {showPass ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 576 512"
                          className="w-6 h-6 fill-gray-500 "
                        >
                          <path d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                          className="w-6 h-6 fill-gray-500"
                        >
                          <path d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z" />
                        </svg>
                      )}
                    </div>
                    <input
                      type={showPass ? "text" : "password"}
                      className={
                        formik.touched.ConfirmarClave &&
                        formik.errors.ConfirmarClave
                          ? ` 

                          border-red-600
                          block 
                          w-full
                          px-4 py-2  
                          text-xl 
                          font-normal
                          pl-10
                        
                          border focus:outline-none
                          rounded-lg transition
                          form-control dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:border-blue-600  text-gray-700 dark:bg-gray-700 dark:focus:text-white dark:border-gray-600  dark:placeholder-gray-400 dark:text-white focus:text-gray-700`
                          : `block 
                          w-full
                          px-4 py-2  
                          text-xl 
                          font-normal
                          pl-10
                        
                          border focus:outline-none
                          rounded-lg transition
                          form-control dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:border-blue-600 border-gray-500 text-gray-700 dark:bg-gray-700 dark:focus:text-white dark:border-gray-600  dark:placeholder-gray-400 dark:text-white focus:text-gray-700`
                      }
                      placeholder=" "
                      name="ConfirmarClave"
                      onChange={formik.handleChange}
                    />

                    {formik.touched.ConfirmarClave &&
                    formik.errors.ConfirmarClave ? (
                      <MesageError message={formik.errors.ConfirmarClave} />
                    ) : null}
                  </div>
                </div>

                {/* 
                <div style={{ textAlign: 'end' }}>

                  {formik.touched.ConfirmarClave && formik.errors.ConfirmarClave ? (
                    <MesageError message={formik.errors.ConfirmarClave} />
                  ) : null}

                </div>
             */}

                <div className="pt-2">
                  <button
                    type="submit"
                    className=" sm:w-auto md:w-full lg:w-full py-4 px-8  text-white bg-blue-500 rounded-lg shadow-lg hover:bg-blue-900 focus:ring-4 focus:ring-red-100 focus:outline-none"
                  >
                    Crear Cuenta
                  </button>
                </div>
              </form>
              <div className="pt-4">
                <div className="font-light text-center text-gray-500">
                  ¿Ya tienes una cuenta?{" "}
                  <NavLink exact="true" to="/login">
                    <b className="text-blue-600">Iniciar sesión</b>
                  </NavLink>
                </div>
                {/* <div
                  className="flex flex-wrap gap-y-2 justify-between items-center pt-14 text-center whitespace-nowrap"
                >
                  <span className="flex-1 text-gray-500">© 2022 Intuito S.A. All rights reserved.</span>
                  <span className="flex flex-1 justify-center items-center space-x-1">
                    <button className='text-gray-500' onClick={handleOpenModal}>
                    Terminos & Condiciones
                    </button>
                    
                  </span> 
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* <ModalTerms Activar={TerminosServicios} setModal={setTerminosServicios} /> */}
      </div>
    </>
  );
};

export default Registro;
