//import {createStore, combineReducers, compose, applyMiddleware} from 'redux'

import {  configureStore } from '@reduxjs/toolkit';

import app from './app.slice'
import empresa from '../features/NavBar/navBar.slice'
import permisosEmpresa from '../features/AsideBar/asideBar.slice'
import modalConfiguracion from '../components/Inventario/Configuracion/modalConfiguracion.Slice'
import modalBodega from '../components/Inventario/Bodegas/Crear/modalCrearBodega.slice'


const middlewareConfiguration = { serializableCheck: false };

const store = configureStore({
    reducer: { app, empresa, permisosEmpresa,modalConfiguracion,modalBodega },
    devTools: {
        name: 'FacturadorWeb',
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(middlewareConfiguration),
});

export default store ;
/*
export function dispatchOnCall(action) {
    return () => store.dispatch(action);
}
*/

//middleware: getDefaultMiddleware(),