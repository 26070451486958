import { useContext } from "react";
import ActiveReembolsoButton from "./ActiveReembolsoButton";
import ReembolsoModal from "./ReembolsoModal";
import ReembolsoProvider, { ReembolsoContext } from "./ReembolsoProvider";
import ReembolsosTable from "./ReembolsosTable";

const useReembolso = () => {
  const {
    EditReembolso,
    setEditReembolso,
    IsEditReembolso,
    setIsEditReembolso,
    ActiveReembolso,
    setActiveReembolso,
    ReembolsoList,
    setReembolsoList,
    handleDeleteElement,
  } = useContext(ReembolsoContext);

  return {
    EditReembolso,
    setEditReembolso,
    handleDeleteElement,
    IsEditReembolso,
    setIsEditReembolso,
    ActiveReembolso,
    setActiveReembolso,
    ReembolsoList,
    setReembolsoList,
    ReembolsoProvider,
    ReembolsosTable,
    ReembolsoModal,
    ActiveReembolsoButton
  };
};

export default useReembolso;
