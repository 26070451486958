import React, { useContext } from "react";
import NumberFormat from "react-number-format";

import { EmitirDocContext } from "./EmitirDocProvider";
import {
  TableStyle,
  TableStyleDiv,
  TableStyleTBody,
  TableStyleTHead,
} from "../../../features/Constantes";
import { roundToTwo } from "../../../services/Utilitario";

const ListaDetalle = () => {
  const {
    setActivarModalPago,
    DataFromAdd,
    setDataFromAdd,
    setDataPagoModal,
    //IsEdit,
    setIsEdit,
    //EditElement,
    setEditElement,
  } = useContext(EmitirDocContext);

  const handleDeleteElementret = (index) => {
    DataFromAdd.splice(index, 1);
    setDataFromAdd([...DataFromAdd]);
  };

  const handleEditElement = (index) => {
    setIsEdit(true);
    setEditElement(index);
  };

  const handleAddPago = (index) => {
    setDataPagoModal(index);
    setActivarModalPago(true);
  };

  return (
    <>
      {DataFromAdd.length === 0 ? (
        <></>
      ) : (
        
         
            <div className=" overflow-x-auto ">
              <div className="inline-block min-w-full py-2 align-middle px-1">
                <div className={TableStyleDiv + " whitespace-nowrap"}>
                  <table className={TableStyle}>
                    <thead className={TableStyleTHead}>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3  text-sm font-semibold  sm:pl-6 w-44 "
                        >
                          Impuesto
                        </th>
                        <th className=" px-3 py-3.5  text-sm  font-semibold w-44 ">
                          Valor
                        </th>
                        <th className=" px-3 py-3.5  text-sm  font-semibold w-44 ">
                          % Ret.
                        </th>
                        <th
                          scope="col"
                          className=" px-3 py-3.5  text-sm font-semibold w-44   "
                        >
                          Valor ($)
                        </th>
                      </tr>
                    </thead>
                    <tbody className={TableStyleTBody}>
                      {/* {documentos.length < 1 ? (
                     <MesageWarningTable
                       colSpan="11"
                       message="No se encontraron datos para la búsqueda."
                       className="py-1"
                     />
                   ) : null} */}
                      {DataFromAdd.map((el, index) => {
                        return (
                          <>
                            <tr key={index} className="">
                              <td className="relative flex space-x-3  py-4 pl-4 sm:pl-6 pr-3 text-sm ">
                                <p>
                                {el.CodDocSustento === "01"
                                  ? "Factura:"
                                  : el.CodDocSustento === null
                                  ? "No Aplica Documento"
                                  : "Otro:"}

                                </p>
                           <p>
                           {el.NumDocSustento}

                           </p>
<p>
{el.FechaEmisionDocSustento}

</p>

                                <button
                                  onClick={() => handleEditElement(index)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#addImpuestoRetencionModal"
                                  title="Editar"
                                  className="text-gray-400 hover:text-gray-600 text-xs "
                                  style={{ verticalAlign: "top" }}
                                  type="button"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                    />
                                  </svg>
                                </button>
                                <button
                                  onClick={() => handleDeleteElementret(index)}
                                  className="text-red-500 hover:text-red-600 text-xs "
                                  style={{ verticalAlign: "top" }}
                                  title="Eliminar"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                    />
                                  </svg>
                                </button>
                              </td>
                            </tr>
                            {el.Retenciones.map((el2, indexRet) => {
                              return (
                                <tr key={indexRet}>
                                  <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm ">
                                    {el2.Codigo === 1
                                      ? "RENTA -" + el2.CodigoRetencion
                                      : "IVA"}
                                  </td>
                                  <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm ">
                                    $
                                    <NumberFormat
                                      fixedDecimalScale
                                      decimalScale={2}
                                      value={el2.BaseImponible}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  </td>

                                  <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm ">
                                    <NumberFormat
                                      fixedDecimalScale
                                      decimalScale={2}
                                      value={el2.PorcentajeRetener}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                    {!el2.PorcentajeRetener ? "" : "%"}
                                  </td>

                                  <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm ">
                                    $
                                    <NumberFormat
                                      fixedDecimalScale
                                      decimalScale={2}
                                      value={el2.ValorRetenido}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        
       
      )}
    </>
  );
};

export default ListaDetalle;
