import { ConfiguracionModalOff } from "./modalConfiguracion.Slice"
import { useDispatch } from "react-redux"
import { useForm } from "react-hook-form"
import { useState } from "react"
import { ModalButtons } from "../Shared/ModalButtons"



const Medidas = ["Unidades", "Kilogramos", "Litros"]

export const MedidasModal = () => {

    const dispatch = useDispatch()

    const { register, handleSubmit, setValue } = useForm()

    const [isAll, setIsAllSelected] = useState(false)

    const handleSelectAll = (e) => {
        const checked = e.target.checked;
        setIsAllSelected(checked);
        Medidas.forEach((opcion) => {
            setValue(opcion, checked);
        });
    };

    const setMedidas = (data) => {
        dispatch(ConfiguracionModalOff())
        console.log(data)
    }


    return (
        <div className="absolute flex items-center top-0 left-0 justify-center w-full h-full" style={{ backgroundColor: "rgba(0,0,0,0.7)" }}>
            <section style={{ padding: "38px", height: "fit-content", width: "60%", backgroundColor: "white", position: "relative" }} className="shadow-xl rounded-lg flex flex-col gap-10">
                <div className="bg-gray-300 rounded-lg text-[12px] w-full flex flex-col justify-center gap-2 p-6">
                    <h1 className="font-semibold text-2xl">Unidades de Medidas</h1>
                    <h4>Configuracion para modulo de inventario</h4>
                </div>
                <form className="w-full flex flex-col gap-10 items-center justify-center" onSubmit={handleSubmit(setMedidas)}>
                    <table className="w-[90%] border-2" >
                        <thead>
                            <tr className="bg-gray-300 p-2 font-semibold text-center grid grid-cols-2">
                                <th className="text-center h-full">Medidas</th>
                                <th className="text-center h-full">Activo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Medidas.map((medida, i) => <tr className="grid grid-cols-2 text-center p-2 border-y-2" key={i}>
                                <td>
                                    <label htmlFor={medida}>{medida}</label>
                                </td>
                                <td>
                                    <input type="checkbox" id={medida} {...register(medida)} />
                                </td>
                            </tr>)}
                            <tr className="border-y-2 grid grid-cols-2 text-center p-2" >
                                <td>
                                    <label htmlFor="all">Seleccionar Todo</label>
                                </td>
                                <td>
                                    <input type="checkbox" id="all" value={isAll} onChange={(e) => handleSelectAll(e)} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ModalButtons closeFn={ConfiguracionModalOff}/>
                </form>
            </section>
        </div>
    )
}