import React, { createContext, useState } from "react";

export const PuntoVentaContext = createContext();

const PuntoVentaProvider = (props) => {
  const [puntoDeVenta, setPuntoDeVenta] = useState(false);

  return (
    <PuntoVentaContext.Provider
      value={{
        puntoDeVenta, setPuntoDeVenta
      }}
    >
      {props.children}
    </PuntoVentaContext.Provider>
  );
};

export default PuntoVentaProvider;
