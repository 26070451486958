export const TextMessageCom = () => {
  return (
    <div>
      <div className="flex justify-center items-center space-x-2">
        <p className="font-semibold text-lg">Advertencia</p>
      </div>
      <div className="mt-3">
        <p>
          Tu comprobante tiene{" "}
          <strong> fecha de emisión mayor a 72 horas</strong> para su
          autorización, para evitar posible sanción por el SRI, se recomienda
          cambiar la fecha de emisión dentro del rango de 72 horas.
        </p>

        <p> Para más detalle consultar o revisar resolución SRI:</p>
        <p className="font-semibold pt-2">
          NAC-DGERCGC22-00000064 y<br></br> NACDGERCGC18-00000233
        </p>
       
      </div>
    </div>
  );
};
