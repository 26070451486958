import { InputGlobal } from "../../context/inputContext"
import { FormBaseInventario } from "../../Shared/FormBaseInventario"
import { useContext } from "react"
import { BodegaContext } from "./context/BodegasContext"
import { v4 as uuidv } from "uuid";

export const CrearBodegaForm = () => {
 
    const {addBodega} = useContext(BodegaContext)

    const crearBodega = (data) => {
        const newBodega = {
            id: uuidv(),...data
        }
        addBodega(newBodega)
    }


    return (<>
        <FormBaseInventario submitFn={crearBodega} title={"Registro de Bodegas"}>
            <InputGlobal title={"Código"} type={"text"} validations={{required: true}} isHook={true} name={"codigo"} isPlaceHolder={"Digite el codigo de la bodega"} />
            <InputGlobal title={"Nombre de la Bodega"} validations={{required: true}} type={"text"} name={"nombreBodega"} isHook={true} isPlaceHolder={"Digite el nombre de la bodega"} />
            <InputGlobal title={"Establecimiento"} type={"text"} validations={{required: true}} name={"establecimiento"} isHook={true} isPlaceHolder={"000"} />
            <InputGlobal title={"Dirección"} type={"text"} validations={{required: true}} name={"direccion"} isPlaceHolder={"Digite el nombre de la dirección"} isHook={true} />
        </FormBaseInventario>
    </>)
}