export const oidcConfig = {
    authority: process.env.REACT_APP_AUTHORITY,
    client_id: process.env.REACT_APP_CLIENTE_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    response_type: process.env.REACT_APP_RESPONSE_TYPE,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    scope: process.env.REACT_APP_SCOPE,
    post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    automaticSilentRenew: process.env.REACT_APP_AUTOMATIC_SILENT_RENEW,
    filterProtocolClaims: process.env.REACT_APP_FILTER_PROTOCOL_CLAIMS,
    loadUserInfo: process.env.REACT_APP_LOAD_USER_INFO,
    onSigninCallback: (user) =>
      localStorage.setItem("token_facturador", user.access_token),
  };