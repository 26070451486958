import React from "react";
import FormReporteServicio from "./FormReporteServicio";
import ListaServicios from "./ListaServicios";
import ModalAdd from "./ModalAdd";
import Modals from "./Modals";
import ReporteServicioProvider from "./ReporteServicioProvider";

const ProductosServicios = () => {
  return (
    <div className="space-y-6 xl:mx-4 my-2">
      <div className="">
      {/* <div className="bg-white shadow  dark:bg-gray-900 sm:rounded-lg md:py-2 md:px-4 py-2 px-2"> */}

      <ReporteServicioProvider>
        <div className="py-1">
          <div className=" border rounded-xl py-4 dark:border-gray-600 bg-white dark:bg-gray-700">
            <FormReporteServicio />
          </div>
        </div>
        <div className="py-2">
          <div className=" p-2 border rounded-xl bg-white dark:bg-gray-700 dark:border-gray-600">
            <ListaServicios />
          </div>
          <Modals />
          <ModalAdd />
        </div>
      </ReporteServicioProvider>
    </div>    </div>
  );
};

export default ProductosServicios;
