
import IngresoProvider from "./Context/IngresoProvider";
import IngresoForm from "./IngresoForm";
import ModalIngreso from "./ModalIngreso";

export default function Ingreso() {

  return (
    <>
      <IngresoProvider>
          <IngresoForm />
          <ModalIngreso />
      </IngresoProvider>
    </>
  );
}
