import React, { useState } from "react";
import { SmallModalInfo } from "../components/SmallModalInfo";
import preguntas from "../../img/preguntas.png";
import { ModalSoporte } from "./ModalSoport";
export const CentroAyudaModal = () => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleAccordionChange = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };
  const Modaltuto = () => {
    document.getElementById("centroModalclose").click();

  };

  const Modaltutow = () => {
    document.getElementById("centroModalclose").click();
    setIsOpen(!isOpen);

  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
    <ModalSoporte isOpen={isOpen} toggleModal={toggleModal} />

    <SmallModalInfo buttonModal={"centroModal"}>
      <div>
        <p className="text-white bg-blue-900 p-5  text-center">
          Centro de ayuda
        </p>
        <div className="grid grid-cols-2 my-4 ">
          <div className="flex justify-center flex-col mx-auto">
            <button
              onClick={() => Modaltuto()}
              data-bs-toggle="modal"
              data-bs-target="#tutorialesModal"
              className="w-28 h-28 flex items-center justify-center bg-blue-900 rounded-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-12 h-12 text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                />
              </svg>
            </button>
            <p className="text-center my-2">Tutoriales</p>
          </div>
          <div className="flex justify-center flex-col mx-auto">
            <button
              onClick={() => Modaltuto()}
              data-bs-toggle="modal"
              data-bs-target="#preguntasModal"
              className="w-28 h-28 flex items-center justify-center bg-blue-900 rounded-full"
            >
              <img
                src={preguntas}
                alt="preguntas"
                className="w-12 h-12 text-white"
              />
            </button>
            <p className="text-center my-2">Preguntas frecuentes</p>
          </div>
        </div>
        <p className="text-center text-xl font-semibold">
          ¿Necesitas comunicarte con nosotros?
        </p>
        <div className="grid grid-cols-1 my-4   ">

          <div className="flex justify-center flex-col mx-auto col-span-2 ">
       
            <button
              onClick={() => Modaltutow()}
             
              className="w-28 h-28 flex items-center mx-auto justify-center bg-blue-900 rounded-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-12 h-12 text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                />
              </svg>
            </button>
            <p className="text-center my-2">Soporte / Recomendación  / Sugerencia</p>
          </div>
</div>

      </div>
    </SmallModalInfo>
    </>
  );
};
