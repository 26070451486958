
  export const PlanJuridica = [
    {
      text: "Cédula de identidad (escaneada PDF).",
    },
    {
      text: "Foto solo del rostro con cédula en mano.",
    },
    {
      text: "Ruc de la entidad solicitante.",
    },
    {
      text: "Llenar el formulario y firmar la autorización de firma (escaneada PDF).",
    },
   
  ];
  