import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { SmallModalInfoSri } from "../../../features/components/SmallModalInfoSri";

const ModalLoading = ({ abortRequest }) => {
  const navigate = useNavigate();

  const handleRedirectToMasivo = () => {
    document.getElementById("ModalLoadingButton").click();
    navigate("/emision/generar/masivo");
  };

  return (
    <SmallModalInfoSri buttonModal="ModalLoading" title="Generado correctamente">
      <div className="relative z-0 my-2 w-full group">
        <div className="text-center">
          <div className="mx-auto  text-center">
            <div className="mx-auto ">
              <div className="bg-green-500 w-10 mx-auto p-2 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
              </div>
            </div>

            <p className="my-2 font-semibold ">
              Tu solicitud de descarga se ha generado correctamente
            </p>
            <p className="text-center text-gray-600 my-2">
              Puedes verificar su estado en la pestaña de{" "}             
            </p>
            <span className="">
                <button className="inline-block mt-1 text-white bg-blue-600 w-full lg:w-28  hover:bg-blue-700  font-semibold p-2 text-[0.813rem] border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:shadow-outline-grayduration-150
              ease-in-out" onClick={handleRedirectToMasivo}>Reporte masivo</button>
              </span>
          </div>
        </div>
      </div>
    </SmallModalInfoSri>
  );
};

export default ModalLoading;
