import { createContext } from "react";
import { useState } from "react";
export const BodegaContext = createContext()
export const BodegaProvider = ({children}) => {

    const [bodegas, setBodegas] = useState([])

    const addBodega = (newBodega) => { 
        setBodegas(prevState => [...prevState, newBodega])
    }
    const deleteBodega = (id) => {
        setBodegas(bodegas.filter(bodega => bodega.id !== id))
    }

    const updateBodega = (newBodega,id) => {
       setBodegas(bodegas.map(bodega => {
        if(bodega.id === id) return newBodega
        else {
            return bodega
        }
       }))
    }
    return(
        <BodegaContext.Provider value={{bodegas, addBodega, deleteBodega, updateBodega}}>
            {children}
        </BodegaContext.Provider>
    )
}