export const PlanDesarrollo = [
  {
    text: "Implementación de soluciones.",
  },
  {
    text: "Integración de procesamientos masivos.",
  },
  {
    text: "Descarga masivas de comprobantes electrónicos del SRI.",
  },

];
