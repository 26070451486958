import { FormProvider, useForm } from "react-hook-form"
import { AddIcon, UploadIcon, DownloadIcon } from "../../../Icons/ButtonIcons"

export const FormBaseInventario = ({submitFn, children,title}) => {

    const methods = useForm()

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submitFn)} className="w-full bg-white rounded-lg p-4 shadow-md flex flex-col gap-4">
                <h1 className="font-bold text-3xl p-6 pr-0 pl-0 border-b-2 border-gray-400">{title}</h1>
                <div className="formCrearBodega p-8 bg-gray-300 rounded-lg text-[12px]">
                    {children}
                </div>
                <div className="flex flex-row gap-1 text-white font-semibold text-[12px]">
                    <button type="submit" className="p-3 rounded-lg bg-blue-600 hover:bg-blue-700 pr-5 pl-5 flex flex-row gap-2 items-center"><span><AddIcon /></span>Agregar</button>
                    <button className="p-3 rounded-lg bg-blue-600 hover:bg-blue-700 pl-5 pr-5 flex flex-row gap-2 items-center"><span><UploadIcon /></span>Importar</button>
                    <button className="p-3 pl-8 pr-8 rounded-lg bg-gray-600 hover:bg-gray-700 flex flex-row gap-2 items-center"> <span><DownloadIcon /></span>Descargar datos</button>
                </div>
            </form>
        </FormProvider>
    )
}