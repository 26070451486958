import React, { useState } from "react";
import FormGuiaRemision from "./FormGuiaRemision";
import EmitirGuiaRemisionProvider, {
  EmitirGuiaRemisionContext,
} from "./EmitirGuiaRemisionProvider";
import FormBuscarDetalle from "./FormBuscarDetalle";
import ListaDetalle from "./ListaDetalle";
import Modals from "./Modals";
import ModalGuardar from "./ModalGuardar";
import InformacionAdicional from "../../../features/components/InformacionAdicional";
import DestinatarioModal from "./DestinatarioModal";
import BuscarFacturaModal from "./BuscarFacturaModal";
import SubmitButton from "./SubmitButton";
import { Transition } from "@headlessui/react";
import ReporteServicioProvider from "../../Mantenimiento/ProductosServicios/ReporteServicioProvider";
import ModalAdd from "../../Mantenimiento/ProductosServicios/ModalAdd";

const EmitirGuiaRemision = () => {
  const ClassAvite =
    "text-sm py-1.5 w-full cursor-pointer  p-2 px-2 text-center bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-t-lg font-medium leading-6 text-white";
  const [isOpen, setIsOpen] = useState(true);
  return (
    <EmitirGuiaRemisionProvider>
      <div className="p-4 ">
        <div className=" dark:bg-gray-900   sm:rounded-lg ">
          <BuscarFacturaModal />
          <FormGuiaRemision />
          <div className="bg-white dark:bg-gray-900 lg:py-2 md:py-2 lg:px-4 md:px-2">
            <FormBuscarDetalle />
            <ListaDetalle />
          </div>

          <div className="grid md:grid-cols-2  bg-white dark:bg-gray-900  px-1 lg:px-4
           md:px-0  lg:p-3 md:p-3  md:gap-6">
            <div className="relative z-0 border rounded-lg dark:border-gray-600  w-full group">
              <div className="flex space-x-0.5 w-full">
                <h3
                  className={ClassAvite + " flex justify-between items-center"}
                >
                  Información adicional (Opcional)
                  <svg
                    onClick={() => setIsOpen(!isOpen)}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`w-4 h-4 lg:hidden md:hidden text-white duration-150 ${
                      isOpen ? "rotate-180" : ""
                    }`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </h3>
              </div>
              <Transition
                show={isOpen}
                enter="transition ease-out duration-300" // Aumenta la duración de entrada
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-300" // Aumenta la duración de salida
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="p-4">
                  <InformacionAdicional Contexto={EmitirGuiaRemisionContext} />
                </div>
              </Transition>
            </div>
          </div>
          <div className=" bg-white dark:bg-gray-900 p-3">
            <div className="flex lg:justify-end justify-center">
              <SubmitButton />
            </div>
          </div>
          {/* <MobileSubmitButton /> */}
        </div>
      </div>
      <Modals />
      <ModalGuardar />
      <DestinatarioModal />
      <ReporteServicioProvider>
            <ModalAdd />
          </ReporteServicioProvider>
    </EmitirGuiaRemisionProvider>
  );
};

export default EmitirGuiaRemision;
