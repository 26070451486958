import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { post } from "../../../services";
import {
  StyleSelect,
  StyleLabel,
  StyleInput,
  StyleInputError,
  StyleLabelError,
} from "../../../features/Constantes";
import { LargeModalForm } from "../../../features/components/LargeModalForm";
import SelectItems from "../../../services/provincias.json";
import { EstablecimientosContext } from "./EstablecimientosProvider";
import { Oval } from "react-loader-spinner";

import { toast } from "react-toastify";

const ModalEdit = () => {
  const empresa = useSelector((store) => store.empresa.empresa);

  const { EditEstablecimiento, fetchData } = useContext(
    EstablecimientosContext
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm("onSubmit");

  const [CantonesList, setCantonesList] = useState({});
  const [ParroquiasList, setParroquiasList] = useState({});

  const [LoadingImage, setLoadingImage] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);

  const [Preview, setPreview] = useState(
    process.env.PUBLIC_URL + "/img/default-logo.png"
  );

  useEffect(() => {
    setLoadingImage(true);
    if (EditEstablecimiento.provincia) {
      let valores = Object.keys(SelectItems).filter((el) => {
        return (
          SelectItems[el].provincia ===
          EditEstablecimiento.provincia.toUpperCase()
        );
      });
      setValue("Provincia", EditEstablecimiento.provincia.toUpperCase());
      let cantones = SelectItems[valores[0]].cantones;

      setCantonesList(cantones);

      setValue("Canton", EditEstablecimiento.ciudad.toUpperCase());
      let valores2 = Object.keys(cantones).filter((el) => {
        return cantones[el].canton === EditEstablecimiento.ciudad.toUpperCase();
      });

      setParroquiasList(cantones[valores2[0]].parroquias);

      setValue("Direccion", EditEstablecimiento.direccion);
      setValue("Codigo", EditEstablecimiento.codigo);
      setValue("Descripcion", EditEstablecimiento.descripcion);
      setValue("NombreComercial", EditEstablecimiento.nombreComercial);

      (async () => {
        // console.log(EditEstablecimiento);
        try {
          let values = await axios.get(
            `api/establecimientos/GetImgEstablecimiento/${EditEstablecimiento.idEstablecimiento}`
          );
          setPreview("data:image/png;base64," + values.data);
        } catch (ex) {
          console.error(ex);
          setPreview(process.env.PUBLIC_URL + "/img/default-logo.png");
        } finally {
          setLoadingImage(false);
        }
      })();
    }
  }, [EditEstablecimiento, setValue]);

  useEffect(() => {
    if (EditEstablecimiento.parroquia)
      setValue("Parroquia", EditEstablecimiento.parroquia.toUpperCase());
  }, [EditEstablecimiento.parroquia, ParroquiasList, setValue]);

  const guardarEditarEstablecimiento = async () => {
    let establecimiento = { ...EditEstablecimiento, ...getValues() };
    establecimiento.Ciudad = establecimiento.Canton;

    const resPut = await axios.put(
      `api/Establecimientos/${empresa.idEmpresa}`,
      establecimiento
    );
    const toasPromise = () =>
      new Promise((resolve) => setInterval(() => resolve(resPut), 2000));

    await toast.promise(toasPromise(), {
      pending: {
        render() {
          setIsLoading(true);
          return "Editando establecimiento.";
        },
        isLoading: true,
      },
      success: {
        render() {
          setIsLoading(false);
          return "Establecimiento editado con exito";
        },
        autoClose: 2000,
        closeOnClick: true,
      },
      error: {
        render() {
          setIsLoading(false);
          return "Algo ha sucedido, intente nuevamente.";
        },
        autoClose: 2000,
        closeOnClick: true,
      },
    });

    await UploadedImage(EditEstablecimiento.idEstablecimiento);
    document.querySelector("#editarEstablecimientoclose").click();
    await fetchData();
  };

  const handleChangeProvincia = (e) => {
    setCantonesList([]);
    setParroquiasList([]);
    setValue("Canton", "");
    setValue("Parroquia", "");
    let valores = Object.keys(SelectItems).filter((el) => {
      return SelectItems[el].provincia === e.target.value;
    });
    setCantonesList(SelectItems[valores[0]].cantones);
    setParroquiasList(
      Object.values(
        Object.values(SelectItems[valores[0]].cantones)[0].parroquias
      )
    );

    setValue(
      "Canton",
      Object.values(SelectItems[valores[0]].cantones)[0].canton
    );
    setValue(
      "Parroquia",
      Object.values(
        Object.values(SelectItems[valores[0]].cantones)[0].parroquias
      )[0]
    );
  };

  const handleChangeCanton = (e) => {
    setParroquiasList([]);
    setValue("Parroquia", "");
    let valores = Object.keys(CantonesList).filter((el) => {
      return CantonesList[el].canton === e.target.value;
    });

    setParroquiasList(CantonesList[valores[0]].parroquias);
    setValue(
      "Parroquia",
      Object.values(CantonesList[valores[0]].parroquias)[0]
    );
  };

  const handleChangeImage = (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = (el) => {
      setPreview(reader.result);
    };
  };

  const UploadedImage = async (id) => {
    try {
      let formData = new FormData();
      let e = document.getElementById("dropzone-fileEdit");
      formData.append("_establecimiento", e.files[0]);
      await post(
        `api/Establecimientos/GuardarImgEstablecimiento/${id}`,
        formData
      );
    } catch (ex) {
      // console.log(ex);
    }
  };

  return (
    <LargeModalForm
      IsLoading={IsLoading}
      buttonModal={"editarEstablecimiento"}
      submitText={"Guardar"}
      title="EDITAR ESTABLECIMIENTO"
      subTitle={`Ruc: ${empresa.ruc} - Razón social: ${empresa.razonSocial}`}
      submitModal={handleSubmit(guardarEditarEstablecimiento)}
    >
      <div className="w-full mt-10">
        {!LoadingImage ? (
          <img
            className="mx-auto upload-image-size object-cover outline outline-gray-300 outline-1 rounded shadow-sm"
            src={Preview}
            height="90"
            width="230"
            alt="your logo"
          />
        ) : (
          <div
            className="flex align-middle justify-center mx-auto"
            style={{ height: 90 }}
          >
            <Oval
              height={50}
              width={50}
              color="#FFFFFF"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#233876"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}
      </div>
      <div className="flex justify-around mt-5 items-center ">
        <div className="w-[300px]">
          <input
            className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    dark:border-gray-600
    dark:bg-gray-700
    dark:text-white
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            accept="image/png, image/gif, image/jpeg"
            id="dropzone-fileEdit"
            onChange={handleChangeImage}
            type="file"
          />
        </div>
      </div>
      <div className="relative z-0 mb-2  pt-2 w-full group">
        <h5 className="font-bold  mb-6 text-gray-800 dark:text-white  pb-2 border-b-gray-600 border-b">
          Establecimiento
        </h5>

        <form>
          <div className="relative z-0 mb-2  pt-2 w-full group">
            <div className="w-full grid md:grid-cols-6 md:gap-4 mb-2 ">
              <div className="relative z-0 mb-2 w-full col-span-6 lg:col-span-1 group">
                <input
                  type="text"
                  className={
                    errors.Codigo
                      ? StyleInputError
                      : StyleInput + " bg-gray-200 dark:bg-gray-600"
                  }
                  placeholder=" "
                  id="Codigo"
                  name="Codigo"
                  readOnly
                  {...register("Codigo", {})}
                />
                <label
                  htmlFor="Codigo"
                  className={errors.Codigo ? StyleLabelError : StyleLabel}
                >
                  Establ.(SRI) *
                </label>
              </div>
              <div className="relative z-0 mb-2 w-full col-span-6 lg:col-span-2 group">
                <input
                  type="text"
                  className={
                    errors.NombreComercial ? StyleInputError : StyleInput
                  }
                  placeholder=" "
                  id="NombreComercial"
                  name="NombreComercial"
                  {...register("NombreComercial", {
                    maxLength: {
                      value: 300,
                      message: "No más de 3 carácteres!",
                    },
                    minLength: {
                      value: 1,
                      message: "Mínimo 1 carácteres",
                    },
                  })}
                />
                <label
                  htmlFor="NombreComercial"
                  className={
                    errors.NombreComercial ? StyleLabelError : StyleLabel
                  }
                >
                  Nombre comercial
                </label>
              </div>
              <div className="relative z-0 mb-2 col-span-6 lg:col-span-3 w-full group">
                <input
                  type="text"
                  className={errors.Direccion ? StyleInputError : StyleInput}
                  placeholder=" "
                  id="Direccion"
                  name="Direccion"
                  {...register("Direccion", {
                    required: {
                      value: true,
                      message: "Direccion es requerido",
                    },
                    maxLength: {
                      value: 300,
                      message: "No más de 300 carácteres!",
                    },
                    minLength: {
                      value: 4,
                      message: "Mínimo 4 carácteres",
                    },
                  })}
                />
                <label
                  htmlFor="Direccion"
                  className={errors.Direccion ? StyleLabelError : StyleLabel}
                >
                  Dirección *
                </label>
              </div>
              <div className="relative z-0 mb-2 col-span-6 lg:col-span-2 w-full group">
                <select
                  type="text"
                  className={StyleSelect}
                  placeholder=" "
                  id="Provincia"
                  name="Provincia"
                  {...register("Provincia", {
                    required: {
                      value: true,
                      message: "Provincia es requerido",
                    },
                  })}
                  onChange={handleChangeProvincia}
                >
                  {Object.keys(SelectItems).map((el, index) => {
                    return (
                      <option key={index} value={SelectItems[el].provincia}>
                        {SelectItems[el].provincia}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="Provincia" className={StyleLabel}>
                  Provincia *
                </label>
              </div>

              <div className="relative z-0 mb-2 col-span-6 lg:col-span-2 w-full group">
                <select
                  type="text"
                  className={StyleSelect}
                  placeholder=" "
                  id="Canton"
                  name="Canton"
                  {...register("Canton", {
                    required: {
                      value: true,
                      message: "Canton es requerido",
                    },
                  })}
                  onChange={handleChangeCanton}
                >
                  {Object.keys(CantonesList).map((el, index) => {
                    return (
                      <option key={index} value={CantonesList[el].canton}>
                        {CantonesList[el].canton}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="Canton" className={StyleLabel}>
                  Cantón *
                </label>
              </div>

              <div className="relative z-0 mb-2 col-span-6 lg:col-span-2 w-full group">
                <select
                  type="text"
                  className={StyleSelect}
                  placeholder=" "
                  id="Parroquia"
                  name="Parroquia"
                  {...register("Parroquia", {
                    required: {
                      value: true,
                      message: "Parroquia es requerido",
                    },
                  })}
                >
                  {Object.values(ParroquiasList).map((el, index) => {
                    return (
                      <option key={index} value={el}>
                        {el}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="Canton" className={StyleLabel}>
                  Parroquia *
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </LargeModalForm>
  );
};

export default ModalEdit;
