import React from "react";
import { getNoventaDiasAtras, getFechaFin } from "../../../services/Utilitario";
import useSecuencialLoader from "./hooks/SecuencialLoader/useSecuencialLoader";
import InputDate from "../../../features/components/common/InputDate";
import InputText from "../../../features/components/common/InputText";
import { useFormContext } from "react-hook-form";
import moment from "moment-timezone";
import axios from "axios";
import { useState, useEffect } from "react";

//capturar fecha de API
export function useCurrentDate(timezone) {
  const [currentDate, setCurrentDate] = useState(null);

  useEffect(() => {
    // Realizar una solicitud HTTP al servicio "Current Time API"
    axios
      .get("worldclockapi.com/api/json/utc/now")
      .then((response) => {
        const fechaUTC = response.data.currentDateTime; // Obtenemos la fecha y hora UTC
        const fechaZonaHoraria = moment(fechaUTC).tz(timezone); // Convertimos a la zona horaria especificada

        setCurrentDate(fechaZonaHoraria.format("YYYY-MM-DD HH:mm:ss")); // Formateamos la fecha y hora
      })
      .catch((error) => {
        console.error("Error al obtener la fecha actual:", error);
      });
  }, [timezone]);

  return currentDate;
}
//FIN CAPTURAR FECHA DE API

const EmisorFactura = () => {
  // const timezone = "America/Guayaquil"; // Zona horaria de Ecuador (UTC-5)
  // const currentDate = useCurrentDate(timezone);
  // useCurrentDate(timezone);

  // console.log(useCurrentDate(timezone) + " INFORMACION");
  // console.log(useCurrentDate(currentDate) + " INFORMACION FECHA");
  // console.log(currentDate + " informacion INFORMACION INFORMACION");

  const { setValue } = useFormContext();
  const { EstablecimientoSelector, PuntoEmisionSelector } =
    useSecuencialLoader();

  return (
    <div className="relative z-0 mb-2 w-full group">
      <h3 className="text-sm py-1.5 my-2 px-2 text-left bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-t-lg font-medium leading-6 text-white">
        Emisor
      </h3>

      <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-3 md:gap-6">
        <div className="relative z-0 mb-2 w-full group">
          <EstablecimientoSelector />
        </div>

        <div className="relative z-0 mb-2 w-full group">
          <PuntoEmisionSelector />
        </div>

        <div className="relative z-0 translate-y-5 w-full group">
          <InputDate
            title={"Fecha emisión"}
            name={"fechaEmision"}
            min={getNoventaDiasAtras()}
          />
        </div>
      </div>
      <div className="grid md:grid-cols-3 mt-3 lg:mt-0 md:gap-6">
        <div className="relative z-0 mt-4 w-full group">
          <InputText
            onChange={(e) => {
              let valor = e.target.value.replace(/^(\d{3})(\d{3}).*/, "$1-$2-");
              setValue("guiaRemision", valor);
            }}
            maxLength={17}
            name={"guiaRemision"}
            title={"Guía remisión"}
          />
        </div>
      </div>
    </div>
  );
};

export default EmisorFactura;
