import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { PostElement } from "../../../services";
import { MailsRegExp } from "../../../services/Utilitario";
import {
  StyleInput,
  StyleInputError,
  StyleLabel,
  StyleLabelError,
} from "../../../features/Constantes";
import { LargeModalForm } from "../../../features/components/LargeModalForm";
import { ReporteFacturaContext } from "./ReporteFacturaUsuarioProvider";

const EmailsModalUsuario = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm({ mode: "onSubmit" });

  const empresa = useSelector(store => store.empresa.empresa);

  const { EditCorreos, setEditCorreos, CorreosId } = useContext(ReporteFacturaContext);

  const addEmail = () => {
    let addedValues = [...EditCorreos, getValues().email];

    setEditCorreos([...new Set(addedValues)]);

    setValue("email", "");
  };

  const handleSubmitModal = async () => {
    // console.log(EditCorreos);
    await PostElement(empresa,'ModifyCorreos', {Correos: EditCorreos.join(','), DocumentoId: CorreosId});
    document.getElementById("editCorreosModalclose").click();
  };

  const EliminarUser = (index) => {
    EditCorreos.splice(index,1);
    setEditCorreos([...EditCorreos])
  }

  return (
    <LargeModalForm
      buttonModal={"editCorreosModal"}
      title="CORREOS"
      submitText="GUARDAR Y ENVIAR"
      submitModal={handleSubmitModal}
    >
  
      <div className="flex align-middle justify-center  flex-col">
      <div className=" grid md:grid-cols-2 md:gap-6 ">
      <div className="relative z-0  w-full group">
      <input
            type="email"
            name="floating_email"
            id="floating_email"
            className={errors.email ? StyleInputError : StyleInput}
            {...register("email", {
              required: {
                value: true,
                message: "Nombre es requerido",
              },
              maxLength: {
                value: 120,
                message: "No más de 120 carácteres!",
              },
              minLength: {
                value: 4,
                message: "Mínimo 4 carácteres",
              },
              pattern:
                MailsRegExp,
            })}
          />
          <label
            htmlFor="floating_email"
            className={errors.email ? StyleLabelError : StyleLabel}
          >
            Correo
          </label>
      </div>
      <div className="relative z-0 -top-1.5 w-full group">
     
          <button
              type="submit"
              className=" inline-block bg-white w-full md:w-full lg:w-28  hover:bg-gray-100 text-gray-700 font-semibold p-2 text-sm border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:shadow-outline-grayduration-150
              ease-in-out"
              onClick={handleSubmit(addEmail)}
              
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline mr-1   -ml-1 w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              
              AGREGAR
              </button>
        </div>
      </div>
      </div>
   
      <div>
        
      <div className="  my-2  whitespace-nowrap overflow-x-auto relative md:rounded-lg ring-1 ring-gray-300  ">
      <table className="min-w-full divide-y divide-gray-300 table-fixed">
      <thead className="text-center">
            <tr>
            <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900  "
              >
                Email
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3  text-sm font-semibold text-gray-900 "
              >
                Eliminar
              </th>
              </tr>
              </thead>
     
      <tbody className="text-center">
      {EditCorreos.map((el, index) => (
        <tr>
        <td className=" px-3 py-2 text-sm text-gray-500  ">
        {el}
                  </td>
                  <td className=" px-3 py-2 text-sm text-gray-500  ">
                  <button
                      onClick={() => EliminarUser(index)}
                      type="submit"
                      className="hover:bg-gray-200  dark:hover:bg-gray-700  rounded-lg p-2   leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-gray "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 dark:fill-white fill-gray-500"
                        viewBox="0 0 448 512"
                      >
                        <path d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z" />
                      </svg>
                    </button>
                  </td>
        </tr>
           ))}
      </tbody>
      </table>
        </div>
      </div>
    </LargeModalForm>
  );
};

export default EmailsModalUsuario;
