import { useEffect ,useState} from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

const Callback = () => {

    const auth = useAuth();
    const navigate = useNavigate();
    const [isAccessDenied, setIsAccessDenied] = useState(false);
  
    useEffect(() => {
      if (auth.isAuthenticated) {
        navigate("/");
      } else if (auth.error) {
        if (auth.error.message === "Aplicación no Permitida") {
          setIsAccessDenied(true);
        } else if (auth.error.message === "No matching state found in storage") {
          navigate("/login");
        }
      }
    }, [auth]);
  
    if (isAccessDenied) {
      return (
        <div className="flex flex-col h-full dark:bg-gray-800">
        <main className="flex-grow flex items-center justify-center">
          <div className=" bg-[#004977] px-10 py-10 rounded-lg">
            <div className="mx-auto w-fit max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
              <div className="flex flex-col md:py-4">
                <div className="self-center">
                  <div className="inline-flex self-center">
                    <span className="my-2 block text-white truncate text-4xl font-bold">
                    Acceso Denegado
                    </span>
                  </div>
                </div>
                  <div className="text-center text-white select-none">
                    <p className="text-base">Parece que no tienes acceso a este proyecto.</p>
                    <div className="flex justify-center items-center text-center pt-4">
                    <a
                      href="https://intuitosa.com/"
                      className="inline-block bg-black text-white px-6 py-2 rounded-md shadow-md hover:bg-white hover:text-black transition duration-300"
                      rel="noopener noreferrer"
                    >
                      Ir a Intuito S.A.
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer className="text-center text-black mt-4">
          Todos Los Derechos Reservados © Intuito S.A
        </footer>
      </div>
      );
    }
  
    return (
      <div className="bg-gradient-to-b from-gray-500 to-black min-h-screen flex flex-col items-center justify-center">
        <div className="container mx-auto flex-1 flex items-center">
          <div className="mx-auto bg-white p-6 rounded-md shadow-md max-w-7xl w-96">
            <div className="text-center">
              <h4 className="text-2xl text-gray-700 font-bold">
                Redireccionando...
              </h4>
              <p className="text-gray-500 mb-4">Espere por favor</p>
            </div>
  
            <div className="text-center">
              <div className="spinner-border text-black" role="status">
                <span className="visually-hidden">Cargando...</span>
              </div>
            </div>
          </div>
        </div>
        <footer className="text-center text-gray-500 mt-4">
          Todos Los Derechos Reservados © Intuito S.A
        </footer>
      </div>
    );
};

export default Callback;