import React, { useContext, useEffect, useState } from "react";
import { LargeModalForm } from "../../../features/components/LargeModalForm";
import { useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import {
  StyleInput,
  StyleInputError,
  StyleLabel,
  StyleLabelError,
} from "../../../features/Constantes";
import { getListV2, getListV4 } from "../../../services";
import { EmitirDocContext } from "./EmitirDocProvider";
import { useSelector } from "react-redux";
import { ImpuestosMap } from "../helpers";
import moment from "moment";

const BuscarFacturaNotaCredito = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm({ mode: "onBlur" });

  const { formik, setDetalleList, setInformacionAdicionalList } = useContext(EmitirDocContext);

  useEffect(() => {
    setValue("secuencialFactura", "000-000-000000000");
  }, []);

  const empresa = useSelector((store) => store.empresa.empresa);

  const [IsLoading, setIsLoading] = useState(false);
  const [ErrorSecuencialState, setErrorSecuencialState] = useState(0);

  const renderMessage = (val) => {
    switch (val) {
      case 1:
        return (
          <p className=" text-green-500 text-sm -translate-y-5 mt-2">
            El comprobante ingresado esta autorizado.
          </p>
        );

      case 2:
        return (
          <p className=" text-yellow-500 text-sm -translate-y-5 mt-2 ">
            El comprobante ingresado existe, pero no está autorizado.
            <p className=" text-gray-500 text-xs -translate-y-5 mt-5 ">
              No puedes replicar comprobantes no autorizados en una nota de
              crédito.
            </p>
          </p>
        );

      case 3:
        return (
          <p className="  text-sm text-red-600 -translate-y-5 mt-2">
            El comprobante ingresado no existe.
            <p className=" text-gray-500 text-xs -translate-y-5 mt-5 ">
              No puedes replicar comprobantes no autorizados en una nota de
              crédito.
            </p>
          </p>
        );

      default:
        break;
    }
  };

  const handleSubmitModal = async () => {
    try {
      //if (ErrorSecuencialState !== 1) return;
      setIsLoading(true);
      let datas = getValues().secuencialFactura.split("-");
      
      let res = await getListV2(
        empresa,
        "DuplicarFactura",
        `?establecimiento=${Number(datas[0])}&puntoEm=${Number(
          datas[1]
        )}&secuencial=${Number(datas[2])}`
      );

      setInformacionAdicionalList([...res.retorno.infoAdicional.campoAdicional]);

      let identificacionCliente =
        res.retorno.infoFactura.identificacionComprador.trim();
      const valores = await getListV2(
        empresa,
        "SearchContribuyenteForForm",
        `?identificacion=${
          identificacionCliente.length === 13
            ? identificacionCliente.slice(0, 10)
            : identificacionCliente
        }`
      );

      formik.setFieldValue(
        "numeroComprobanteModificar",
        `${datas[0]}-${datas[1]}-${datas[2]}`
      );

      formik.setFieldValue(
        "numeroComprobanteModificar",
        `${datas[0]}-${datas[1]}-${datas[2]}`
      );
      let dateSplited = res.retorno.infoFactura.fechaEmision.split("/");

      formik.setFieldValue(
        "fechaEmisionComprobanteModificar",
        `${dateSplited[2]}-${dateSplited[1]}-${dateSplited[0]}`
      );

      // console.log(`${datas[0]}-${datas[1]}-${datas[2]}`);

      formik.setFieldValue(
        "clienteIdentificacion",
        res.retorno.infoFactura.identificacionComprador
      );
      formik.setFieldValue("clienteRazonSocial", valores.razonSocial);
      formik.setFieldValue("clienteDireccion", valores.direccion);
      formik.setFieldValue("clienteEmail", valores.correo);
      formik.setFieldValue("clienteTelefono", valores.telefonoCelular || "");
      formik.setFieldValue("idContribuyente", valores.idContribuyente);

      const NuevoModeloProductos = res.retorno.detalles.map((el) => {
        return {
          idServicio: null,
          idEmpresa: empresa.idEmpresa,
          idImpuestoIva:
            ImpuestosMap[el.impuestos[0].codigoPorcentaje].idImpuestoDetalle,
          codigoPrincipal: el.codigoPrincipal,
          codigoAuxiliar: el.codigoAuxiliar,
          descripcion: el.descripcion,
          precioUnitario: Number(el.precioUnitario),
          descuento: el.descuento,
          cantidad: el.cantidad,
          activo: true,
          tipo: 1,
          iva: Number(el.impuestos[0].valor),
          subTotal: Number(el.precioTotalSinImpuesto),
          fechaCreacion: "2022-09-28T11:17:25.26",
          idImpuestoIvaNavigation:
            ImpuestosMap[el.impuestos[0].codigoPorcentaje],
          servicioDetalle: el.detallesAdicionales,
        };
      });
      setDetalleList([...NuevoModeloProductos]);

      if (res !== "" && res !== undefined) {
        if (
          res.docElectronico.idEstadoDocumentoElectronico.toUpperCase() !==
          "1777AA9D-FA14-49BD-B9F0-ECE6D2952756"
        ) {
          setErrorSecuencialState(2);
        } else {
          setErrorSecuencialState(1);
        }
      }
    } catch (ex) {
    //  console.log(ex);
      setErrorSecuencialState(3);
    } finally {
      setIsLoading(false);
      document.getElementById("searchFacturaModal").click();
    }
  };

  const handleComprobarComprobante = async () => {
    try {
      let datas = getValues().secuencialFactura.split("-");
      let res = await getListV4(
        empresa,
        "ComprobarAutorizado",
        `?establecimiento=${Number(datas[0])}&puntoEm=${Number(
          datas[1]
        )}&secuencial=${Number(datas[2])}`
      );
      if (res.status === 200) {
        setErrorSecuencialState(1);
      } else if (res.status === 206) {
        setErrorSecuencialState(2);
      } else {
        setErrorSecuencialState(3);
      }
    } catch (ex) {
  //    console.log(ex);
      setErrorSecuencialState(3);
    }
  };

  return (
    <LargeModalForm
      buttonModal={"searchFacturaModal"}
      IsLoading={IsLoading}
      submitText={"Agregar"}
      title="Consultar factura"
      submitModal={handleSubmit(handleSubmitModal)}
    >
      <div className="w-full mb-2  mt-10 grid md:grid-cols-3 md:gap-6">
        <div className="w-full col-span-2">
          
          <NumberFormat
            format="###-###-#########"
            id="secuencialFactura"
            name="secuencialFactura"
            placeholder=" "
            className={errors.secuencialFactura ? StyleInputError : StyleInput}
            {...register("secuencialFactura", {
              required: true,
            })}
            onChange={(e) => setValue("secuencialFactura", e.target.value)}
            value={getValues().secuencialFactura}
          />
          <label
            htmlFor="secuencialFactura"
            className={errors.secuencialFactura ? StyleLabelError : StyleLabel}
          >
            Secuencial comprobante
          </label>
          {renderMessage(ErrorSecuencialState)}
        </div>
        <div className="mb-2 w-full group">
          <button
            onClick={handleComprobarComprobante}
            type="button"
            className="  text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center dark:bg-green-600 dark:focus:ring-green-800 dark:hover:bg-green-700"
          >
            COMPROBAR
          </button>
        </div>
      </div>
    </LargeModalForm>
  );
};

export default BuscarFacturaNotaCredito;
