import React from "react";
import NumberFormat from "react-number-format";
import { StyleInput, StyleLabel,  } from "../../Constantes";
import { useForm } from "react-hook-form";

const InputNumber = (props) => {


  return (
    <>
   
   <label
        htmlFor={props.name}
        className={
       
        
            StyleLabel + " -translate-x-0 -translate-y-0"
        }
      >
        {props.title || props.name}
      </label>
      <NumberFormat
        
    
        className={StyleInput}
        id={props.identifier}
        name={props.identifier}
        decimalScale={props.decimalMax}
        defaultValue={0}
        isAllowed={value => value.value.length <= 14}
        {...props}
      />
     </>

  );
};

export default InputNumber;
