export const PlanTaxflash = [
  {
    text: "Descarga masiva de los XML del SRI.",
  },
  {
    text: "Generación masiva de RIDE o PDF.",
  },
  {
    text: "Instalación en computadora superiores a Windows 7 y acceso con credenciales de seguridad.",
  },
  {
    text: "Soporte y capacitación vía Anydesk, correo y vídeo ilustrativo.",
  },
  {
    text: "Procesa emitidos (ventas) y recibidos (compras).",
  },
];
