import { useDispatch } from "react-redux"
import { DeleteIcon, EditIcon } from "../../../../Icons/ButtonIcons"
import { ModalStatusBodega, changeBodegaModal } from "./modalCrearBodega.slice"


export const CrearBodegaItem = ({Bodega, isSelected, bodegasChecked, handleClick}) => {
   
    const dispatch = useDispatch()
    
    return (
        <tr className="bg-white text-center font-normal" >
            <th style={{padding: "15px"}}><input type="checkbox" checked={bodegasChecked.find((bog) => bog === Bodega.codigo)} onChange={() => {
                  handleClick(Bodega.codigo)
            }} /></th>
            <th>{Bodega.codigo}</th>
            <th>{Bodega.nombreBodega}</th>
            <th>{Bodega.direccion}</th>
            <th>{Bodega.establecimiento}</th>
            <th style={{padding: "15px"}} className="flex justify-center flex-row gap-2">
                <button onClick={() => dispatch(changeBodegaModal({modalStatus: ModalStatusBodega.editar, Bodega}))}><EditIcon/></button>
                <button onClick={() => dispatch(changeBodegaModal({modalStatus: ModalStatusBodega.eliminar, Bodega}))}><DeleteIcon/></button>
            </th>
        </tr>
    )
}