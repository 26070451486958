import React from "react";
import { useFormContext } from "react-hook-form";
import {
  StyleInput,
  StyleInputError,
  StyleLabel,
  StyleLabelError,
} from "../../../features/Constantes";

const InputText = (props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
           <label
        htmlFor={props.name}
        className={
          errors[props.name]
            ? StyleLabelError
            :  "block text-[0.813rem] font-medium text-black dark:text-white"
        }
      >
        {props.title || props.name}
      </label>


      <input
        type="text"
        placeholder=" "
        className={errors[props.name]
          ? StyleInputError
          : StyleInput + (props.disabled ? " bg-[#EBEBEB]" : "")}
        {...register(props.name, props.validations)}
        {...props}
      />
    
    </>
  );
};

export default InputText;
