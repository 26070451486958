import React, { useState } from "react";
import { CardPlanesTaxflash } from "../Components/CardPlanesTaxflash";
import { PlanTaxflash } from "../data/PlanTaxflash";
import { PlanDesarrollo } from "../data/PlanDesarrollo";
import { PlanAdicional } from "../data/PlanAdicional";

const FormTaxflash = () => {
  const itemTexts = PlanTaxflash.map((item) => item.text);
  const ejex = PlanDesarrollo.map((item) => item.text);
  const adicional = PlanAdicional.map((item) => item.text);
  const [Value, setValue] = useState("CONTADORES");
  const precios = {
    CONTADORES: "$ 39.20",
    EMPRESAS: "$ 78.40",
    DESARROLLADORES: "Generamos canales de una conexión con base de datos",
    ADICIONAL: "Planes Adicionales",
  };

  const listas = {
    CONTADORES: itemTexts,
    EMPRESAS: itemTexts,
    DESARROLLADORES: ejex,
    ADICIONAL: adicional,
  };

  const active =
    "relative inline-flex items-center px-4 py-2 rounded-l-md border transition-colors duration-300  border-gray-300 bg-[#00314D] text-white text-sm font-medium text-gray-700  focus:z-10 focus:outline-none  ";
  const inactive =
    "relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500";

  return (
    <div className="mx-4 my-4 ">
      <div className="bg-white dark:bg-gray-700 p-3 rounded-md ">
        <div className="bg-[#DBDBDB] dark:text-white s dark:bg-gray-700 rounded-md p-4 ">
          <p className="font-semibold text-2xl">Planes de taxFlash</p>
          <p>Dercarga de documentos XML y PDF de forma organizada</p>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-700 p-4 my-4 rounded-lg">
        <div className="my-10 dark:text-white   justify-center items-center grid gap-x-2">
          <p className="dark:text-white text-center mb-6">
            Seleccione el tipo de plan para:
          </p>
          <span className="relative z-0 lg:inline-flex shadow-sm rounded-md ">
            <button
              onClick={() => setValue("CONTADORES")}
              type="button"
              className={Value === "CONTADORES" ? active : inactive}
            >
              Contadores
            </button>
            <button
              onClick={() => setValue("EMPRESAS")}
              type="button"
              className={
                Value === "EMPRESAS"
                  ? "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-[#00314D] text-white text-sm font-medium   focus:z-10 focus:outline-none "
                  : "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 transition-colors duration-300"
              }
              //className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              Empresas
            </button>
            <button
              onClick={() => setValue("DESARROLLADORES")}
              type="button"
              className={
                Value === "DESARROLLADORES"
                  ? "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-[#00314D] text-white text-sm font-medium   focus:z-10 focus:outline-none "
                  : "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 transition-colors duration-300"
              }
            >
              Desarrolladores
            </button>

            <button
              onClick={() => setValue("ADICIONAL")}
              type="button"
              className={
                Value === "ADICIONAL"
                  ? "-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-[#00314D] text-sm font-medium text-white  focus:z-10 focus:outline-none "
                  : "-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              }
              //className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              Adicional
            </button>
          </span>
        </div>
        <div className="grid grid-cols-1 gap-10 max-w-[25rem] my-6 mx-auto">
          <CardPlanesTaxflash
            titulo={
              Value === "CONTADORES"
                ? true
                : false || Value === "EMPRESAS"
                ? true
                : false
            }
            barner={
              Value === "CONTADORES"
                ? true
                : false || Value === "EMPRESAS"
                ? true
                : false
            }
            descripcion={
              Value === "CONTADORES"
                ? true
                : false || Value === "EMPRESAS"
                ? true
                : false
            }
            precio={precios[Value] || null}
            link="https://api.whatsapp.com/send/?phone=593961820002&text=+Hola%2C+necesito+información+del+Sistema+de+Taxflash&type=phone_number&app_absent=0"
            //descripcion={"Precio incluye IVA"}
            //lista={itemTexts}
            lista={listas[Value]}
          />
        </div>
      </div>
    </div>
  );
};

export default FormTaxflash;
