import React, { useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import {
  StyleInput,
  StyleInputError,
  StyleSelect,
} from "../../../../features/Constantes";
import { getListV2 } from "../../../../services";
import { getNoventaDiasAtras } from "../../../../services/Utilitario";
import { ModalAddImpuestoContext } from "./ModalAddImpuestoProvider";
import { EmitirDocContext } from "../EmitirDocProvider";
import LengthCounter from "../../../../features/components/LengthCounter";

const MainFields = ({
  register,
  setValue,
  getValues,
  watch,
  setError,
  errors,
}) => {
  const [TipoSustentoList, setTipoSustentoList] = useState([]);
  const [DocList, setDocList] = useState([]);

  const { DataFromAdd } = useContext(EmitirDocContext);

  const empresa = useSelector((store) => store.empresa.empresa);

  const DocSustentoObs = watch("CodSustento");
  const NumDocSustentoObs = watch("NumDocSustento");

  useEffect(() => {
    if (DocSustentoObs === "10") setShowDividendos(true);
    else setShowDividendos(false);
  }, [DocSustentoObs]);

  const { setShowDividendos, setPagoLocExt, PagoLocExt } = useContext(
    ModalAddImpuestoContext
  );

  useEffect(() => {
    // console.log(PagoLocExt)
    if (PagoLocExt === "01") setValue("PaisEfecPago", "593");
  }, [PagoLocExt, setValue]);

  useEffect(() => {
    // console.log(NumDocSustentoObs);
    // console.log(DataFromAdd);
    if (!DataFromAdd) return;
    if (!DataFromAdd.find((el) => el.NumDocSustento === NumDocSustentoObs))
      return;
    setError("NumDocSustento", "asdsaadas");
  }, [NumDocSustentoObs]);
  const [inputValue, setInputValue] = useState(''); // Estado para el valor del campo de entrada

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };
  useEffect(() => {
    (async () => {
      let impList = await getListV2(empresa, "GetTipoDocRetencion", "");
      setDocList(impList._embedded);
      //setValue("CodSustento", impList._embedded[0].codigo);
      setValue("CodDocSustento", impList._embedded[0].codigo);

      let sustentoList = await getListV2(
        empresa,
        "GetCodSustentoRetencion",
        ""
      );
      setTipoSustentoList(sustentoList._embedded);
      // setValue("CodDocSustento", sustentoList._embedded[0].codigo);
      setValue("CodSustento", sustentoList._embedded[0].codigo);
    })();
  }, [empresa]);

  return (
    <div>
      <div className="w-full  mb-2 md:mb-0 my-3">
        <div className="relative z-50 mb-2 w-full group">
          <div className="w-full grid md:grid-cols-3 md:gap-2 mb-2 ">
            <div className="relative z-120 mb-2 w-full group">
              <label className="block text-sm font-medium text-black dark:text-white">
                {" "}
                Cod. Doc. Sust. *{" "}
              </label>
              <select
                name="CodDocSustento"
                id="CodDocSustento"
                {...register("CodDocSustento", {
                  required: {
                    value: true,
                    message: "CodDocSustento es requerido",
                  },
                })}
                className={StyleSelect}
              >
                {DocList.map((el, key) => {
                  return (
                    <option key={key} value={el.codigo}>
                      {el.codigo + " - " + el.nombreComprobantes}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="relative z-120 mb-2 w-full group">
              <label className="block text-sm font-medium text-black dark:text-white">
                {" "}
                Num. Doc. Sust. *{" "}
              </label>
              <NumberFormat
                format="###-###-#########"
                id="NumDocSustento"
                name="NumDocSustento"
                {...register("NumDocSustento", {
                  required: {
                    value: true,
                    message: "Nombre es requerido",
                  },
                  maxLength: {
                    value: 17,
                    message: "No más de 17 carácteres!",
                  },
                  minLength: {
                    value: 17,
                    message: "Mínimo 17 carácteres",
                  },
                })}
                onChange={(e) => setValue("NumDocSustento", e.target.value)}
                value={NumDocSustentoObs}
                className={StyleInput}
              />
            </div>

            <div className="relative z-0 mb-2 w-full group">
              <label className="block text-sm font-medium text-black dark:text-white">
                {" "}
                Fecha emisión *{" "}
              </label>
              <input
                type="date"
                className={
                  errors.FechaEmisionDocSustento ? StyleInputError : StyleInput
                }
                name="FechaEmisionDocSustento"
                id="FechaEmisionDocSustento"
                min={getNoventaDiasAtras()}
                {...register("FechaEmisionDocSustento", {
                  required: {
                    value: true,
                    message: "FechaEmisionDocSustento es requerido",
                  },
                })}
                placeholder=" "
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full  mb-2 md:mb-0">
        <div className="relative z-0 mb-2 w-full group">
          <div className="w-full grid md:grid-cols-3 md:gap-2 mb-2 ">
            {/* <div className="relative z-0 mb-2 w-full group">
              <label className="block text-sm font-medium text-black dark:text-white">
                {" "}
                Num. Doc. Sust. *{" "}
              </label>
              <NumberFormat
                format="###-###-#########"
                id="NumDocSustento"
                name="NumDocSustento"
                {...register("NumDocSustento", {
                  required: {
                    value: true,
                    message: "Nombre es requerido",
                  },
                  maxLength: {
                    value: 17,
                    message: "No más de 17 carácteres!",
                  },
                  minLength: {
                    value: 17,
                    message: "Mínimo 17 carácteres",
                  },
                })}
                onChange={(e) => setValue("NumDocSustento", e.target.value)}
                value={watch("NumDocSustento")}
                className={StyleInput}
              />
            </div> */}
            <div className=" md:col-span-2 relative z-0 mb-2 w-full group">
              <label className="block text-sm font-medium text-black dark:text-white">
                {" "}
                Autorización{" "}
              </label>
              <NumberFormat
                format="#################################################"
                id="NumAutDocSustento"
                name="NumAutDocSustento"
                {...register("NumAutDocSustento", {
                  required: true,
                })}
                onChange={(e) => setValue("NumAutDocSustento", e.target.value)}
                value={getValues().NumAutDocSustento}
                className={
                  errors.NumAutDocSustento ? StyleInputError : StyleInput
                }
              />
             
            </div>
            <div className="  relative z-0 mb-2 w-full group">
              <label className="block text-sm font-medium text-black dark:text-white">
                {" "}
                Fecha contable{" "}
              </label>
              <input
                type="date"
                className={StyleInput}
                name="FechaRegistroContable"
                id="FechaRegistroContable"
                min={getNoventaDiasAtras()}
                {...register("FechaRegistroContable", {})}
                placeholder=" "
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mb-2 md:mb-0">
        <div className="w-full grid md:grid-cols-3 md:gap-2 mb-2 ">
          <div className="lg:col-span-2 relative z-0 mb-2 w-full group">
            <label className="block text-sm font-medium text-black dark:text-white">
              {" "}
              Cod. Sust. *{" "}
            </label>
            <select
              name="CodSustento"
              id="CodSustento"
              {...register("CodSustento", {
                required: {
                  value: true,
                  message: "Nombre es requerido",
                },
              })}
              className={StyleSelect}
            >
              {TipoSustentoList.map((el, key) => {
                return (
                  <option key={key} value={el.codigo}>
                    {el.codigo + " - " + el.tipoSustento}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="relative z-0 mb-2 w-full group">
            <label className="block text-sm font-medium text-black dark:text-white">
              {" "}
              Pago Loc Ext. *{" "}
            </label>
            <select
              id="PagoLocExt"
              name="PagoLocExt"
              placeholder=" "
              className={StyleSelect}
              {...register("PagoLocExt", {
                required: {
                  value: true,
                  message: "Nombre es requerido",
                },
                maxLength: {
                  value: 2,
                  message: "No más de 2 carácteres!",
                },
                minLength: {
                  value: 2,
                  message: "No más de 2 carácteres!",
                },
              })}
              onChange={(e) => setPagoLocExt(e.target.value)}
              value={PagoLocExt}
            >
              <option value="01">Residente</option>
              <option value="02">Extranjero</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainFields;
