import React, { useContext } from "react";
import NumberFormat from "react-number-format";
import { redondear, roundToTwo } from "../../../services/Utilitario";

import { EmitirLiquidacionContext } from "./EmitirLiquidacionProvider";
import { TableStyle, TableStyleDiv, TableStyleTBody, TableStyleTHead } from "../../../features/Constantes";

const ListaDetalle = () => {
  const {
    DetalleList,
    setDetalleList,
    setActivarModalDetalle,
    setDetalleItem,
  } = useContext(EmitirLiquidacionContext);

  const handleDeleteElement = (index) => {
    DetalleList.splice(index, 1);
    setDetalleList([...DetalleList]);
  };

  const handleEditElement = (index) => {
    let data = DetalleList[index];
    data.index = index;
    setDetalleItem(data);
    setActivarModalDetalle(true);
  };

  return (
    <>
      {DetalleList.length === 0 ? (
        <></>
      ) : (
        <div className="px-4 sm:px-6 lg:px-8">
        <div className=" flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle px-1">
              <div className={TableStyleDiv + " whitespace-nowrap"}>
                <table className={TableStyle}>
                  <thead className={TableStyleTHead }>
                    <tr>
                 
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3  text-sm font-semibold  sm:pl-6 w-44 "
                      >
                        Código
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3  text-sm font-semibold  sm:pl-6 w-[1800px] "
                      >
                       Detalle
                       
                      </th>
                      <th className=" px-3 py-3.5  text-sm  font-semibold w-44 ">
                        Cant.
                      </th>
                      <th
                        scope="col"
                        className=" px-3 py-3.5  text-sm font-semibold w-44   "
                      >
                       
                       Precio U.
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5  text-sm font-semibold  w-44  "
                      >
                       Desc.
                      </th>
                      {/* <th
                      scope="col"
                      className="px-3 py-3.5  text-sm font-semibold w-44  "
                    >
                      Identificación
                    </th> */}
                      <th
                        scope="col"
                        className="px-3 py-3.5  text-sm font-semibold w-44  "
                      >
                        Subtotal
                      </th>
           
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-sm font-semibold sm:hidden sm:table-cell md:table-cell lg:table-cell w-44"
                      >
                        Opciones
                      </th>
                    </tr>
                  </thead>
                  <tbody className={TableStyleTBody}>
                    {/* {documentos.length < 1 ? (
                      <MesageWarningTable
                        colSpan="11"
                        message="No se encontraron datos para la búsqueda."
                        className="py-1"
                      />
                    ) : null} */}
                    {DetalleList.map((el, index) => {
                 
                      return (
                        <tr key={index} className="">
                         
                          <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm ">
                          {el.codigoPrincipal}
                          </td>
                          <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm  truncate  w-[300px]  max-w-[300px] ">
                          {el.descripcion}  {el.servicioDetalle === undefined
                        ? null
                        : el.servicioDetalle === undefined
                        ? null
                        : el.servicioDetalle.map((el2, index) => (
                            <span className="block font-medium text-xs text-gray-500">
                              {el2.nombre} - {el2.valor}
                            </span>
                          ))}
                          </td>

                          <td className=" px-3 py-2 text-sm  lg:table-cell w-44 ">
                          <NumberFormat
                        decimalScale={6}
                        value={el.cantidad}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                          </td>
                          <td className=" px-3 py-3.5 text-sm lg:table-cell w-44">
                          $
                      <NumberFormat
                        decimalScale={6}
                        value={el.precioUnitario}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                          </td>
                          <td className=" px-3 py-3.5 text-sm  lg:table-cell w-44">
                          $
                      <NumberFormat
                        decimalScale={2}
                        value={roundToTwo(el.descuento ? el.descuento : 0)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                          </td>
                          <td className="relative py-4 text-sm truncate text-ellipsis  px-2  w-[300px]  max-w-[300px]">
                          $
                      <NumberFormat
                        fixedDecimalScale
                        decimalScale={2}
                        value={
                          el.precioUnitario * el.cantidad -
                          (el.descuento ? el.descuento : 0)
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                          </td>

                          <td className=" py-3.5 text-sm flex items-center w-44 justify-center text-center">
                          <button
                      type="button"
                      onClick={() => handleEditElement(index)}
                      className="text-gray-400 hover:text-gray-600 text-xs mr-2 focus:outline-none focus:shadow-outline-grayduration-150
                             ease-in-out"
                      data-bs-toggle="modal"
                      data-bs-target="#editDetalleFacturaModal"
                      style={{ verticalAlign: "top" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      onClick={() => handleDeleteElement(index)}
                      className="text-red-500 hover:text-red-600 text-xs "
                      style={{ verticalAlign: "top" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button>
                          </td>
                       
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
             
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

export default ListaDetalle;
