
  export const PlanNatural = [
    {
      text: "Cédula de identidad (escaneada PDF).",
    },
    {
      text: "Foto solo del rostro con cédula en mano.",
    },
    {
      text: "Llenar el formulario y firmar la autorización de firma (escaneada PDF).",
    },
    
    
   
  ];
  