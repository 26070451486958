import React, { createContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

export const ReporteDocGeneradoContext = createContext();

const ReporteDocGeneradoProvider = (props) => {
  const [docGenerado, setDocGenerado] = useState([]);
  const [AutorizarElement, setAutorizarElement] = useState(null);
  const [cargandoData, setCargandoData] = useState(false);
  const [cargandoDataExcel, setCargandoDataExcel] = useState(false);

  const getReporDoc = async (e) => {
    setCargandoData(true);
    const res = await axios.get(
      `/api/DocumentoElectronico/ReporteDocumentosGenerados?identificacionusuario=${e.identificacionusuario}&identificacioncontribuyente=${e.identificacioncontribuyente}&fechaInicio=${e.fechaInicio}&fechaFin=${e.fechaFin}`
    );

    if (res.status === 200) {
      setDocGenerado(res.data);
      setCargandoData(false);
    } else {
      setCargandoData(false);
      setDocGenerado([]);
    }
  };

  const getReporDocExcel = async (e) => {
    try {
      setCargandoDataExcel(true);
      const res = await axios.get(
        `/api/ReporteMasivo/DescargaDocumentosGenerados?identificacionusuario=${e.identificacionusuario}&identificacioncontribuyente=${e.identificacioncontribuyente}&fechaInicio=${e.fechaInicio}&fechaFin=${e.fechaFin}`
      );

      // Verifica si la solicitud fue exitosa (código de estado 200)
      if (res.status === 200) {
        // Descargar el archivo Excel
        downloadExcel(res.data, "ReporteExcel");
        setCargandoDataExcel(false);
      } else {
        console.error(`Error en la solicitud: ${res.statusText}`);
        toast.error("Error al descargar el excel");
        setCargandoDataExcel(false);
      }
    } catch (error) {
      console.error("Error al obtener el archivo Excel:", error);
      toast.error("Error al descargar el excel");
      setCargandoDataExcel(false);
    }
  };

  const downloadExcel = (base64String, fileName) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.xlsx`; // Cambia la extensión a .xlsx
    link.click();
  };

  return (
    <ReporteDocGeneradoContext.Provider
      value={{
        AutorizarElement,
        setAutorizarElement,
        getReporDoc,
        setDocGenerado,
        docGenerado,
        getReporDocExcel,
        cargandoData,
        cargandoDataExcel,
      }}
    >
      {props.children}
    </ReporteDocGeneradoContext.Provider>
  );
};

export default ReporteDocGeneradoProvider;
