import React from 'react';

import FormFirmaelect from './FormFactuelect';

const FacturacionElec = () => {
    return (
       
        <div className=" mx-4 my-4">
        <FormFirmaelect />
        </div>
       
    );
};

export default FacturacionElec;