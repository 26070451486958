import React from "react";

const MesageError = ({message = "Error"}) => {
    return (
        // <div className="text-sm  text-red-600 dark:text-red-500 inline-flex">
        //     <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" className="w-4 h-4 mr-2 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        //         <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
        //     </svg>
        //     <div style={{ position: 'relative', top: '-2px' }} >
        //         <span className="font-semibold">{message}</span> 
        //     </div>
        // </div>
        <p className="  text-red-600 text-xs transform -translate-y-5  top-5 -z-10 origin-[0]">
        <span>{message}
            </span>
      </p>
    )
}

export default MesageError;