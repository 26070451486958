import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getListV2, PostElement } from "../../../services";
import {
  StyleInput,
  StyleLabel,
  StyleLabelError,
  StyleInputError,
  StyleSelect,
} from "../../../features/Constantes";
import MesageError2 from "../../../features/components/MessageError2";
import { ReporteUsuariosContext } from "./ReporteUsuariosProvider";
import { LargeModalForm } from "../../../features/components/LargeModalForm";

import { toast } from "react-toastify";

const ModalAdd = () => {
  const { formik, setIsLoading, IsLoading } = useContext(ReporteUsuariosContext);

  const initialState = {
    identificacion: "",
    idUsuario: "",
    nombre: "",
    estado: "True",
    esAdministrador: "False",
    formularios: [],
  };

  const [NewItem, setNewItem] = useState(initialState);

  const [EsAdminShow, setEsAdminShow] = useState(false);

  const [CheckedItems, setCheckedItems] = useState([]);

  const [FormulariosAdmin, setFormulariosAdmin] = useState([]);

  const [FormulariosInvitado, setFormulariosInvitado] = useState([]);

  const [ShowCheckeds, setShowCheckeds] = useState(true);

  const [ErrorState, setErrorState] = useState(false);

  const empresa = useSelector((store) => store.empresa.empresa);

  useEffect(() => {
    consultarListaFormularios();
  }, []);

  const handleChangeEsAdmin = (e) => {
    setEsAdminShow(e.target.value);
    handleChangeModal(e);
  };

  const consultarListaFormularios = async () => {
    let formsListInvitados = await getListV2(empresa, "GetInvitadoForms");
    setFormulariosInvitado(formsListInvitados._embedded);

    let formsListAdmin = await getListV2(empresa, "GetAdminForms");
    const formulariosActivos = formsListAdmin._embedded.filter(formulario => formulario.activo);

    setFormulariosAdmin(formulariosActivos);
  };

  const handleSearchUser = async (e) => {
    try {
      let userInfo = await getListV2(
        empresa,
        "GetUserByIdentificacion",
        `?identificacion=${e.target.value}`
      );

      if (userInfo._embedded === null) {
        setErrorState(true);
      } else setErrorState(false);

      let estado = userInfo._embedded.activo ? "True" : "False";

      setNewItem({
        ...NewItem,
        nombre: userInfo._embedded.nombre,
        estado,
        idUsuario: userInfo._embedded.idUsuario,
      });
      setShowCheckeds(true);
    } catch (error) {
      setNewItem({ ...NewItem, nombre: "", estado: "True", idUsuario: null });
    }
  };

  const handleSubmitModal = async () => {
    try {
      let request = NewItem;
      request.formularios = CheckedItems;

      const postRes = await PostElement(empresa, "AddUserInEmpresa", request);
      const toastPro = () => new Promise((resolve) => setInterval(() => resolve(postRes), 2000));

      await toast.promise(toastPro(), {
        pending: {
          render() {
            setIsLoading(true);
            return 'Guardando usuario...'
          },
          isLoading: true,
        },
        success: {
          render() {
            const formsDesactivadosAdmin = FormulariosAdmin.map((el) => { el.checked = false; return el; });
            setFormulariosAdmin([...formsDesactivadosAdmin]);
            let formsDesactivadosInvitado = FormulariosInvitado.map((el) => { el.checked = false; return el; });
            setFormulariosInvitado([...formsDesactivadosInvitado]);
            document.getElementById("addUsuarioModalclose").click();
            formik.handleSubmit();
            setNewItem(initialState);
            setCheckedItems([]);
            setShowCheckeds(false);
            setErrorState(false);
            setIsLoading(false);
            return 'Se ha enviado la invitacion al usuario.'
          },
          autoClose: 2000,
          closeOnClick: true
        },
        error: {
          render() {
            setIsLoading(false);
            return 'Algo ha sucedido, intente nuevamente.'
          },
          autoClose: 2000,
          closeOnClick: true
        }
      })
    } catch (ex) {
      setErrorState(true);
      setNewItem(initialState);
    }
  };

  const handleChangeModal = (e) => {
    if (e.target.value.length >= 20) return e.target.value;

    setNewItem({ ...NewItem, [e.target.name]: e.target.value });
  };

  const handleCheckedItemInvitado = (el, e, index) => {
    let datos = [...FormulariosInvitado];
    datos[index].checked = e.target.checked;
    setFormulariosInvitado(datos);

    if (e.target.checked) {
      setCheckedItems([...CheckedItems, el]);
    } else {
      let newItems = CheckedItems.filter(
        (el2) => el.idFormulario !== el2.idFormulario
      );
      setCheckedItems(newItems);
    }
  };

  const handleCheckedItemAdmin = (el, e, index) => {
    let datos = [...FormulariosAdmin];
    datos[index].checked = e.target.checked;
    setFormulariosAdmin(datos);

    if (e.target.checked) {
      setCheckedItems([...CheckedItems, el]);
    } else {
      let newItems = CheckedItems.filter(
        (el2) => el.idFormulario !== el2.idFormulario
      );
      setCheckedItems(newItems);
    }
  };

  const handleCheckAllInvitado = (e) => {
    let datos = FormulariosInvitado.map(el => {
      el.checked = e.target.checked;
      return el;
    });
    setFormulariosInvitado([...datos]);

    if (e.target.checked) {
      setCheckedItems(prev => [...prev, ...datos]);
    } else {
      let newItems = CheckedItems.filter(
        (el) => el.rolId !== "80dd2f96-00f0-4a19-aa3d-01ed687a2970"
      );
      setCheckedItems([...newItems]);
    }
  }

  const handleCheckAllAdmin = (e) => {
    let datosAdmin = FormulariosAdmin.map(el => {
      el.checked = e.target.checked;
      return el;
    });

    setFormulariosAdmin([...datosAdmin]);

    if (e.target.checked) {
      setCheckedItems(prev => [...prev, ...datosAdmin]);
    } else {
      let newItems = CheckedItems.filter(
        (el) => el.rolId !== "80dd2f96-00f0-4a19-aa3d-01ed447a2970"
      );
      setCheckedItems([...newItems]);
    }
  }

  return (
    <LargeModalForm
      textSuccess={"Edicion del elemento realizada con exito!"}
      buttonModal={"addUsuarioModal"}
      submitText="Invitar"
      title="Invitar usuario"
      submitModal={handleSubmitModal}
      IsLoading={IsLoading}
    >
      <div className="relative z-0 mb-2 pt-8 w-full group">
        <div className="w-full grid md:grid-cols-2 md:gap-2 mb-2 ">
          <div className="relative z-0 mb-2 w-full group">
            <input
              type="text"
              className={ErrorState ? StyleInputError : StyleInput}
              placeholder=" "
              id="identificacion"
              name="identificacion"
              onChange={handleChangeModal}
              onBlur={handleSearchUser}
              value={NewItem.identificacion || ""}
            />
            <label
              htmlFor="identificacion"
              className={ErrorState ? StyleLabelError : StyleLabel}
            >
              Identificacion
            </label>

            {ErrorState ? (
              <MesageError2
                message={
                  "El usuario ingresado no existe o ya esta relacionado con esta empresa!"
                }
              />
            ) : null}
          </div>
          <div className="relative z-0 mb-2 w-full group">
            <input
              type="text"
              className={StyleInput}
              placeholder=" "
              id="nombre"
              name="nombre"
              readOnly
              value={NewItem.nombre || ""}
            />
            <label htmlFor="nombre" className={StyleLabel}>
              Nombre
            </label>
          </div>
        </div>
      </div>

      <div className="relative z-0 mb-2 w-full group">
        <div className="w-full grid md:grid-cols-2 md:gap-2 mb-2 ">
          <div className="relative z-0 mb-2 w-full group">
            <select
              name="estado"
              id="estado"
              onChange={handleChangeModal}
              value={NewItem.estado}
              className={StyleSelect}
              x-model="item.gst"
            >
              <option value="True">ACTIVO</option>
              <option value="False">INACTIVO</option>
            </select>
            <label htmlFor="estado" className={StyleLabel}>
              Estado
            </label>
          </div>
          <div className="relative z-0 mb-2 w-full group">
            <select
              name="esAdministrador"
              id="esAdministrador"
              onChange={handleChangeEsAdmin}
              value={NewItem.esAdministrador}
              className={StyleSelect}
              x-model="item.gst"
            >
              <option value="True">SI</option>
              <option value="False">NO</option>
            </select>
            <label htmlFor="nombre" className={StyleLabel}>
              Es administrador
            </label>
          </div>
        </div>
      </div>

      <div className="py-0 mb-4 flex-wrap justify-between">
        
        <div className="overflow-x-auto  relative overflow-hidden shadow ring-1 ring-black  ring-opacity-5 md:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase divide-y bg-[#D7D7D7] dark:bg-gray-600 dark:text-gray-400">
              <tr className="p-2">
                <th scope="col" className="py-2 px-4 ">
                  <input
                    onChange={(e) => handleCheckAllInvitado(e)}
                    id="checkbox-table-search-2"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </th>
                <th scope="col" className="py-1 px-6 ">
               PERMISOS GENERALES
                </th>
              </tr>
            </thead>
            <tbody className="">
              {ShowCheckeds
                ? FormulariosInvitado.map((el, index) => (
                  <tr
                    key={index}
                    className="bg-white dark:bg-gray-800 dark:hover:bg-gray-900 hover:bg-gray-200  border-b-gray-600"
                  >
                    <td className="py-2 px-4 w-4">
                      <div className="flex items-center">
                        <input
                          checked={FormulariosInvitado[index].checked}
                          onChange={(e) =>
                            handleCheckedItemInvitado(el, e, index)
                          }
                          id="checkbox-table-search-2"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="checkbox-table-search-2"
                          className="sr-only"
                        >
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="py-1 px-6">{el.nombre}</td>
                  </tr>
                ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
      {NewItem.esAdministrador === "True" ? (
        <div className="py-0  flex-wrap justify-between mb-0">
         
          <div className="overflow-x-auto  relative overflow-hidden shadow ring-1 ring-black  ring-opacity-5 md:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase divide-y bg-[#D7D7D7] dark:bg-gray-600 dark:text-gray-400">
                <tr>
                  <th scope="col" className="py-2 px-4 ">
                    <input
                      onChange={(e) => handleCheckAllAdmin(e)}
                      id="checkbox-table-search-2"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </th>
                  <th scope="col" className="py-1 px-6 ">
                  PERMISOS AMINISTRADOR
                  </th>
                </tr>
              </thead>
              <tbody>
                {ShowCheckeds && (EsAdminShow === "True" || EsAdminShow)
                  ? FormulariosAdmin.map((el, index) => (
                    <tr
                      key={index}
                      className="bg-white dark:bg-gray-800 dark:hover:bg-gray-900 hover:bg-gray-200  border-b-gray-600 "
                    >
                      <td className="py-2 px-4 w-4">
                        <div className="flex items-center">
                          <input
                            checked={FormulariosAdmin[index].checked}
                            onChange={(e) =>
                              handleCheckedItemAdmin(el, e, index)
                            }
                            id="checkbox-table-search-2"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="checkbox-table-search-2"
                            className="sr-only"
                          >
                            checkbox
                          </label>
                        </div>
                      </td>
                      <td className="py-1 px-6">{el.nombre}</td>
                    </tr>
                  ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </LargeModalForm>
  );
};

export default ModalAdd;