import { createSlice } from "@reduxjs/toolkit";

export const ModalStatusBodega = {
    off: "off",
    editar: "editar",
    eliminar: "eliminar"
}

const initialState = {
    modalStatus : ModalStatusBodega.off,
    registroAfectado: null
}

const slice = createSlice({
    name: "modalBodega",
    initialState,
    reducers: {
        initializeApp: () => initialState,
        changeBodegaModal: (state,action) => {
            if(action.payload !== null){
                state.modalStatus = action.payload.modalStatus
                state.registroAfectado = action.payload.Bodega
            }
        },
        BodegaModalOff: (state,action) => {
            state.modalStatus = ModalStatusBodega.off
        }
    }
})

export const {
    changeBodegaModal,
    BodegaModalOff
} = slice.actions

export default slice.reducer