import { getListV2 } from "../../../../services";
import { roundToTwo } from "../../../../services/Utilitario";

/**
 * TipoImpuesto
 * @typedef {object} TipoImpuesto
 * @property {string} IdImpuesto - Guid del impuesto.
 * @property {number} BaseIva - Valor base del producto sin Impuesto incluido.
 * @property {number} TotalIva - Valor base del producto sin Impuesto incluido.
 */

/**
 * HashMap con los Guid de los impuestos y su valor en entero.
 */
// const TarifaMap = {
//   "8E7CF164-C66B-4312-945F-57AE08C86A33": 0,
//   "81BAF871-E113-48CF-8AA8-670CB43494B6": 0,
//   "8DCD2CFA-5F9D-4FB7-B27A-87447D6CDDE7": 14,
//   "F03C7F10-2773-4505-ACCB-B2FA88D62049": 0,
//   "20FA15DF-58A0-4546-9DB1-BEE1CCCACF3E": 8,
//   "A5B8F607-8D38-4150-97AF-C591F83678D8": 12,
//   "BED68281-CF9C-458E-9E0F-517A8FB02528": 15,
//   "2ED52B34-BA6C-475B-A82D-0F7C933412A7": 5,
// };

/**
 * @param {Object[]} DetallesList - Recibe la lista de detalles de un comprobante para generar el nuevo arreglo.
 * @returns {TipoImpuesto[]} valor mapeado, reducido y calculado segun su tipo de impuesto.
 */

export const GetIiva = async (empresa) => {
  try {
    let data = await getListV2(
      empresa,
      "GetAllimpDetalles",
      "?activo=true&idImpuesto=1"
    );
    const Tarifa = data._embedded.reduce((acc, el) => {
      return {
        ...acc,
        [el.idImpuestoDetalle.toUpperCase()]: el.porcentaje * 100,
      };
    }, {});
    // const Tarifa = data._embedded.map((el) => {
    //   return {
    //     [el.idImpuestoDetalle]: el.codigo,
    //   };
    // });
    return Tarifa;
  } catch (error) {
    console.log(error);
  }
};
export const CalcularImpuestosList = async (DetallesList, empresa, Totales) => {
  const res = await GetIiva(empresa);

  const ValuesMapped = DetallesList.map((el) => {
    const idImpuesto = el.idImpuestoIva.toUpperCase();
    const baseIva = roundToTwo(el.subTotal);

    let totalIva;

    switch (idImpuesto) {
     
      case "BED68281-CF9C-458E-9E0F-517A8FB02528":
        totalIva = roundToTwo(Totales.totalIva15);
        break;
      case "2ED52B34-BA6C-475B-A82D-0F7C933412A7":
        totalIva = roundToTwo(Totales.totalIva5);
        break;
      case "47BFE2B8-3630-4F76-8727-2D6808B862AC":
        totalIva = roundToTwo(Totales.totalIva13);
        break;
      case "20FA15DF-58A0-4546-9DB1-BEE1CCCACF3E":
        totalIva = roundToTwo(Totales.totalIva8);
        break;
      case "A5B8F607-8D38-4150-97AF-C591F83678D8":
        totalIva = roundToTwo(Totales.totalIva);
        break;

      default:
        totalIva = 0;
        break;
    }

    return {
      IdImpuesto: idImpuesto,

      BaseIva: baseIva,
      TotalIva: totalIva,
    };
  });

  return ReduceImpuestos(ValuesMapped, res);
};

/* NUEVO */
export const CalcularImpuestos = async (DetallesList, empresa) => {
  try {
    const tarifas = await GetIiva(empresa);


    const idMap = Object.keys(tarifas).reduce((map, key) => {
      const porcentaje = tarifas[key];
      map[porcentaje] = key;
      return map;
    }, {});

    const impuestosAcumulados = DetallesList.reduce((acc, el) => {
      const tarifaPorcentaje = parseInt(el.TARIFA, 10);
      const idImpuesto = idMap[tarifaPorcentaje] || null;


      const precioUnitario = parseFloat(el.PRECIOUNITARIO);
      const cantidad = parseFloat(el.CANTIDAD);

      if (isNaN(precioUnitario) || isNaN(cantidad) || isNaN(tarifaPorcentaje)) {
        console.warn("Datos inválidos en el detalle:", el);
        return acc;
      }

      const baseImponible = roundToTwo(precioUnitario * cantidad);
      const valorIva = roundToTwo(baseImponible * (tarifaPorcentaje / 100));

      if (idImpuesto !== null) {
        if (!acc[idImpuesto]) {
          acc[idImpuesto] = { BaseIva: 0, TotalIva: 0 };
        }

        acc[idImpuesto].BaseIva += baseImponible;
        acc[idImpuesto].TotalIva += valorIva;
      } else {
        console.warn("IdImpuesto no encontrado para tarifa:", el.TARIFA);
      }

      return acc;
    }, {});

    return Object.keys(impuestosAcumulados).map(idImpuesto => ({
      IdImpuesto: idImpuesto,
      BaseIva: impuestosAcumulados[idImpuesto].BaseIva,
      TotalIva: impuestosAcumulados[idImpuesto].TotalIva
    }));
  } catch (error) {
    console.error("Error en el cálculo de impuestos:", error);
    return [];
  }
};

/* NUEVO */


/**
Hace un reduce de todos los productos y servicios segun su tipo de impuesto.
* @param {TipoImpuesto[]} ValuesMapped - Arreglo de productos y servicios.
* @returns {TipoImpuesto[]} Arreglo reducido y organizado.
*/
const ReduceImpuestos = (ValuesMapped, res) => {
  let ValuesReduced = Object.keys(res).map((el) => {
    const FindDetallesByIdIva = ValuesMapped.filter(
      (imp) => imp.IdImpuesto === el
    );
    if (FindDetallesByIdIva.length > 0) {
      let calculated = FindDetallesByIdIva.reduce((total, next) => {
        return {
          ...total,
          BaseIva: total.BaseIva + next.BaseIva,
          TotalIva: total.TotalIva,
        };
      });
      calculated.TotalIva = roundToTwo(calculated.TotalIva);
      calculated.BaseIva = roundToTwo(calculated.BaseIva);
      return calculated;
    } else {
      return null;
    }
  });

  return ValuesReduced.filter((el) => el !== null);
};
