import moment from 'moment';
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux';
import { LargeModalForm } from '../../../features/components/LargeModalForm';
import { PostElement } from '../../../services';
import { rellenarCeros } from '../../../services/Utilitario';
import { ReporteFacturaContext } from './ReporteFacturaProvider';

const ModalAutorizar = () => {
    const { AutorizarElement, formik } = useContext(
        ReporteFacturaContext
      );
      const [IsLoading, setIsLoading] = useState(false);
    
      const empresa = useSelector((store) => store.empresa.empresa);
    
      const handleSubmitModal = async () => {
        setIsLoading(true);
        await PostElement(empresa, "AutorizarDocumento", {
          idDocumentoElectronico: AutorizarElement.idDocumentoElectronico,
        });
        document.getElementById("AutorizarFacturaModal").click();
        setIsLoading(false);
        formik.handleSubmit(formik.values);
      };
    
      const getDocType = (codigoTipoDocumento) => {
        switch (codigoTipoDocumento) {
          case "01":
            return "Factura";
    
          case "07":
            return "Comprobante de Retencion";
    
          case "04":
            return "Nota de Credito";
    
          case "05":
            return "Nota de Debito";
    
          case "06":
            return "Guia de Remision";
    
          case "03":
            return "Liquidacion de Compra";
    
          default:
            return "N/A";
        }
      };
    
      return (
        <LargeModalForm
          buttonModal={"AutorizarFacturaModal"}
          title="AUTORIZAR DOCUMENTO ANULADO"
          IsLoading={IsLoading}
          submitText="AUTORIZAR"
          submitModal={handleSubmitModal}
        >
          {AutorizarElement && (
            <div>
              <div>
                <p>
                  <b>Tipo de documento:</b>{" "}
                  {getDocType(AutorizarElement.codigoTipoDocumento)} -{" "}
                  {`${rellenarCeros(
                    AutorizarElement.establecimiento,
                    3
                  )}-${rellenarCeros(
                    AutorizarElement.puntoEmision,
                    3
                  )}-${rellenarCeros(AutorizarElement.secuencial, 9)}`}
                </p>
                <p>
                  <b>Fecha emisión:</b>{" "}
                  {moment(AutorizarElement.fechaCreacion).format(
                    "MMMM D YYYY, h:mm A"
                  )}
                </p>
                <p>
                  <b>Contribuyente:</b>{" "}
                  {AutorizarElement.idContribuyenteNavigation.razonSocial} -{" "}
                  {AutorizarElement.idContribuyenteNavigation.identificacion}
                </p>
                <p>
                  <b>Importe Total:</b> ${AutorizarElement.importeTotal}
                </p>
              </div>
              <div className=" my-3">
                {/* <span className="text-xl text-red-600">
                  Esta autorizacion es solo dentro del ambiente del facturador web,
                  no interactua ni afecta a los comprobantes anulados en el SRI.
                </span> */}
                <div className="rounded-md bg-yellow-50 dark:bg-gray-800 p-4">
  <div className="flex">
    <div className="flex-shrink-0">
    
      <svg className="h-5 w-5 text-yellow-400 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
      </svg>
    </div>
    <div className="ml-3">
      <h3 className="text-sm font-medium text-yellow-800 dark:text-gray-400">Advertencia</h3>
      <div className="mt-2 text-sm text-yellow-700 dark:text-gray-400">
        <p>  Esta autorización es solo dentro del ambiente del facturador web,
                  no interactua ni afecta a los comprobantes anulados en el SRI.</p>
      </div>
    </div>
  </div>
</div>
              </div>
            </div>
          )}
        </LargeModalForm>
      );
}

export default ModalAutorizar