import React, { useState, useContext, useEffect } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import MesageErrorTable from "../../../features/components/MesageErrorTable";

import { StyleSelect, StyleInput } from "../../../features/Constantes";
import { getListV2 } from "../../../services";
import { EmitirLiquidacionContext } from "./EmitirLiquidacionProvider";
import { redondear, roundToTwo } from "../../../services/Utilitario";
import { toast } from "react-toastify";
import MsjRequerid from "../../../features/components/MsjRequerid";

const FormaPago = () => {
  const { FormaPagoList, setFormaPagoList, ValorTotal, DetalleList } =
    useContext(EmitirLiquidacionContext);

  const empresa = useSelector((store) => store.empresa.empresa);

  const [TiposPago, setTiposPago] = useState([]);
  const [IsLoading, setIsLoading] = useState();

  useEffect(() => {
    formik.setFieldValue("valor", roundToTwo(ValorTotal));
  }, [ValorTotal, DetalleList]);

  const formik = useFormik({
    initialValues: {
      tipoPago: "",
      descripcionTipoPago: "",
      tiempoPago: "DIAS",
      plazoPago: 0,
      valor: ValorTotal,
    },
    validationSchema: Yup.object({
      valor: Yup.number().required("REQUERIDO").min(0),
      plazoPago: Yup.number().required("REQUERIDO").integer().min(0),
    }),
    onSubmit: async (obj) => {
      try {
        setIsLoading(true);
        let valores = FormaPagoList.find((el) => el.valor <= 0);
        if (valores !== undefined && obj.valor <= 0) return setTimeout(() => { setIsLoading(false) }, 2000);
        setFormaPagoList([...FormaPagoList, formik.values]);
        formik.setFieldValue("plazoPago", "0");
        formik.setFieldValue("valor", ValorTotal);
        setTimeout(() => { setIsLoading(false) }, 2000)
      } catch (error) {
       // console.log(error);
      }
    },
  });

  useEffect(() => {
    (async () => {
      let data = await getListV2(empresa, "GetFormasPago");
      setTiposPago(data._embedded);
      formik.setFieldValue(
        "descripcionTipoPago",
        data._embedded[0].descripcion
      );
      formik.setFieldValue("tipoPago", data._embedded[0].idFormaPagoSri);
    })();
  }, [empresa]);

  const handleDeleteElement = (index) => {
    FormaPagoList.splice(index, 1);
    //let value = FormaPagoList.filter(el => el.index !== index);
    setFormaPagoList([...FormaPagoList]);
  };

  const handleChangeFormaPago = (e) => {
    const description =
      e.target.selectedOptions[0].getAttribute("data-description");
    formik.setFieldValue("descripcionTipoPago", description);

    formik.handleChange(e);
  };

  const handleChangeValorTotal = (e) => {
    /*console.log('valorIntento: ' + e.target.value);
        console.log('TotalActual: ' + ValorTotal);*/
    if (e.target.value > ValorTotal) e.target.value = ValorTotal;
    formik.handleChange(e);
  };

  const handleEditPlazoPago = (e) => {
    if (e.target.value.length > 4) {
      return e.target.value;
    }
    formik.handleChange(e);
  };

  return (
    <>
   
    {DetalleList.length > 0 &&

      <div className=" flex-wrap justify-between my-2 w-full    ">
      
      <div className="flex align-middle justify-center flex-col">
        <div className=" grid md:grid-cols-1 md:gap-6">
          <div className="relative z-0 mb-2 w-full group">
            <label className="block text-[0.813rem] font-medium text-black dark:text-white">
              {" "}
              Tipos de pago: *{" "}
            </label>
            <select
              type="text"
              className={StyleSelect}
              placeholder=" "
              name="tipoPago"
              id="tipoPago"
              defaultValue={formik.values.tipoPago}
              onChange={handleChangeFormaPago}
              onBlur={formik.handleBlur}
            >
              {TiposPago.map((el, index) => (
                <option
                  key={index}
                  data-index={index}
                  data-description={el.descripcion}
                  value={el.idFormaPagoSri}
                >
                  {" "}
                  {el.descripcion}{" "}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className=" grid md:grid-cols-4 md:gap-6">
          <div className="relative z-0 mb-2 w-full group">
            <label className="block text-[0.813rem] font-medium text-black dark:text-white">
              {" "}
              Tiempo:{" "}
            </label>
            <select
              type="text"
              className={StyleSelect}
              placeholder=" "
              name="tiempoPago"
              id="tiempoPago"
              defaultValue={formik.values.tiempoPago}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option key="1" value="DIAS">
                {" "}
                DÍAS{" "}
              </option>
              <option key="2" value="MES">
                {" "}
                MES{" "}
              </option>
              <option key="3" value="AÑO">
                {" "}
                AÑO{" "}
              </option>
            </select>
          </div>
          <div className="relative z-0 mb-2 w-full group">
            <label className="block text-[0.813rem] font-medium text-black dark:text-white">
              {" "}
              Plazo:{" "}
            </label>
            <input
              type="number"
              className={StyleInput}
              name="plazoPago"
              id="plazoPago"
              value={formik.values.plazoPago}
              onChange={handleEditPlazoPago}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="relative z-0 mb-2 w-full group ">
            <label className="block text-[0.813rem] font-medium text-black dark:text-white">
              {" "}
              Valor:{" "}
            </label>
            <input
              type="number"
              className={StyleInput}
              name="valor"
              id="valor"
              value={formik.values.valor}
              onChange={handleChangeValorTotal}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="relative z-0 mb-2 w-full group  pt-3 text-center">
            <button
              type="submit"
              className=" inline-block mt-0 text-white bg-blue-600 dark:bg-gray-700 dark:border-none w-full lg:w-28  hover:bg-blue-700  font-semibold p-2 text-[0.813rem] border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:shadow-outline-grayduration-150
              ease-in-out"
              onClick={formik.handleSubmit}
              disabled={IsLoading}
            >
              {
                IsLoading ? 'Cargando...' : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="inline mr-1   -ml-1 w-5 h-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    AGREGAR
                  </>
                )
              }
            </button>
          </div>
        </div>
      </div>

      {FormaPagoList.length > 0 ? 
        <div className="overflow-x-auto overflow-y-auto dark:bg-gray-700 dark:border-gray-600   relative overflow-hidden border rounded-md  ">
        <table className="w-full text-[0.813rem] text-center text-gray-500 dark:text-gray-400 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr className="">
              <th className="px-1 py-1 ">Forma de pago</th>
              <th className=" px-1 py-1">Plazo</th>
              <th className="px-1 py-1">Valor</th>
              <th className="px-1 py-1"></th>
            </tr>
          </thead>
          <tbody>
            {FormaPagoList.length === 0 ? (
              <MesageErrorTable colSpan="4" message="Información requerida" />
            ) : null}
            {FormaPagoList.map((el, index) => (
              <tr
                key={index}
                className="bg-white border-b text-[0.813rem] dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td className="py-2 px-1"> {el.descripcionTipoPago}</td>
                <td className="py-2 px-1">
                  {" "}
                  {el.plazoPago} {el.tiempoPago}
                </td>
                <td className="py-2 px-1"> ${el.valor}</td>
                <td className="py-2 px-1">
                  <button onClick={() => handleDeleteElement(index)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>:
      <MsjRequerid />
      }
    
    </div>

     }
    </>
  );
};

export default FormaPago;
