import { useEffect, useState } from "react";
import { getListV2 } from "../../../../../services";
import { useSelector } from "react-redux";
import SelectForm from "../../../../../features/components/common/SelectForm";
import { rellenarCeros } from "../../../../../services/Utilitario";
import { useFormContext } from "react-hook-form";

const useSecuencialLoader = () => {
  const [Establecimientos, setEstablecimientos] = useState([]);
  const [PuntosEmision, setPuntosEmision] = useState([]);

  const empresa = useSelector((store) => store.empresa.empresa);

  const { setValue, watch } = useFormContext();

  const estbObs = watch("establecimiento");
  const ptoEmbObs = watch("puntoEmision");

  useEffect(() => {
    handleChangeEstablecimiento();
  }, [estbObs]);

  useEffect(() => {
    handleChangePuntoEmision();
  }, [ptoEmbObs]);

  const EstbParsed = Establecimientos.map((el) => {
    return {
      value: el.codigo,
      name: rellenarCeros(el.codigo, 3),
    };
  });

  const PtoEmParsed = PuntosEmision.map((el) => {
    return {
      value: el.codigo,
      name: rellenarCeros(el.codigo, 3),
    };
  });

  const handleChangePuntoEmision = async () => {
    const puntoEmision = PuntosEmision.find(
      (el) => el.codigo === Number(ptoEmbObs)
    );

    await getSecuencial(puntoEmision);
  };

  const handleChangeEstablecimiento = async () => {
    const establecimiento = Establecimientos.find(
      (el) => el.codigo === Number(estbObs)
    );

    await getPuntosEmision(establecimiento);
  };

  const getSecuencial = async (puntoEmisionObj) => {
    try {
      if(!puntoEmisionObj) return null;
      const GetSecuencial = await getListV2(
        puntoEmisionObj,
        "getSecuencia-Factura"
      );

      setValue("secuencial", GetSecuencial._embedded.codigo);
    } catch (error) {
     // console.log(error);
      setValue("secuencial", "");
    }
  };

  const getPuntosEmision = async (establecimiento) => {
      if(!establecimiento) return null;
    const AllPtosEm = await getListV2(
      establecimiento,
      "GetListPuntosEmision",
      `?Estado=1`
    );

    if (AllPtosEm._embedded.length > 0) {
      const DefaultPtoEm = AllPtosEm._embedded[0];

      setPuntosEmision(AllPtosEm._embedded);

      setValue("puntoEmision", DefaultPtoEm.codigo);
      await getSecuencial(DefaultPtoEm);
    } else {
      setValue("puntoEmision", "");
      setPuntosEmision([]);
    }
  };

  const getEstablecimientos = async () => {
    const AllEstablecimientos = await getListV2(
      empresa,
      "ObtenerEstablecimientosPorEmpresa"
    );

    if (AllEstablecimientos._embedded.length <= 0) return null;

    const DefaultEstab = AllEstablecimientos._embedded[0];
    setValue("establecimiento", DefaultEstab.codigo);

    setEstablecimientos([...AllEstablecimientos._embedded]);

    await getPuntosEmision(DefaultEstab);
  };

  const EstablecimientoSelector = () => {
    return (
      <SelectForm
        name={"establecimiento"}
        title={"Establecimiento:"}
        options={EstbParsed}
      />
    );
  };

  const PuntoEmisionSelector = () => {
    return (
      <SelectForm
        name={"puntoEmision"}
        title={"Punto de emisión:"}
        options={PtoEmParsed}
      />
    );
  };

  useEffect(() => {
    getEstablecimientos();
  }, [empresa]);

  return {
    EstablecimientoSelector,
    PuntoEmisionSelector,
  };
};

export default useSecuencialLoader;
