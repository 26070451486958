//import './App.css';

import "./css/main.css";
import axios from "axios";
import { Routes, Route } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
// Default theme
import "@splidejs/react-splide/css";

// or other themes
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";

// or only core styles
import "@splidejs/react-splide/css/core";
import Login from "./components/Login";
import Registro from "./components/Registro";
import Reset from "./components/Reset";
import ResetNewPass from "./components/ResetNewPass";

import AsideBar from "./features/AsideBar";
import NavBar from "./features/NavBar";
import Dashboard from "./components/Dashboard";
import DashboardSke from "./components/Dashboard/DashbSkeleton";
import { loadUser } from "./redux/app.slice";
import {
  cargarEmpresas,
  seleccionarEmpresa,
} from "./features/NavBar/navBar.slice";
import { getUser, getList } from "./services";
import packageJson from "../package.json";

//import EmitirFactura from "./components/Mantenimiento/InfoEmpresa";
import EmitirLiquidacion from "./components/EmisionElectronico/EmitirLiquidacion";
import moment from "moment";
import "moment/locale/es";
import GlobalToastifyProvider from "./features/hooks/GlobalToastify/GlobalToastifyProvider";
import PuntoVentaProvider from "./components/Mantenimiento/InfoEmpresa/PuntoVentaProvider";

import { CentroAyudaModal } from "./features/AsideBar/CentroAyudaModal";

import { hasAuthParams, useAuth } from "react-oidc-context";
import Callback from "./oidc/Callback";
import { SubRoutes } from "./components/constants/routes.d";

moment.locale("es");
axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL.replaceAll(
  "http://",
  "https://"
);

function App() {
  const [MenuMobil, setMenuMobil] = useState(false);
  const [IsLoading, setIsLoading] = useState(true);
  const [OpenMenu, setOpenMenu] = useState(true);
  const auth = useAuth();

  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.isLoading) {
      auth.signinRedirect();
    }

    if (auth.isAuthenticated) {
      load();
      setIsLoading(false);
    }
  }, [auth.isAuthenticated, auth.isLoading]);

  // const CacheCleaner = () => {
  //   let version = localStorage.getItem("version");
  //   if (version !== packageJson.version) {
  //     if ("caches" in window) {
  //       caches.keys().then((names) => {
  //         // Delete all the cache files
  //         names.forEach((name) => {
  //           caches.delete(name);
  //         });
  //       });
  //       // Makes sure the page reloads. Changes are only visible after you refresh.
  //       window.location.reload(true);
  //     }
  //     const actualToken = localStorage.getItem("token");
  //     const actualEmpresa = localStorage.getItem("empresaSelected");
  //     localStorage.clear();
  //     localStorage.setItem("version", packageJson.version);
  //     if (actualToken) {
  //       localStorage.setItem("token", actualToken);
  //       if (actualEmpresa !== null)
  //         localStorage.setItem("empresaSelected", actualEmpresa);
  //     }
  //   }
  // };
  const handleOnclickMenuMobil = () => {
    setMenuMobil(!MenuMobil);
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const GetUserV2 = async () => {
    try {
      const { data } = await axios.get(
        `${axios.defaults.baseURL}api/cuentas/usuario`
      );
      return data;
    } catch (error) {
      cerrarSesion();
    }
  };

  const cerrarSesion = async () => {
    const logoutUrl = `${auth.settings.authority}connect/endsession?id_token_hint=${auth.user?.id_token}&post_logout_redirect_uri=${auth.settings.post_logout_redirect_uri}`;
    sessionStorage.clear();
    localStorage.clear();
    await new Promise((resolve) => {
      window.location.href = logoutUrl;
      resolve();
    });
    navigate("/");
  };

  const loginIn = useSelector((store) => store.app.loginIn);

  const load = async () => {
    var token = localStorage.getItem("token_facturador");
    // console.log("------------------");
    if (window.location.pathname === "/resetnewpass") {
      navigate("/resetnewpass");
    } else if (token == null) {
      auth.signinRedirect();
    } else {
      axios.defaults.headers.Authorization = `Bearer ${token}`;

      var data = await GetUserV2();

      dispatch(loadUser(data._embedded));

      (async () => {
        const oldSelected = window.localStorage.getItem("empresaSelected");

        const totalItems = await getList(
          `api/Empresas/GetEmpresasByUserSearch`
        );

        if (
          !(
            oldSelected === null ||
            oldSelected === undefined ||
            oldSelected === "undefined" ||
            oldSelected === "null"
          )
        ) {
          const { data } = await axios.get("api/Empresas/" + oldSelected);

          const itemsToAdd = totalItems._embedded.filter(
            (el) => el.idEmpresa !== oldSelected
          );

          dispatch(cargarEmpresas([data._embedded, ...itemsToAdd]));
          dispatch(seleccionarEmpresa(oldSelected));
        } else {
          dispatch(cargarEmpresas([...totalItems._embedded]));
          dispatch(seleccionarEmpresa(totalItems._embedded[0].idEmpresa));
        }
      })();
    }
  };

  const [openMobil, setOpenMobil] = useState(false);
  const [openItem, setOpenItem] = useState(null);
  const [subMenu, setSubMenu] = useState(null);

  const toggleItem = (item) => {
    setOpenItem(openItem === item ? null : item);
    setSubMenu(null);
  };

  return (
    <div className="flex min-h-screen ">
      <PuntoVentaProvider>
        <CentroAyudaModal />

        <GlobalToastifyProvider>
          {/*<Navbar firebaseUser={firebaseUser} />*/}
          {loginIn ? (
            <AsideBar
              openItem={openItem}
              setOpenItem={setOpenItem}
              toggleItem={toggleItem}
              openMobil={openMobil}
              setOpenMobil={setOpenMobil}
              MenuMobil={MenuMobil}
              handleOnclickMenuMobil={handleOnclickMenuMobil}
              OpenMenu={OpenMenu}
              setOpenMenu={setOpenMenu}
              subMenu={subMenu}
              setSubMenu={setSubMenu}
            />
          ) : null}
          <div className="flex flex-col flex-1 overflow-auto ">
            {loginIn ? (
              <NavBar
                handleOnclickMenuMobil={handleOnclickMenuMobil}
                openMobil={openMobil}
                setOpenMobil={setOpenMobil}
                OpenMenu={OpenMenu}
                setOpenMenu={setOpenMenu}
              />
            ) : null}
            <main className="h-full lg:p-2 md:p-2 p-1 dark:bg-gray-800">
              <Routes>
                <Route
                  path="/"
                  element={
                    IsLoading ? (
                      <DashboardSke />
                    ) : (
                      <Dashboard toggleItem={toggleItem} />
                    )
                  }
                />
                <Route path="/callback" element={<Callback />} />

                {SubRoutes.map((data, i) => (
                  <Route
                    key={i}
                    exact
                    path={data.path}
                    element={
                      <ProtectedRoute userIsLogged={loginIn}>
                        {" "}
                        <data.Component />{" "}
                      </ProtectedRoute>
                    }
                  />
                ))}

                <Route exact path="/login" element={<Login />} />
                <Route
                  exact
                  path="/emision/generar/liquidacion"
                  element={
                    <ProtectedRoute userIsLogged={loginIn}>
                      {" "}
                      <EmitirLiquidacion />{" "}
                    </ProtectedRoute>
                  }
                />
                <Route path="/registro" element={<Registro />} />
                <Route path="/reset" element={<Reset />} />
                <Route path="/resetnewpass" element={<ResetNewPass />} />
              </Routes>
            </main>
          </div>
        </GlobalToastifyProvider>
      </PuntoVentaProvider>
    </div>
  );
}

// https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/
const ProtectedRoute = ({ children, userIsLogged }) => {
  if (!userIsLogged) {
    // user is not authenticated
    return <Navigate to="/login" />;
    //return children;
  }
  return <div className="  max-w-full max-h-full">{children}</div>;
};

export default App;
