import { useContext } from "react";
import { globalToastifyContext } from "./GlobalToastifyProvider";

const useGlobalToastify = () => {

  const { Toast } = useContext(globalToastifyContext);

  let ToastId = null;

  const WarningToast = (content) => {
    if(ToastId) {
      Toast.update(ToastId, {
        render: content,
        type: "warning",
        isLoading: false,
        autoClose: 2000,
      });
    } else {
      Toast.warning(content);
    }
  }

  const SuccessToast = (content) => {
    Toast.update(ToastId, {
      render: content,
      type: "success",
      isLoading: false,
      autoClose: 2000,
    });
  }

  const ErrorToast = (content) => {
    if(ToastId) {
      Toast.update(ToastId, {
        render: content,
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    } else {
      Toast.error(content);
    }
  }

  const LoadingToast = (content = "Cargando...") => {
    const ItemId = Toast.loading(content);
   // console.log(ItemId);
    ToastId = ItemId;
  }

  const InformativeToast = (content) => {
    Toast.info(content);
  }


  return {
    InformativeToast,
    LoadingToast,
    ErrorToast,
    SuccessToast,
    WarningToast
  }
}

export default useGlobalToastify;