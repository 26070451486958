import React, { useContext, useState } from "react";
import NumberFormat from "react-number-format";
import { DiseñoPago } from "../../../features/Constantes";
import { EmitirLiquidacionContext } from "../EmitirLiquidacion/EmitirLiquidacionProvider";
import { Transition } from "@headlessui/react";

const TotalDocumento = () => {
  const { Totales } = useContext(EmitirLiquidacionContext);
  const [isOpen,setIsOpen] = useState(true);
  //style={{ width: "320px" }}

  return (
    <>
      <div className="text-sm py-1.5   px-2 text-center mx-auto  bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-t-lg font-medium leading-6 text-white flex  lg:justify-center md:justify-center justify-between">
        <div></div>
        <h3>Totales</h3>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          onClick={() => setIsOpen(!isOpen)}
          stroke="currentColor"
          className={`w-4 h-4 my-auto   lg:hidden md:hidden text-white duration-150 ${isOpen ? 'rotate-180' : ''}`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m19.5 8.25-7.5 7.5-7.5-7.5"
          />
        </svg>
      </div>
      <Transition
        show={isOpen}
        enter="transition ease-out duration-300" // Aumenta la duración de entrada
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-300" // Aumenta la duración de salida
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >

<div className="flex align-middle justify-center flex-col ">
      {Totales.subTotalIva !== 0 && (
          <div className="flex flex-center justify-center  mb-2 pt-2 ">
            <div className="text-gray-800 select-none max-w-xs text-right pr-2     rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
              SUBTOTAL IVA 12 %
            </div>

            <div className={DiseñoPago + " w-56 select-none"}>
              <div
                className="text-gray-800 font-medium text-right pr-10  dark:text-white"
                x-html="netTotal"
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={Totales.subTotalIva}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </div>
            </div>
          </div>
        )}

{Totales.subTotalIva0 > 0 && (
          <div className="flex flex-center justify-center  mb-2 pt-2 ">
            <div className="text-gray-800 select-none max-w-xs text-right pr-2     rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
              SUBTOTAL IVA 0 %
            </div>

            <div className={DiseñoPago + " w-56 select-none"}>
              <div
                className="text-gray-800 font-medium text-right pr-10  dark:text-white"
                x-html="netTotal"
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={Totales.subTotalIva0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </div>
            </div>
          </div>
        )}
        {Totales.subTotalIva15 !== 0 && (
          <div className="flex flex-center justify-center  mb-2 pt-2 ">
            <div className="text-gray-800 select-none max-w-xs text-right pr-2     rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
              SUBTOTAL IVA 15 %
            </div>

            <div className={DiseñoPago + " w-56 select-none"}>
              <div
                className="text-gray-800 font-medium text-right pr-10  dark:text-white"
                x-html="netTotal"
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={Totales.subTotalIva15}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </div>
            </div>
          </div>
        )}

        {Totales.subTotalIva5 !== 0 && (
          <div className="flex flex-center justify-center  mb-2  ">
            <div className="text-gray-800 select-none max-w-xs text-right pr-2     rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
              SUBTOTAL IVA 5 %
            </div>

            <div className={DiseñoPago + " w-56 select-none"}>
              <div
                className="text-gray-800 font-medium text-right pr-10  dark:text-white"
                x-html="netTotal"
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={Totales.subTotalIva5}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </div>
            </div>
          </div>
        )}

        {Totales.subTotalIva8 !== 0 && (
          <div className="flex flex-center justify-center  mb-2  ">
            <div className="text-gray-800 select-none max-w-xs text-right pr-2     rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
              SUBTOTAL IVA 8 %
            </div>

            <div className={DiseñoPago + " w-56 select-none"}>
              <div
                className="text-gray-800 font-medium text-right pr-10  dark:text-white"
                x-html="netTotal"
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={Totales.subTotalIva8}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </div>
            </div>
          </div>
        )}

        {Totales.subTotalIva13 !== 0 && (
          <div className="flex flex-center justify-center  mb-2  ">
            <div className="text-gray-800 select-none max-w-xs text-right pr-2     rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
              SUBTOTAL IVA 13 %
            </div>

            <div className={DiseñoPago + " w-56 select-none"}>
              <div
                className="text-gray-800 font-medium text-right pr-10  dark:text-white"
                x-html="netTotal"
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={Totales.subTotalIva13}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </div>
            </div>
          </div>
        )}
        {Totales.subTotalNoObjetoIva !== 0 ? (
          <div className="flex flex-center justify-center mb-0">
            <div className="text-gray-800 select-none  max-w-xs text-right pr-2   dark:text-white flex-1 text-sm font-semibold">
              SUBTOTAL NO OBJETO IVA
            </div>
            <div className={DiseñoPago + ' w-56 select-none'}>
              <div
                className="text-gray-800 font-medium text-right pr-10 dark:text-white"
                x-html="netTotal"
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={Totales.subTotalNoObjetoIva}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </div>
            </div>
          </div>
        ) : null}
        {Totales.subTotalExentoIva !== 0 ? (
          <div className="flex flex-center justify-center mb-0">
            <div className="text-gray-800 select-none max-w-xs text-right pr-2   dark:text-white flex-1 text-sm font-semibold">
              SUBTOTAL EXENTO IVA
            </div>
            <div className={DiseñoPago + ' w-56 select-none'}>
              <div
                className="text-gray-800 font-medium text-right pr-10 dark:text-white"
                x-html="netTotal"
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={Totales.subTotalExentoIva}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className="flex flex-center justify-center mb-2 ">
          <div className="text-gray-800 select-none text-[0.813rem] text-right pr-2 dark:text-white    rounded-l-lg px-1 py-2  max-w-xs flex-1 text-sm font-semibold">
            TOTAL SIN IMPUESTO
          </div>
          <div className={DiseñoPago + ' w-56 select-none'}>
            <div
              className="text-gray-800 font-medium text-right pr-10 dark:text-white"
              x-html="netTotal"
            >
              <NumberFormat
                fixedDecimalScale
                decimalScale={2}
                value={parseFloat(Totales.totalSinImpuesto)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$ "}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-center justify-center mb-2">
          <div className="text-gray-800 select-none  text-[0.813rem] text-right pr-2  flex-1 max-w-xs    rounded-l-lg px-1 py-2 dark:text-white text-sm font-semibold">
            TOTAL DESCUENTOS
          </div>
          <div className={DiseñoPago + ' w-56 select-none'}>
            <div
              className="text-gray-900 font-medium text-right pr-10 dark:text-white"
              x-html="netTotal"
            >
              <NumberFormat
                fixedDecimalScale
                decimalScale={2}
                value={Totales.totalDescuentos}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$ "}
              />
            </div>
          </div>
        </div>
        {Totales.totalIce !== 0 ? (
          <div className="flex flex-center justify-center mb-0">
            <div className="text-sm text-gray-600 select-none text-[0.813rem] text-right pr-2  max-w-xs bg-gray-100 dark:text-white flex-1 dark:bg-gray-700 dark:border-gray-600  ">
              ICE
            </div>
            <div className={DiseñoPago + ' w-56 select-none'}>
              <div
                className="text-sm text-gray-600 text-right pr-10 dark:text-white"
                x-html="totalGST"
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={Totales.totalIce}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </div>
            </div>
          </div>
        ) : null}
    {Totales.totalIva !== 0 && (
          <div className="flex flex-center justify-center mb-2">
            <div className="text-gray-800 select-none max-w-xs text-right pr-2     rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
              IVA 12 %
            </div>
            <div className={DiseñoPago + " w-56 select-none"}>
              <div
                className="text-gray-800 font-medium text-right pr-10 dark:text-white"
                x-html="totalGST"
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={Totales.totalIva}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </div>
            </div>
          </div>
        )}
        {Totales.totalIva15 !== 0 && (
          <div className="flex flex-center justify-center mb-2">
            <div className="text-gray-800 select-none max-w-xs text-right pr-2     rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
              IVA 15 %
            </div>
            <div className={DiseñoPago + " w-56 select-none"}>
              <div
                className="text-gray-800 font-medium text-right pr-10 dark:text-white"
                x-html="totalGST"
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={Totales.totalIva15}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </div>
            </div>
          </div>
        )}

        {Totales.totalIva5 !== 0 && (
          <div className="flex flex-center justify-center mb-2">
            <div className="text-gray-800 select-none max-w-xs text-right pr-2     rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
              IVA 5 %
            </div>
            <div className={DiseñoPago + " w-56 select-none"}>
              <div
                className="text-gray-800 font-medium text-right pr-10 dark:text-white"
                x-html="totalGST"
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={Totales.totalIva5}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </div>
            </div>
          </div>
        )}

        {Totales.totalIva13 !== 0 && (
          <div className="flex flex-center justify-center mb-2">
            <div className="text-gray-800 select-none max-w-xs text-right pr-2     rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
              IVA 13 %
            </div>
            <div className={DiseñoPago + " w-56 select-none"}>
              <div
                className="text-gray-800 font-medium text-right pr-10 dark:text-white"
                x-html="totalGST"
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={Totales.totalIva13}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </div>
            </div>
          </div>
        )}

        {Totales.totalIva8 !== 0 && (
          <div className="flex flex-center justify-center mb-2">
            <div className="text-gray-800 select-none max-w-xs text-right pr-2     rounded-l-lg px-1 py-2 flex-1 dark:text-white text-sm font-semibold">
              IVA 8 %
            </div>
            <div className={DiseñoPago + " w-56 select-none"}>
              <div
                className="text-gray-800 font-medium text-right pr-10 dark:text-white"
                x-html="totalGST"
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={Totales.totalIva8}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </div>
            </div>
          </div>
        )}
        {Totales.totalIRBPNR !== 0 ? (
          <div className="flex flex-center justify-center mb-0">
            <div className="text-sm text-gray-600 select-none text-[0.813rem] text-right pr-2 max-w-xs dark:text-white flex-1 bg-gray-100 dark:bg-gray-700 dark:border-gray-600 ">
              IRBPNR
            </div>
            <div className={DiseñoPago + ' w-56 select-none'}>
              <div
                className="text-sm text-gray-600 text-right pr-10 dark:text-white"
                x-html="totalGST"
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={Totales.totalIRBPNR}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </div>
            </div>
          </div>
        ) : null}
        {Totales.propina !== 0 ? (
          <div className="flex flex-center justify-center mb-0">
            <div className="text-sm text-gray-600 select-none text-[0.813rem] dark:text-white flex-1 bg-gray-100 dark:bg-gray-700 dark:border-gray-600 ">
              PROPINA
            </div>
            <div className={DiseñoPago + ' w-56 select-none'}>
              <div
                className="text-sm text-gray-600 text-right pr-10 dark:text-white"
                x-html="totalGST"
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={Totales.propina}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </div>
            </div>
          </div>
        ) : null}

        <div className="flex flex-center justify-center mb-2">
          <div className="text-gray-800 select-none text-right pr-2 text-[0.813rem]  dark:text-white max-w-xs  dark:bg-gray-700 dark:border-gray-600  rounded-l-lg px-1 py-2  flex-1 text-sm font-bold">
            VALOR TOTAL
          </div>
          <div className={DiseñoPago + ' w-56 select-none'}>
            <div
              className="text-gray-800 font-bold text-right pr-10 dark:text-white "
              x-html="totalGST"
            >
              <NumberFormat
                fixedDecimalScale
                decimalScale={2}
                value={Totales.importeTotal}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$ "}
              />
            </div>
          </div>
        </div>
      </div>
      
      
      </Transition>
    </>
  );
};

export default TotalDocumento;
