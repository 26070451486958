import React from "react";
import { SmallModalInfo } from "../components/SmallModalInfo";

const TutorialesModal = () => {
  return (
    <SmallModalInfo buttonModal={"tutorialesModal"} title="Tutoriales">
      <div className="text-center border-b border-t">
        <h1 className="text-2xl my-5">¿Cómo emitir una factura?</h1>
        <div className="flex justify-around my-5">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://youtu.be/II75lEAUNNc"
            className="flex justify-around flex-col align-middle"
          >
            <svg
              className="mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              height="50"
              width="100"
              viewBox="-35.20005 -41.33325 305.0671 247.9995"
            >
              <path
                d="M229.763 25.817c-2.699-10.162-10.65-18.165-20.748-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.553 7.652 7.6 15.655 4.903 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.903 56.85C7.6 149.68 15.553 157.681 25.65 160.4c18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.682-4.934c10.098-2.718 18.049-10.72 20.748-20.882 4.904-18.421 4.904-56.85 4.904-56.85s0-38.431-4.904-56.85"
                fill="red"
              />
              <path
                d="M93.333 117.559l61.333-34.89-61.333-34.894z"
                fill="#fff"
              />
            </svg>
            <span className="mt-2">Ver en youtube</span>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://drive.google.com/drive/folders/1Arn1DO9VK8x9Y6b1Vr6or5tU68s8smgm?usp=share_link"
            className="flex justify-around flex-col align-middle"
          >
            <svg
              className="mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              height="50"
              width="100"
              viewBox="0 0 87.3 78"
            >
              <path
                d="m6.6 66.85 3.85 6.65c.8 1.4 1.95 2.5 3.3 3.3l13.75-23.8h-27.5c0 1.55.4 3.1 1.2 4.5z"
                fill="#0066da"
              />
              <path
                d="m43.65 25-13.75-23.8c-1.35.8-2.5 1.9-3.3 3.3l-25.4 44a9.06 9.06 0 0 0 -1.2 4.5h27.5z"
                fill="#00ac47"
              />
              <path
                d="m73.55 76.8c1.35-.8 2.5-1.9 3.3-3.3l1.6-2.75 7.65-13.25c.8-1.4 1.2-2.95 1.2-4.5h-27.502l5.852 11.5z"
                fill="#ea4335"
              />
              <path
                d="m43.65 25 13.75-23.8c-1.35-.8-2.9-1.2-4.5-1.2h-18.5c-1.6 0-3.15.45-4.5 1.2z"
                fill="#00832d"
              />
              <path
                d="m59.8 53h-32.3l-13.75 23.8c1.35.8 2.9 1.2 4.5 1.2h50.8c1.6 0 3.15-.45 4.5-1.2z"
                fill="#2684fc"
              />
              <path
                d="m73.4 26.5-12.7-22c-.8-1.4-1.95-2.5-3.3-3.3l-13.75 23.8 16.15 28h27.45c0-1.55-.4-3.1-1.2-4.5z"
                fill="#ffba00"
              />
            </svg>
            <span className="mt-2">Ver en google drive</span>
          </a>
        </div>
      </div>
      <div className="text-center border-b">
        <h1 className="text-2xl my-5">¿Cómo emitir una retención?</h1>
        <div className="flex justify-around my-5">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/watch?v=9qpapI01XbQ"
            className="flex justify-around flex-col align-middle"
          >
            <svg
              className="mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              height="50"
              width="100"
              viewBox="-35.20005 -41.33325 305.0671 247.9995"
            >
              <path
                d="M229.763 25.817c-2.699-10.162-10.65-18.165-20.748-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.553 7.652 7.6 15.655 4.903 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.903 56.85C7.6 149.68 15.553 157.681 25.65 160.4c18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.682-4.934c10.098-2.718 18.049-10.72 20.748-20.882 4.904-18.421 4.904-56.85 4.904-56.85s0-38.431-4.904-56.85"
                fill="red"
              />
              <path
                d="M93.333 117.559l61.333-34.89-61.333-34.894z"
                fill="#fff"
              />
            </svg>
            <span className="mt-2">Ver en youtube</span>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://drive.google.com/drive/folders/1FLJvfS-3oUOPDgxW2uqqNb1L0bMtxc7I"
            className="flex justify-around flex-col align-middle"
          >
            <svg
              className="mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              height="50"
              width="100"
              viewBox="0 0 87.3 78"
            >
              <path
                d="m6.6 66.85 3.85 6.65c.8 1.4 1.95 2.5 3.3 3.3l13.75-23.8h-27.5c0 1.55.4 3.1 1.2 4.5z"
                fill="#0066da"
              />
              <path
                d="m43.65 25-13.75-23.8c-1.35.8-2.5 1.9-3.3 3.3l-25.4 44a9.06 9.06 0 0 0 -1.2 4.5h27.5z"
                fill="#00ac47"
              />
              <path
                d="m73.55 76.8c1.35-.8 2.5-1.9 3.3-3.3l1.6-2.75 7.65-13.25c.8-1.4 1.2-2.95 1.2-4.5h-27.502l5.852 11.5z"
                fill="#ea4335"
              />
              <path
                d="m43.65 25 13.75-23.8c-1.35-.8-2.9-1.2-4.5-1.2h-18.5c-1.6 0-3.15.45-4.5 1.2z"
                fill="#00832d"
              />
              <path
                d="m59.8 53h-32.3l-13.75 23.8c1.35.8 2.9 1.2 4.5 1.2h50.8c1.6 0 3.15-.45 4.5-1.2z"
                fill="#2684fc"
              />
              <path
                d="m73.4 26.5-12.7-22c-.8-1.4-1.95-2.5-3.3-3.3l-13.75 23.8 16.15 28h27.45c0-1.55-.4-3.1-1.2-4.5z"
                fill="#ffba00"
              />
            </svg>
            <span className="mt-2">Ver en google drive</span>
          </a>
        </div>
      </div>
      <div className="text-center border-b">
        <h1 className="text-2xl my-5">¿Cómo emitir una nota de crédito?</h1>
        <div className="flex justify-around my-5">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://youtu.be/Yy5CYhMau-s"
            className="flex justify-around flex-col align-middle"
          >
            <svg
              className="mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              height="50"
              width="100"
              viewBox="-35.20005 -41.33325 305.0671 247.9995"
            >
              <path
                d="M229.763 25.817c-2.699-10.162-10.65-18.165-20.748-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.553 7.652 7.6 15.655 4.903 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.903 56.85C7.6 149.68 15.553 157.681 25.65 160.4c18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.682-4.934c10.098-2.718 18.049-10.72 20.748-20.882 4.904-18.421 4.904-56.85 4.904-56.85s0-38.431-4.904-56.85"
                fill="red"
              />
              <path
                d="M93.333 117.559l61.333-34.89-61.333-34.894z"
                fill="#fff"
              />
            </svg>
            <span className="mt-2">Ver en youtube</span>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://drive.google.com/drive/folders/1DcWupmr2r_5F6dGKo0GwtqNGJj4YKttS"
            className="flex justify-around flex-col align-middle"
          >
            <svg
              className="mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              height="50"
              width="100"
              viewBox="0 0 87.3 78"
            >
              <path
                d="m6.6 66.85 3.85 6.65c.8 1.4 1.95 2.5 3.3 3.3l13.75-23.8h-27.5c0 1.55.4 3.1 1.2 4.5z"
                fill="#0066da"
              />
              <path
                d="m43.65 25-13.75-23.8c-1.35.8-2.5 1.9-3.3 3.3l-25.4 44a9.06 9.06 0 0 0 -1.2 4.5h27.5z"
                fill="#00ac47"
              />
              <path
                d="m73.55 76.8c1.35-.8 2.5-1.9 3.3-3.3l1.6-2.75 7.65-13.25c.8-1.4 1.2-2.95 1.2-4.5h-27.502l5.852 11.5z"
                fill="#ea4335"
              />
              <path
                d="m43.65 25 13.75-23.8c-1.35-.8-2.9-1.2-4.5-1.2h-18.5c-1.6 0-3.15.45-4.5 1.2z"
                fill="#00832d"
              />
              <path
                d="m59.8 53h-32.3l-13.75 23.8c1.35.8 2.9 1.2 4.5 1.2h50.8c1.6 0 3.15-.45 4.5-1.2z"
                fill="#2684fc"
              />
              <path
                d="m73.4 26.5-12.7-22c-.8-1.4-1.95-2.5-3.3-3.3l-13.75 23.8 16.15 28h27.45c0-1.55-.4-3.1-1.2-4.5z"
                fill="#ffba00"
              />
            </svg>
            <span className="mt-2">Ver en google drive</span>
          </a>
        </div>
      </div>
    </SmallModalInfo>
  );
};

export default TutorialesModal;
