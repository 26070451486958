import axios from "axios";
import { getList } from "../../../../services";

export const getSecuencialesByPuntoEmision = async (idPT) => {
  try {
    let request = await getList(`api/secuencial/${idPT}`);
    let objectParsed = {};
    request._embedded.forEach((elTwo) => {
      objectParsed[
        elTwo.idTipoDocumentoNavigation.documento.replaceAll(" ", "")
      ] = {
        Codigo: elTwo.codigo,
        idSecuencial: elTwo.idSecuencial,
      };
    });
    return objectParsed;
  } catch (ex) {
    console.error(ex);
  }
};

export const submitSecuencialesByPuntoEmision = async (obj) => {
    let request = await axios.put(`api/secuencial`, obj);
    return request;
}