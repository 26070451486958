import { useContext } from "react";
import { FacturaExportacionContext } from "./FacturaExportacionProvider";
import ActiveFacturaExportacionButton from "./ActiveFacturaExportacionButton";

const useFacturaExportacion = () => {
  const {
    IsActiveExportacion,
    setIsActiveExportacion,
    ExportacionInfo,
    setExportacionInfo,
  } = useContext(FacturaExportacionContext);

  return {
    IsActiveExportacion,
    setIsActiveExportacion,
    ExportacionInfo,
    setExportacionInfo,
    ActiveFacturaExportacionButton
  };
};

export default useFacturaExportacion;
