import { useContext } from "react";
import ModalComponent from "../../../../../features/components/ModalComponents/ModalComponent";
import { EgresoProviderContext } from "./Context/EgresoProvider";
export default function ModalEgreso() {
  const { setIsOpen, isOpen } = useContext(EgresoProviderContext);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ModalComponent dimensiones="mediano" isOpen={isOpen} onClose={toggleModal}>
        <div className="">
          <p className="uppercase">La transacción se genero exitosamente</p>
        </div>
      </ModalComponent>
    </>
  );
}
