import ModalComponent from "../components/ModalComponets/ModalComponent";

export const ModalHours = ({ isOpenModalHours, toggleModal }) => {
  return (
    <ModalComponent isOpen={isOpenModalHours} onClose={toggleModal}>
         <div>
        <div className="flex justify-center items-center space-x-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-10  text-yellow-400"
          >
            <path
              fillRule="evenodd"
              d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
              clipRule="evenodd"
            />
          </svg>

          <p className="font-semibold text-lg">Advertencia</p>
        </div>
        <div className="mt-3">
          <p>
            Tu comprobante tiene{" "}
            <strong> fecha de emisión mayor a 72 horas</strong> para su
            autorización, para evitar posible sanción por el SRI, se recomienda
            cambiar la fecha de emisión dentro del rango de 72 horas.
          </p>

          <p> Para más detalle consultar o revisar resolución SRI:</p>
          <p className="font-semibold pt-2">
             NAC-DGERCGC22-00000064 y
            
            <br></br> NACDGERCGC18-00000233
          </p>
        </div>
      </div>
    </ModalComponent>
  );
};
