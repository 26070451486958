import React, { useContext, useEffect } from "react";
import { roundToTwo } from "../../../services/Utilitario";
import { ReembolsoContext } from "./ReembolsoProvider";
import { TableStyle, TableStyleTBody } from "../../Constantes";

const ReembolsosTable = () => {
  const {
    ActiveReembolso,
    ReembolsoList,
    setReembolsoList,
    setEditReembolso,
    setIsEditReembolso,
    setContador,
    contador,
  } = useContext(ReembolsoContext);
  const handleDeleteElement = (index) => {
    ReembolsoList.splice(index, 1);
    setReembolsoList([...ReembolsoList]);
  };
  const HandleEditReembolso = (index) => {
    setEditReembolso(index);
    setIsEditReembolso(true);
    setContador(contador + 1);
  };

  useEffect(() => {
    console.log(ReembolsoList);
  }, [ReembolsoList]);

  if (!ActiveReembolso) return <></>;

  const ivaTypes = new Set();
  ReembolsoList.forEach((reembolso) => {
    reembolso.DataReembolso.forEach((item) => {
    
      ivaTypes.add(item.Impuesto.porcentaje);
    });
  });
  const ivaHeaders = Array.from(ivaTypes).sort((a, b) => a - b);
  console.log(ivaHeaders);
  return (
    <div className="border rounded-lg mb-2 border-gray-300 dark:border-gray-600 w-full">
      <h3 className="flex text-sm py-1.5 px-2 text-left bg-[#003B5B]  dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-t-lg font-medium leading-6 text-white">
        Reembolso
      </h3>
      <div className="p-2">
        <div>
          <button
            type="button"
            className="inline-block bg-blue-600 dark:bg-gray-900 dark:border-b-gray-600 text-white my-2 hover:bg-blue-700 font-semibold py-2 px-4 text-sm border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:shadow-outline-grayduration-150 ease-in-out"
            data-bs-toggle="modal"
            data-bs-target="#addReembolsoFacturaModal"
            onClick={(e) => {
              setIsEditReembolso(false);
              setEditReembolso(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="inline mr-2 -ml-1 w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            AÑADIR
          </button>
        </div>
        {ReembolsoList.length === 0 ? (
          <></>
        ) : (
          <>
            {" "}
            <div className=" my-2 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg dark:bg-gray-700 dark:border-gray-600  border ">
              <table className={TableStyle}>
                <thead>
                  <tr>
                    <th className="py-2"> Razon social proveedor</th>
                    <th>Numero comprobante</th>
                    <th> Fecha comprobante</th>

                    {ivaHeaders.map((iva) => (
                      <th key={iva}>{`IVA ${iva * 100}%`}</th>
                    ))}
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody className={TableStyleTBody}>
                  {ReembolsoList.map((reembolso, index) => {
                    const ivaValues = {};

                    reembolso.DataReembolso.forEach((item) => {
                      ivaValues[item.Impuesto.porcentaje] = roundToTwo(
                        item.SubTotalReembolso
                      );
                    });

                    let Total = reembolso.DataReembolso.reduce(
                      (acc, item) =>
                        acc +
                        (Number(item.SubTotalReembolso) +
                          Number(item.TotalIvaReembolso)),
                      0
                    );

                    return (
                      <tr key={index}>
                        <td className="py-2">
                          {reembolso.RazonSocialProveedor}
                        </td>
                        <td>{reembolso.NumeroComprobante}</td>

                        <td>{reembolso.FechaEmisionComprobante}</td>

                        {ivaHeaders.map((iva) => (
                          <td key={iva}>{ivaValues[iva] || ""}</td>
                        ))}
                        <td>{roundToTwo(Total)}</td>
                        <td>
                          <button
                            type="button"
                            onClick={() => HandleEditReembolso(index)}
                            className="text-gray-400 hover:text-gray-600 text-xs mr-2 focus:outline-none focus:shadow-outline-grayduration-150
                     ease-in-out"
                            data-bs-toggle="modal"
                            data-bs-target="#addReembolsoFacturaModal"
                            style={{ verticalAlign: "top" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              />
                            </svg>
                          </button>
                          <button
                            type="button"
                            onClick={() => handleDeleteElement(index)}
                            className="text-red-500 hover:text-red-600 text-xs "
                            style={{ verticalAlign: "top" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ReembolsosTable;