import React, { useState } from "react";

import FormDoc from "./FormDoc";

import EmitirDocProvider, { EmitirDocContext } from "./EmitirDocProvider";
import ListaDetalle from "./ListaDetalle";
import ModalAddPago from "./ModalAddPago";
import ModalGuardar from "./ModalGuardar";
import InformacionAdicional from "../../../features/components/InformacionAdicional";
import ModalAddImpuestoProvider from "./ModalAdd/ModalAddImpuestoProvider";
import ModalAdd from "./ModalAdd";
import MobileSubmitButton from "./MobileSubmitButton";
import ButtonDown from "./ButtonDown";
import { Transition } from "@headlessui/react";
const EmitirRetencion = () => {
  const ClassAvite =
    "text-sm py-1.5 w-full cursor-pointer  p-2 px-2 text-center bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-t-lg font-medium leading-6 text-white";
  const [isOpen,setIsOpen] = useState(true);
  return (
    <EmitirDocProvider>
      <div className="p-4">
        <div className="  dark:bg-gray-900 sm:rounded-lg ">
          <FormDoc />
       
          <div className="grid md:grid-cols-2  bg-white dark:bg-gray-900 lg:py-4  lg:px-4  md:gap-6">
            <div className="relative z-0 border dark:border-gray-600 rounded-lg  w-full group">
              <div className="flex space-x-0.5 w-full">
                <h3 className={ClassAvite + " flex justify-between"}>
                  Información adicional (Opcional)
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    onClick={()=>setIsOpen(!isOpen)}
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`w-4 h-4 my-auto duration-150 ${isOpen ? "rotate-180" : <></>} text-white lg:hidden md:hidden`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </h3>
              </div>
              <Transition
        show={isOpen}
        enter="transition ease-out duration-300" // Aumenta la duración de entrada
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-300" // Aumenta la duración de salida
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
              <div className="p-4">
                <InformacionAdicional Contexto={EmitirDocContext} />
              </div></Transition>
            </div>
          </div>
          <ButtonDown />
          {/* <MobileSubmitButton /> */}
        </div>
      </div>
      <ModalAddImpuestoProvider>
        <ModalAdd />
      </ModalAddImpuestoProvider>
      {/* <ModalAddReembolso /> */}
      <ModalAddPago />
      <ModalGuardar />
    </EmitirDocProvider>
  );
};

export default EmitirRetencion;
