
export const PlanFactura = [
    {
      text: "Envío de e-mail Automático de XML y PDF",
    },
    {
      text: "Reportes en Excel",
    },
    {
      text: "Factura y Reembolso / Nota de Crédito",
    },
    {
      text: "Visualización previa de RIDE o PDF",
    },
    {
        text: "Servidores en la nube",
      },
      {
        text: "Comprobante de Retención / Nota de Débito",
      },
      {
        text: "Soporte / Capacitación",
      },
     
      {
        text: "Guía de Remisión / Liquidación de Compras",
      },
     
     
  ];
  