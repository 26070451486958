import { useFormContext } from "react-hook-form"

export const SelectGlobal = ({ name,children, validations, disabled, onChange, value, title, isHook, isPlaceHolder }) => {

    const { register } = useFormContext()

    return (
        <label className="w-full text-gray-600 flex flex-col gap-1 " htmlFor={title}>{title}
            <select className="border-gray-300 focus:outline-none left-0 relative  focus:border-blue-500 focus:ring-blue-500 focus:ring-1 ring-0 w-full  border rounded-md h-9 pl-1 text-xs space-x-0" onChange={onChange} value={value}  {...register(name, validations)} disabled={disabled ? disabled : false} placeholder={isPlaceHolder} id={title}>
                 {children}
            </select>
        </label>
    )


}