import React, { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getListV2 } from '../../../services';

export const ReporteServicioContext = createContext();

const ReporteServicioProvider = (props) => {

    const empresa = useSelector(store => store.empresa.empresa);
    const [start, setStart] = useState(0);

    const initialStatePaginacion = {
        pagina: 1,
        _totalpaginas: 1,
        _totalelementos: 0,
        _links: [],
        _inicioRegistro:0,
        _totalEnPagina:0
    }
    const [Paginacion, setPaginacion] = useState(initialStatePaginacion);

    const [documentos, setDocumentos] = useState([])
    const [cargandoData, setCargandoData] = React.useState(false);
    const [DetalleItem, setDetalleItem] = useState(null);
    const [ActivarModalDetalle, setActivarModalDetalle] = useState(false);
    const [ActivarModalAdd, setActivarModalAdd] = useState(false);

    const formik = useFormik({
        initialValues: {
            codigoPrincipal: '',
            descripcion: '',
            estado: 'True',
            cantidad: 50,

            tipo:0
        },
        validationSchema: Yup.object({
            codigoPrincipal: Yup.string(),
            descripcion: Yup.string()

        }),
        onSubmit: async obj => {
            try {
                setCargandoData(true)

                await fetchData(obj.codigoPrincipal, obj.descripcion, obj.estado, obj.cantidad,obj.tipo);

                setCargandoData(false)
            } catch (error) {
              //  console.log(error)
            }

            setCargandoData(false)
        }
    });


    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = async (
        codigoPrincipal = '',
        descripcion = '',
        estado = 'True',
        cantidad = 50,
        tipo=0,
    ) => {
        try {
            const parametroCodificado = encodeURIComponent(codigoPrincipal);
            const res = await getListV2(
                empresa,
                'SearchServicios',
                `?codigoPrincipal=${parametroCodificado}&start=${start}&descripcion=${descripcion}&Estado=${estado}&cantidad=${cantidad}&tipo=${tipo}&Pagina=${Paginacion.pagina}&Cantidad=10`
            )

            if (!(res === undefined)) {
                setPaginacion({
                    ...Paginacion,
                    _totalpaginas: res._totalpaginas,
                    _totalelementos: res._totalelementos,
                    _links: res._links,
                    _inicioRegistro:res._inicioRegistro,
                    _totalEnPagina:res._totalEnPagina
                })
                setDocumentos(res._embedded)
            }
                
        } catch (error) {
            setPaginacion(initialStatePaginacion);
            setDocumentos([]);
            setCargandoData(false);
         
         //   console.log(error)
           
        }
    }

    return (
        <ReporteServicioContext.Provider value={{ start,setStart, ActivarModalDetalle, setActivarModalDetalle, documentos, cargandoData, formik, DetalleItem, setDetalleItem, setDocumentos, ActivarModalAdd, setActivarModalAdd, Paginacion, setPaginacion, initialStatePaginacion }}>
            {
                props.children
            }
        </ReporteServicioContext.Provider>
    )
}

export default ReporteServicioProvider;