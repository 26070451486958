import React, { useState } from "react";
import { SmallModalInfo } from "../components/SmallModalInfo";

export const PreguntasModal = () => {
    const [activeIndex, setActiveIndex] = useState(-1);

    const handleAccordionChange = (index) => {
        setActiveIndex(index === activeIndex ? -1 : index);
    };

    return (
        <SmallModalInfo buttonModal={"preguntasModal"} title="Preguntas frecuentes">
            <div data-te-perfect-scrollbar-init data-te-suppress-scroll-x="true" className="relative max-h-[60vh] overflow-y-auto">
                <div className="space-y-2 flex flex-col gap-1">
                    <div className={`p-2 border rounded-lg overflow-hidden transition duration-150 ease-out hover:ease-in shadow-md flex flex-col gap-1 ${activeIndex === 1 ? 'ease-in' : 'ease-out'}`}>
                        <button
                            onClick={() => handleAccordionChange(1)}
                            className="flex w-full items-center justify-between rounded-lg bg-gray-200 px-4 py-2 text-left text-sm font-medium hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75 p-2 border border-neutral-200 dark:border-neutral-600 dark:bg-neutral-800 cursor-pointer"
                        >
                            <span className="w-5/6">1{')'} ¿Por qué motivos no se autoriza mi comprobante?</span>
                            <svg xmlns="http://www.w3.org/2000/svg" className={` ${activeIndex === 1 ? 'rotate-180 transform' : ''} transition duration-100 icon icon-tabler icon-tabler-arrow-badge-down-filled`} width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 10l6 6l6 -6h-12" /></svg>
                        </button>
                        {activeIndex === 1 && (<div className="px-4 pb-2 pt-4 text-sm flex flex-col gap-1 ease-in-out text-justify" >
                            <p>Podrá visualizar el motivo exacto por el cual no se autoriza su comprobante en el apartado de <strong>“Consulta de documentos electrónicos”</strong> en el bloque de opciones, opción <strong>“trazabilidad”</strong>. Siendo más comunes los siguientes:</p><br />
                            <div className="px-4">
                                <ul className="list-decimal">
                                    <li>No tiene una autorización activa en el SRI. <strong>Solución:</strong> Debe realizar la solicitud en el portal del SRI.</li>
                                    <li>No tiene cargada una firma electrónica vigente en el Facturador. <strong>Solución:</strong> Comunicarse con su asesor para la renovación de firma, o subir la nueva firma electrónica en <strong>“Mantenimiento”</strong> {'>'} <strong>“Editar empresa”</strong> {'>'} <strong>“Cargar firma”</strong> {'>'} <strong>"Guardar"</strong>.</li>
                                    <li>La información ingresada en los campos requeridos de los comprobantes es incorrecta. <strong>Solución:</strong> Deberá realizar la emisión de otro comprobante.</li>
                                </ul>
                            </div>
                        </div>)}
                    </div>
                    <div className={`p-2 border rounded-lg overflow-hidden transition duration-150 ease-out hover:ease-in shadow-md flex flex-col gap-1 ${activeIndex === 0 ? 'ease-in' : 'ease-out'}`}>
                        <button
                            onClick={() => handleAccordionChange(0)}
                            className="flex w-full items-center justify-between rounded-lg bg-gray-200 px-4 py-2 text-left text-sm font-medium hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75 p-2 border border-neutral-200 dark:border-neutral-600 dark:bg-neutral-800 cursor-pointer"
                        >
                            <span className="w-5/6">2{')'} ¿Qué hago si mi comprobante se quedó en estado “procesando”?</span>
                            <svg xmlns="http://www.w3.org/2000/svg" className={` ${activeIndex === 0 ? 'rotate-180 transform' : ''} transition duration-100 icon icon-tabler icon-tabler-arrow-badge-down-filled`} width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 10l6 6l6 -6h-12" /></svg>
                        </button>
                        {activeIndex === 0 && (<div className="px-4 pb-2 pt-4 text-sm ease-in-out" >
                            <p className="text-justify">Cuando un comprobante se encuentra en estado de procesamiento, se recomienda dirigirse al apartado <strong>"Reporte de documentos emitidos"</strong>, en el bloque de opciones, dar clic en opción <strong>“Reenviar”</strong>, lo que permitirá recibir el nuevo estado que el SRI asigne al comprobante, sin que tenga que emitir nuevamente dicho comprobante.</p>
                        </div>)}
                    </div>
                    <div className={`p-2 border rounded-lg overflow-hidden transition duration-150 ease-out hover:ease-in shadow-md flex flex-col gap-1 ${activeIndex === 2 ? 'ease-in' : 'ease-out'}`}>
                        <button
                            onClick={() => handleAccordionChange(2)}
                            className="flex w-full items-center justify-between rounded-lg bg-gray-200 px-4 py-2 text-left text-sm font-medium hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75 p-2 border border-neutral-200 dark:border-neutral-600 dark:bg-neutral-800 cursor-pointer"
                        >
                            <span className="w-5/6">3{')'} Cuándo renuevo mi firma electrónica (por caducidad), ¿Se actualiza de manera automática en el sistema de facturación la nueva firma electrónica?</span>
                            <svg xmlns="http://www.w3.org/2000/svg" className={` ${activeIndex === 2 ? 'rotate-180 transform' : ''} transition duration-100 icon icon-tabler icon-tabler-arrow-badge-down-filled`} width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 10l6 6l6 -6h-12" /></svg>
                        </button>
                        {activeIndex === 2 && (<div className="px-4 pb-2 pt-4 text-sm flex flex-col gap-1 text-justify ease-in-out" >
                            <p>No, una vez renovada su firma electrónica debe descargar el archivo de firma en <a className="hover:text-blue-500" href="https://www.intuitosa.com" target="_blank" >https://www.intuitosa.com</a>, opción descargar firma; posteriormente subir la firma en el sistema de facturación <a className="hover:text-blue-500" href="https://facturasintuitosa.com" target="_blank">https://facturasintuitosa.com</a>. Seleccionar</p><br />
                            <p className="text-center">Mantenimiento {'>'} Editar empresa {'>'} Subir Firma</p><br />
                            <p>Debe ingresar la clave de PIN y validar la información en <strong>“información de la firma”</strong>, una vez validada proceder a <strong>guardar</strong> los cambios.</p><br />
                            <p>Los comprobantes que quedaron en estado no autorizado por la firma caducada podrá reprocesarlos, pero el sistema le cambiará la fecha de emisión con la fecha actual.</p>
                        </div>)}
                    </div>
                    <div className={`p-2 border rounded-lg overflow-hidden transition duration-150 ease-out hover:ease-in shadow-md flex flex-col gap-1 ${activeIndex === 3 ? 'ease-in' : 'ease-out'}`}>
                        <button
                            onClick={() => handleAccordionChange(3)}
                            className="flex w-full items-center justify-between rounded-lg bg-gray-200 px-4 py-2 text-left text-sm font-medium hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75 p-2 border border-neutral-200 dark:border-neutral-600 dark:bg-neutral-800 cursor-pointer"
                        >
                            <span className="w-5/6">4{')'} ¿Puedo repetir el mismo código principal al registrar un nuevo servicio/producto?</span>
                            <svg xmlns="http://www.w3.org/2000/svg" className={` ${activeIndex === 3 ? 'rotate-180 transform' : ''} transition duration-100 icon icon-tabler icon-tabler-arrow-badge-down-filled`} width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 10l6 6l6 -6h-12" /></svg>
                        </button>
                        {activeIndex === 3 && (<div className="px-4 pb-2 pt-4 text-sm text-justify flex flex-col gap-1 ease-in-out" >
                            <p>No, no es posible repetir el código principal al registrar un nuevo servicio o producto. Cada ítem debe tener un código único para evitar conflictos y mantener la integridad del sistema. Asignar códigos distintos contribuye a un funcionamiento eficiente y confiable.</p>
                        </div>)}
                    </div>
                    <div className={`p-2 border rounded-lg overflow-hidden transition duration-150 ease-out hover:ease-in shadow-md flex flex-col gap-1 ${activeIndex === 3 ? 'ease-in' : 'ease-out'}`}>
                        <button
                            onClick={() => handleAccordionChange(4)}
                            className="flex w-full items-center justify-between rounded-lg bg-gray-200 px-4 py-2 text-left text-sm font-medium hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75 p-2 border border-neutral-200 dark:border-neutral-600 dark:bg-neutral-800 cursor-pointer"
                        >
                            <span className="w-5/6">5{')'} ¿Debo anular un comprobante en estado <strong>“No autorizado”</strong>?</span>
                            <svg xmlns="http://www.w3.org/2000/svg" className={` ${activeIndex === 4 ? 'rotate-180 transform' : ''} transition duration-100 icon icon-tabler icon-tabler-arrow-badge-down-filled`} width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 10l6 6l6 -6h-12" /></svg>
                        </button>
                        {activeIndex === 4 && (<div className="px-4 pb-2 pt-4 text-sm flex flex-col gap-1 text-justify ease-in-out" >
                            <p>No, un comprobante no autorizado no tiene validez tributaria en el SRI</p>
                        </div>)}
                    </div>
                    <div className={`p-2 border rounded-lg overflow-hidden transition duration-150 ease-out hover:ease-in shadow-md flex flex-col gap-1 ${activeIndex === 3 ? 'ease-in' : 'ease-out'}`}>
                        <button
                            onClick={() => handleAccordionChange(5)}
                            className="flex w-full items-center justify-between rounded-lg bg-gray-200 px-4 py-2 text-left text-sm font-medium hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75 p-2 border border-neutral-200 dark:border-neutral-600 dark:bg-neutral-800 cursor-pointer"
                        >
                            <span className="w-5/6">6{')'} ¿Puedo anular una factura(comprobante) directamente en el sistema de Intuito?</span>
                            <svg xmlns="http://www.w3.org/2000/svg" className={` ${activeIndex === 5 ? 'rotate-180 transform' : ''} transition duration-100 icon icon-tabler icon-tabler-arrow-badge-down-filled`} width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 10l6 6l6 -6h-12" /></svg>
                        </button>
                        {activeIndex === 5 && (<div className="px-4 pb-2 pt-4 text-sm flex flex-col gap-1 text-justify ease-in-out" >
                            <p>Si, sin embargo, recuerde que toda anulación de comprobantes previamente se debe realizar en el sistema del SRI, una vez aprobada la anulación en dicha plataforma, puede anular la factura en el sistema de Intuito S.A.</p><br />
                            <p className="text-sm"><strong>Pasos:</strong> Para cambiar el estado de<strong> “Autorizado”</strong> a <strong>“Anulado”</strong> en portal dirigirse a <strong>“Reporte de documentos emitidos” </strong> ubicar el comprobante y en opciones seleccionar <strong>“Anular”</strong>.</p>
                        </div>)}
                    </div>
                </div>
            </div>
        </SmallModalInfo >
    )
}