import React, { useState } from "react";

import FormDoc from "./FormDoc";

import EmitirDocProvider, { EmitirDocContext } from "./EmitirDocProvider";
import FormBuscarDetalle from "./FormBuscarDetalle";
import ListaDetalle from "./ListaDetalle";
import TotalDocumento from "./TotalDocumento";
import Modals from "./Modals";
import ModalGuardar from "./ModalGuardar";
import InformacionAdicional from "../../../features/components/InformacionAdicional";
import MobileSubmitButton from "./MobileSubmitButton";
import BuscarFacturaNotaCredito from "./BuscarFacturaNotaCredito";
import SubmitButtonNC from "./SubmitButtonNC";
import { Transition } from "@headlessui/react";
import ReporteServicioProvider from "../../Mantenimiento/ProductosServicios/ReporteServicioProvider";
import ModalAdd from "../../Mantenimiento/ProductosServicios/ModalAdd";

const EmitirNotaCredito = () => {
  const ClassAvite =
    "text-sm py-1.5 w-full cursor-pointer  p-2 px-2 text-center bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-tl-lg rounded-tr-lg font-medium leading-6 text-white";
  const [isOpen, setIsOpen] = useState(true);
  return (
    <EmitirDocProvider>
      <div className=" p-4">
        <BuscarFacturaNotaCredito />
        <div className="  dark:bg-gray-900 sm:rounded-lg   ">
          <FormDoc />

          <div className=" lg:p-2 md:p-2 lg:px-4 md:px-0 px-1  bg-white dark:bg-gray-900 ">
            <FormBuscarDetalle />
          </div>



          <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:px-4 md:px-0 mx-0 lg:gap-2 md:gap-2 lg:p-2 md:p-2 lg:space-y-0 md:space-y-0  dark:bg-gray-900   bg-white ">
            <div className="relative z-0 border rounded-lg dark:border-gray-600   w-full group">
              <div className="flex space-x-0.5 w-full">
                <h3
                  className={ClassAvite + " flex justify-between items-center"}
                >
                  Información adicional (Opcional)
                  <svg
                    onClick={() => setIsOpen(!isOpen)}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`w-4 h-4 lg:hidden md:hidden text-white ${isOpen ? "transform rotate-180" : ""}`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </h3>
              </div>
              <Transition
                show={isOpen}
                enter="transition ease-out duration-300" // Aumenta la duración de entrada
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-300" // Aumenta la duración de salida
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="p-4">
                  <InformacionAdicional Contexto={EmitirDocContext} />
                </div>
              </Transition>
            </div>
            <div className=" z-0 border  dark:border-gray-600  rounded-lg  w-full ">
              <TotalDocumento />
            </div>
          </div>
          <div className="flex lg:justify-end justify-center  bg-white dark:bg-gray-900 rounded-b-lg p-3">
            <SubmitButtonNC />
          </div>
          {/* <MobileSubmitButton /> */}
        </div>
      </div>
      <Modals />
      <ReporteServicioProvider>
            <ModalAdd />
          </ReporteServicioProvider>
      <ModalGuardar />
    </EmitirDocProvider>
  );
};

export default EmitirNotaCredito;
