import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getListV2 } from "../../../services";

import { EmitirFacturaContext } from "./EmitirFacturaProvider";

import { redondear, roundToTwo } from "../../../services/Utilitario";
import {
  StyleInput,
  StyleInputError,
  StyleLabel,
  StyleLabelError,
  StyleSelect,
} from "../../../features/Constantes";
import { LargeModalForm } from "../../../features/components/LargeModalForm";
import NumberFormat from "react-number-format";
import InputNumber from "../../../features/components/common/InputNumber";
import { useForm } from "react-hook-form";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";
import InfoAdicionalModals from "./InfoAdicionalModals";
import LengthCounter from "../../../features/components/LengthCounter";

const Modals = () => {
  const {
    DetalleItem,
    setDetalleItem,
    setActivarModalDetalle,
    DetalleList,
    setDetalleList,
  } = useContext(EmitirFacturaContext);

  const [TempDetalleItem, setTempDetalleItem] = useState({});
  const [ImpuestosList, setImpuestosList] = useState([]);
  const [InformacionAdicionalList, setInformacionAdicionalList] = useState([]);

  const {
    register,
    setValue,
    formState: { errors },
    getValues,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      descripcion: "",
    },
  });

  const { ErrorToast } = useGlobalToastify();

  const empresa = useSelector((store) => store.empresa.empresa);

  useEffect(() => {
    setTempDetalleItem({ ...DetalleItem });
    setValue("descripcion", DetalleItem.descripcion);

    if (DetalleItem.servicioDetalle) {
      let listItems = [];
      DetalleItem?.servicioDetalle?.forEach((el) => {
        listItems.push(el);
      });
      setInformacionAdicionalList(listItems);
    }

    (async () => {
      let data = await getListV2(
        empresa,
        "GetAllimpDetalles",
        "?activo=true&idImpuesto=1"
      );
      setImpuestosList(data._embedded);
    })();
  }, [DetalleItem]);

  const handleChangeImpuesto = (e) => {
    const index = e.target.selectedOptions[0].getAttribute("data-index");

    const item = {
      ...TempDetalleItem,
      [e.target.name]: e.target.value,
      idImpuestoIvaNavigation: { ...ImpuestosList[index] },
    };
    setTempDetalleItem(item);
  };

  const calcularValorNeto = (item) => roundToTwo(item.precioUnitario) * roundToTwo(item.cantidad);

  const calcularValorConDescuento = (item) =>
    calcularValorNeto(item) - item.descuento;

  const calcularTotal = (item) => {

    if (item.descuento === undefined) return 0;
    if (item.descuento === null) return 0;
    let valorTotal = item.idImpuestoIvaNavigation ? calcularValorConDescuento(item) + calcularValorConDescuento(item) * item.idImpuestoIvaNavigation.porcentaje : calcularValorConDescuento(item);
    return roundToTwo(valorTotal);
  };

  const handleSubmitModal = () => {
    if (calcularTotal(TempDetalleItem) >= 0) {
      DetalleList[TempDetalleItem.index] = {
        ...TempDetalleItem,
        ...getValues(),
        servicioDetalle: [...InformacionAdicionalList],
      };
      setDetalleList([...DetalleList]);
      document.getElementById("editDetalleFacturaModalclose").click();
      setDetalleItem({});
      setTempDetalleItem({});
      setValue("descripcion", "");
      setActivarModalDetalle(false);
    }
  };

  useEffect(() => {
    Object.values(errors)[0] && ErrorToast(Object.values(errors)[0].message);
  }, [errors, ErrorToast]);

  const [Open, SetOpen] = useState(false);


  return (
    <LargeModalForm
      buttonModal={"editDetalleFacturaModal"}
      submitText={"Guardar"}
      title={`EDITAR DETALLE | ${TempDetalleItem.codigoPrincipal}`}
      submitModal={handleSubmit(handleSubmitModal)}
    >
      <div className="w-full  mb-2 md:mb-0">
        <div className="relative z-0 mb-2 w-full group my-2">
          <label
            htmlFor="descripcion"
            className={errors["descripcion"] ? "" : ""}
          >
            DESCRIPCIÓN{" "}
            <LengthCounter
              itemLength={
                watch("descripcion") === undefined
                  ? "hola"
                  : watch("descripcion")
              }
            />
          </label>
          <input
            type="text"
            name="descripcion"
            className={errors["descripcion"] ? StyleInputError : StyleInput}
            {...register("descripcion", {
              required: {
                value: true,
                message: "Descripción es requerido",
              },
              maxLength: {
                value: 300,
                message: "Descripción no debe tener más de 300 carácteres!",
              },
              minLength: {
                value: 1,
                message: "Descripción debe tener mínimo 1 carácter",
              },
            })}
            maxLength="300"
            // onChange={(e) =>
            //   setTempDetalleItem({
            //     ...TempDetalleItem,
            //     descripcion: e.target.value,
            //   })
            // }
          />
        </div>

        <div className="w-full grid md:grid-cols-4 md:gap-2 mb-2 my-3 ">
          <div>
            <InputNumber
              identifier="cantidad"
              name="CANTIDAD"
              decimalMax={6}
              value={TempDetalleItem.cantidad}
              onChange={(e) =>
                setTempDetalleItem({
                  ...TempDetalleItem,
                  cantidad: e.target.value,
                })
              }
            />
          </div>

          <div className="relative z-0 mb-2 w-full group">
            <InputNumber
              identifier="precioUnitario"
              name="PVP UNITARIO"
              decimalMax={6}
              value={TempDetalleItem.precioUnitario}
              onChange={(e) =>
                setTempDetalleItem({
                  ...TempDetalleItem,
                  precioUnitario: e.target.value,
                })
              }
            />
          </div>
          <div className="relative z-0 mb-2 w-full group">
            <label className={StyleLabel + " -translate-x-0 -translate-y-0"}>
              PVP * CANTIDAD
            </label>
            <NumberFormat
              decimalScale={2}
              displayType={"text"}
              thousandSeparator={true}
              placeholder=" "
              className={StyleInput + " bg-[#EBEBEB]"}
              value={calcularValorNeto(TempDetalleItem)}
              name="pvpCantidad"
              id="pvpCantidad"
              readOnly
            />
          </div>
          <div className="relative z-0 mb-2 w-full group">
          <InputNumber
            identifier="descuento"
            name="DESCUENTO $"
            decimalMax={2}
            value={TempDetalleItem.descuento}
            onChange={(e) =>
              setTempDetalleItem({
                ...TempDetalleItem,
                descuento: e.target.value,
              })
            }
          />
        </div>
        </div>
      </div>

        <div className="grid grid-cols-4 gap-3">

        {/* <div >
            <label htmlFor="">miinput</label>
            <input type="text" value={TempDetalleItem.idImpuestoIva} />
          </div> */}
          <div>

          </div>
          <div>

          </div>
        <div className="relative z-0 mb-2 w-full group">
          <label
            htmlFor="idImpuestoIva"
            className={StyleLabel + " -translate-x-0 -translate-y-0"}
          >
            IMPUESTO
          </label>
          <select
            type="text"
            className={StyleSelect}
            placeholder=" "
            name="idImpuestoIva"
            id="idImpuestoIva"
            onChange={handleChangeImpuesto}
            value={
              TempDetalleItem.idImpuestoIva
                ? TempDetalleItem.idImpuestoIva.toLowerCase()
                : ""
            }
          >
            {ImpuestosList.map((el, index) => (
              <option
                key={index}
                data-index={index}
                value={el.idImpuestoDetalle}
              >
                {el.detalle}
              </option>
            ))}
          </select>
        </div>
        <div className="relative z-0 mb-2 w-full group">
          <label
            htmlFor="precioUnitario"
            className={StyleLabel + " -translate-x-0 -translate-y-0"}
          >
            TOTAL
          </label>

          <NumberFormat
            className={
              calcularTotal(TempDetalleItem) >= 0 ? StyleInput + " bg-[#EBEBEB]" : StyleInputError
            }
            decimalScale={2}
            displayType={"text"}
            thousandSeparator={true}
            placeholder=" "
            id="subTotal"
            name="subTotal"
            value={calcularTotal(TempDetalleItem)}
            readOnly
          />
        </div>
      </div>

      <div>
          <div>
            <p
              onClick={() => SetOpen(!Open)}
              className="text-lg font-semibold p-2 border-b border-b-gray-400 select-none cursor-pointer dark:text-white flex gap-x-2  items-center   font-body"
            >
              Información adicional (Opcional){" "}
              {!Open ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              )}
            </p>
            {Open ? (
              <InfoAdicionalModals
              InformacionAdicionalList={InformacionAdicionalList}
              setInformacionAdicionalList={setInformacionAdicionalList}
            />
            ) : (
              <></>
            )}
          </div>
        </div>











   
    </LargeModalForm>
  );
};

export default Modals;
