import React from 'react';
import FormFactura from './FormFactura';
import ListaFacturas from './ListaFacturas';
import { ModalTrazabilidad } from './ModalTrazabilidad';
import ReporteFacturaProvider from './ReporteFacturaProvider';

const ReporteCliente = () => {
    return (
        <div className="space-y-6 xl:mx-4 my-2" >
            <div className="bg-white shadow  dark:bg-gray-900 sm:rounded-lg md:py-2 md:px-4 py-2 px-2" >
                <ReporteFacturaProvider>
                    <FormFactura />
                    <ListaFacturas />
                    {/* <ModalTrazabilidad /> */}
                </ReporteFacturaProvider>
            </div>
        </div>
    );
};

export default ReporteCliente;