import React, { useContext, Fragment } from "react";
//import useVistaPrevia from "../hooks/useVistaPrevia";
import { Menu, Transition } from "@headlessui/react";
import { EmitirFacturaContext } from "./EmitirFacturaProvider";
//import { TotalInicial } from "../../../features/bl/DocumentSharedBL";
import { useSelector } from "react-redux";
import useVistaPreviaFactura from "./hooks/useVistaPreviaFactura";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";
//import {getFechaV1,getNoventaDiasAtras,rellenarCeros,roundToTwo,} from "../../../services/Utilitario";
//import {useVistaPreviaFactura} from "./hooks/useVistaPreviaFactura";

const MobileSubmitButtonFactura = () => {
  const { ErrorToast } = useGlobalToastify();
  const {
    handleResetDocument,
    DetalleList,
    Totales,
    setSaveDataMessage,
    methods,
    setSaveDataPTVenta,
    FormaPagoList,
  } = useContext(EmitirFacturaContext);
  const handleClickSubmitFacturaPTVenta = (e) => {
    try {
      validateForm(methods.getValues());
      if (Totales.ValorTotal !== 0) return e.preventDefault();
      if (DetalleList.length <= 0) return e.preventDefault();
      setSaveDataPTVenta(true);
      setSaveDataMessage("¿ESTÁ SEGURO DE QUE DESEA ENVIAR AL SRI?");
    } catch (ex) {}
    /* getVistaPreviaFactura()
     getVistaPreviaFacturaPTVenta() */
  };

  const validateForm = (data) => {
    if (
      data.guiaRemision !== "" &&
      !/^[0-9]{3}-[0-9]{3}-[0-9]{9}/.test(data.guiaRemision)
    ) {
      ErrorToast(
        "El campo 'Guia Remision' no coincide con el patron requerido!"
      );
      throw new Error("Error de validacion");
    }

    if (Totales.importeTotal !== Totales.TotalFormaPago) {
      ErrorToast(
        "El total de las formas de pago no coincide con el total de la factura!"
      );
      throw new Error("Error de validacion");
    }
    if (
      data.clienteIdentificacion !== "9999999999999" &&
      (data.clienteDireccion === "" ||
        data.clienteEmail === "" ||
        data.clienteIdentificacion === "" ||
        data.clienteRazonSocial === "")
    ) {
      ErrorToast("Falta informacion en el apartado de contribuyente!");
      throw new Error("Error de validacion");
    }

    if (DetalleList.length < 1) {
      ErrorToast("Falta agregar un producto o servicio en la factura!");
      throw new Error("Error de validacion");
    }

    if (FormaPagoList.length < 1) {
      ErrorToast("Falta agregar una forma de pago valida a la factura!");
      throw new Error("Error de validacion");
    }
    
    const cadenaCorreosModificada = data.clienteEmail.replace(/,/g, ";").replace(/\s+/g, "");

    if (
      cadenaCorreosModificada !== "" &&
      !/^([^\s@]+@[^\s@]+\.[^\s@]+)(;[^\s@]+@[^\s@]+\.[^\s@]+)*$/.test(
        cadenaCorreosModificada
      )
    ) {
      ErrorToast("El campo 'Correo' no coincide con el patron requerido");
      throw new Error("Error de validacion");
    }
  };

  const { getVistaPreviaFactura, IsLoading } = useVistaPreviaFactura();
  const empresa = useSelector((store) => store.empresa.empresa);

  return (
    <div className=" my-auto lg:hidden md:hidden " >
   

      <Menu as="div" className="relative  text-left ">
        <div>
          <Menu.Button className="flex items-center dark:text-white  px-2  text-sm font-medium text-gray-700 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 my-auto text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
              />
            </svg>
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right z-10 absolute right-0 text-gray-500 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleResetDocument}
                    type="button"
                    className={"group flex items-center px-4 py-2 text-sm"}
                    data-bs-toggle="modal"
                    data-bs-target="#searchFacturaModal"
                    id="searchFacturaModalButton"
                   
                  >
                   
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="h-5 w-5 mr-1 text-gray-400 "
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                      />
                    </svg>
                    Replicar factura
                  </button>
                )}
              </Menu.Item>
              {empresa.puntoventa && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={handleClickSubmitFacturaPTVenta}
                      type="button"
                      className={"group flex items-center  px-4 py-2 text-sm"}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-5 w-5 mr-1 text-gray-400 "
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                        />
                      </svg>
                      Punto venta
                    </button>
                  )}
                </Menu.Item>
              )}

              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleResetDocument}
                    type="button"
                    className={"group flex items-center px-4 py-2 text-sm"}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="h-5 w-5 mr-1 text-gray-400 "
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                    Limpiar
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default MobileSubmitButtonFactura;
