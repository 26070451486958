import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  StyleInput,
  StyleInputError,
  StyleLabel,
  StyleLabelError,
  StyleSelect,
} from "../../../../features/Constantes";
import { PutElement } from "../../../../services";
import { MailsRegExp, UTF8RegExp } from "../../../../services/Utilitario";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MailsForm from "../components/MailsForm";
import { Oval } from "react-loader-spinner";
import PhoneInputWithCountrySelect from "react-phone-number-input";

const EditContribuyente = () => {
  const [OpenCorreo, setOpenCorreo] = useState(false);

  let { id } = useParams();
  const [IsLoading, setIsLoading] = useState(false);

  const [DataFinal, setDataFinal] = useState({});

  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
    setValue,
    watch,
  } = useForm({ mode: "onBlur" });

  const IsTrasnsportistaObs = watch("IsTransportista");

  const empresa = useSelector((store) => store.empresa.empresa);

  const handleSubmitModal = async () => {
    try {
      let request = { ...getValues(), IdContribuyente: id };
      request.identificacion = request.identificacion.trim();
      request.razonsocial = request.razonsocial.trim();
      request.correo = request.correo.trim();
      request.IsTransportista = request.IsTransportista === "1";
      request.IsPartRel = request.IsPartRel === "1";
      request.EsRuc = request.EsRuc === "1";
      request.activo = request.activo === "1";
      request.direccion = request.direccion.trim();

      if (request.EsCedula === "1") {
        request.idTipoIdentificacion = 1;
      }

      if (request.EsRuc === "1") {
        request.identificacion = request.identificacion + "001";
      }

      if (request.correo === "") {
        const emails = request.correo.split(";");
        for (let i = 0; i < emails.length; i++) {
          const email = emails[i].trim();
          if (!MailsRegExp.test(email)) {
            return toast.warning("El correo ingresado no es válido!", {
              isLoading: false,
              autoClose: 2000,
            });
          }
        }
      }

      delete request._links;
      delete request.EsCedula;
      delete request.EsRuc;

      if (request.identificacion === "") {
        toast.warning("El campo identificacion es obligatorio!", {
          isLoading: false,
          autoClose: 2000,
        });
        return;
      }
      if (request.razonsocial === "") {
        toast.warning("El campo razon social es obligatorio!", {
          isLoading: false,
          autoClose: 2000,
        });
        return;
      }
      /* if (request.direccion.trim() === "") {
        toast.warning("El campo direccion es obligatorio!", {
          isLoading: false,
          autoClose: 2000,
        });
        return;
      } */

      const putRes = await PutElement(empresa, "EditContribuyente", request);
      const toasPromise = () =>
        new Promise((resolve) => setInterval(() => resolve(putRes), 2000));

      await toast.promise(toasPromise(), {
        pending: {
          render() {
            setIsLoading(true);
            return "Actualizando contribuyente....";
          },
          isLoading: true,
        },
        success: {
          render() {
            setIsLoading(false);
            navigate("/emision/reporte/contribuyentes");
            return "Contribuyente actualizado con exito!";
          },
          autoClose: 2000,
          closeOnClick: true,
        },
        error: {
          render() {
            setIsLoading(false);
            return "Algo ha sucedido, intente nuevamente";
          },
          autoClose: 2000,
          closeOnClick: true,
        },
      });
    } catch (ex) {
      toast.error("Error al editar el contribuyente", {
        isLoading: false,
        autoClose: 2000,
      });
    }
  };

  const loadUser = async () => {
    const { data } = await axios.get(
      `/api/Contribuyente/CargarContribuyente/${empresa.idEmpresa}/?IdContribuyente=${id}`
    );
    // console.log({ ...data });

    if (data.esRuc) data.identificacion += "001";
    data.IsTransportista = data.isTransportista ? "1" : "0";
    data.IsPartRel = data.isPartRel ? "1" : "0";
    data.EsRuc = data.esRuc ? "1" : "0";
    data.activo = data.activo ? "1" : "0";

    delete data.isTransportista;
    delete data.isPartRel;

    if (data === "") {
      setTipoIdentificacion("");
    } else if (data.esRuc) {
      setTipoIdentificacion("RUC");
    } else if (data.idTipoIdentificacion === 1) {
      setTipoIdentificacion("CEDULA");
    } else if (data.idTipoIdentificacion === 2) {
      setTipoIdentificacion("RUC");
    } else if (data.idTipoIdentificacion === 3) {
      setTipoIdentificacion("Pasaporte");
    } else if (data.idTipoIdentificacion === 5) {
      setTipoIdentificacion("Extranjero");
    } else {
      setTipoIdentificacion("");
    }
    return data;
  };

  useEffect(() => {
    (async () => {
      const data = await loadUser();
      // console.log(data);
      reset({ ...data });
      setDataFinal(data);
    })();
  }, []);

  const handleEsRucChange = (e) => {
    const newValue = e.target.value;

    if (newValue === "1") {
      setValue("idTipoIdentificacion", 1);
    } else {
      setValue("idTipoIdentificacion", 2);
    }
  };

  const handleRucChange = (e) => {
    const newValue = e.target.value;
    if (newValue === "1") {
      setValue("esRuc", 1);
    } else {
      setValue("esRuc", false);
    }
  };

  const [tipoIdentificacion, setTipoIdentificacion] = useState("");

  return (
    <div className="space-y-6 xl:mx-4 my-2 mt-5 bg-white dark:bg-gray-700 p-4">
      <div className="bg-[#DBDBDB] dark:bg-gray-800 p-5 rounded-md ">
        <p className="font-semibold text-xl dark:text-white text-black">
          Información del Contribuyente / Cliente
        </p>
        <p className="dark:text-white">Editar información</p>
      </div>
      <form
        onSubmit={handleSubmit(handleSubmitModal)}
        className="bg-white shadow  dark:bg-gray-900 sm:rounded-lg md:py-2 md:px-4 py-2 px-2"
      >
        <p className="p-2 border-b dark:text-white text-black font-semibold border-b-gray-400">
          Datos Generales
        </p>

        <div className="relative z-0 mb-2  pt-2 w-full   mt-7">
          <div className="w-full grid md:grid-cols-5 grid-cols-1 md:gap-4 mb-2 ">
            <div className="relative z-0 mb-2 w-full ">
              <input
                type="text"
                className={errors.identificacion ? StyleInputError : StyleInput + " bg-gray-200 dark:bg-gray-600"}
                placeholder=" "
                id="identificacion"
                name="identificacion"
                {...register("identificacion", {
                  required: {
                    value: true,
                    message: "Identificación es requerido",
                  },
                  maxLength: {
                    value: 20,
                    message: "No más de 20 carácteres!",
                  },
                  minLength: {
                    value: 3,
                    message: "Mínimo 3 carácteres",
                  },
                })}
                maxLength="20"
                readOnly
              />
              <div
                htmlFor="identificacion"
                className={errors.correo ? StyleLabelError : StyleLabel}
              >
                <div className=" flex  relative">
                  Identificación
                  {tipoIdentificacion && ` / ${tipoIdentificacion}`} *
                  <div className="px-2    group">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 30 30"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                      />
                    </svg>
                    <div
                      className="absolute   text-white text-sm
                      -top-14 -left-1 hidden dark:bg-white dark:text-black 
                       rounded-md bg-gray-800 group-hover:flex text-center p-1"
                    >
                      Número de RUC, cédula o pasaporte
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative z-0 mb-2 w-full group">
              <input
                type="text"
                className={errors.razonsocial ? StyleInputError : StyleInput}
                placeholder=" "
                id="razonsocial"
                name="razonsocial"
                {...register("razonsocial", {
                  required: {
                    value: true,
                    message: "Nombre es requerido",
                  },
                  maxLength: {
                    value: 300,
                    message: "No más de 300 carácteres!",
                  },
                  minLength: {
                    value: 4,
                    message: "Mínimo 4 carácteres",
                  },
                })}
                maxLength="300"
              />
              <label
                htmlFor="razonsocial"
                className={errors.razonsocial ? StyleLabelError : StyleLabel}
              >
                Razón social *
              </label>
            </div>
            <div className="relative z-0 mb-2 w-full group">
              <input
                type="text"
                className={errors.direccion ? StyleInputError : StyleInput}
                placeholder=" "
                id="direccion"
                name="direccion"
                {...register("direccion", {
                  required: {
                    value: true,
                    message: "Nombre es requerido",
                  },
                  maxLength: {
                    value: 300,
                    message: "No más de 300 carácteres!",
                  },
                  minLength: {
                    value: 3,
                    message: "Mínimo 3 carácteres",
                  },
                  pattern: UTF8RegExp,
                })}
                maxLength="300"
              />
              <label
                htmlFor="direccion"
                className={errors.direccion ? StyleLabelError : StyleLabel}
              >
                Dirección *
              </label>
            </div>
            <div className="relative z-0 w-full col-span-2">
              <input
                type="text"
                className={errors.correo ? StyleInputError : StyleInput}
                placeholder=" "
                id="correo"
                name="correo"
                {...register("correo", {
                  required: {
                    value: true,
                    message: "Correo es requerido",
                  },
                  maxLength: {
                    value: 300,
                    message: "No más de 300 carácteres!",
                  },
                  minLength: {
                    value: 4,
                    message: "Mínimo 4 carácteres",
                  },
                  pattern: MailsRegExp,
                })}
                maxLength="300"
                onChange={(e) => {
                  const trimmedValue = e.target.value.trim(); // Eliminar espacios en blanco
                  setValue("correo", trimmedValue); // Actualizar el valor del campo
                }}
              />
              <div
                htmlFor="correo"
                className={errors.correo ? StyleLabelError : StyleLabel}
              >
                <div className=" flex ">
                  Correo *{" "}
                  {/*  <label className="border bg-green-400 relative"> */}
                  <div className="px-2 relative group">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 30 30"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                      />
                    </svg>
                    <div
                      className="absolute   text-white text-sm
                      -top-8 -left-8 hidden dark:bg-white dark:text-black 
                       rounded-md bg-gray-800 group-hover:flex text-center p-1"
                    >
                      ejemplo1@hotmail.com;ejemplo2@hotmail.com;...
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative z-0 mb-2 w-full ">
              {/* <input
                type="number"
                id="telefonocelular"
                name="telefonocelular"
                className={
                  errors.telefonocelular ? StyleInputError : StyleInput
                }
                placeholder=" "
                {...register("telefonocelular", {
                  maxLength: {
                    value: 13,
                    message: "No más de 13 carácteres!",
                  },
                  minLength: {
                    value: 4,
                    message: "Mínimo 4 carácteres",
                  },
                })}
                maxLength="13"
              /> */}

              <input
                type="text"
                id="telefonocelular"
                name="telefonocelular"
                className={
                  errors.telefonocelular ? StyleInputError : StyleInput
                }
                {...register("telefonocelular", {
                  maxLength: {
                    value: 14,
                    message: "No más de 13 carácteres!",
                  },
                  minLength: {
                    value: 4,
                    message: "Mínimo 4 carácteres",
                  },
                })}
                maxLength={14}
                pattern="^\+?[0-9]+$"
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^\+?[0-9]*$/;
                  const plusCount = (value.match(/\+/g) || []).length;
                  if (
                    regex.test(value) &&
                    plusCount <= 1 &&
                    (plusCount === 0 || value.startsWith("+"))
                  ) {
                    setValue("telefonocelular", value);
                  } else {
                    let sanitizedValue = value.replace(/[^0-9]/g, "");
                    if (value.startsWith("+")) {
                      sanitizedValue = "+" + sanitizedValue;
                    }
                    setValue("telefonocelular", sanitizedValue);
                  }
                }}
              />

              {/* <PhoneInputWithCountrySelect
                defaultCountry="EC"
                limitMaxLength={true}
                name="telefonocelular"
                id="telefonocelular"
               
                className={
                  errors.telefonocelular ? StyleInputError : StyleInput 
                }
                {...register("telefonocelular", {
                  maxLength: {
                    value: 13,
                    message: "No más de 13 carácteres!",
                  },
                  minLength: {
                    value: 4,
                    message: "Mínimo 4 carácteres",
                  },
                })}
              /> */}
              <div
                htmlFor="telefonoconvencional"
                className={errors.correo ? StyleLabelError : StyleLabel}
              >
                <div className=" flex ">
                  Teléfono celular (Opcional){" "}
                  {/*  <label className="border bg-green-400 relative"> */}
                  <div className="px-2 relative group">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 30 30"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                      />
                    </svg>
                    <div
                      className="absolute  text-white text-sm  -top-8 -left-8 hidden dark:bg-white dark:text-black   
                        rounded-md bg-gray-800 group-hover:flex text-center p-1 "
                    >
                      (EJ:0958922462)
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative z-0 w-full group">
              <select
                name="activo"
                id="activo"
                {...register("activo", {})}
                className={StyleSelect}
                x-model="item.gst"
              >
                <option value="1">ACTIVO</option>
                <option value="0">INACTIVO</option>
              </select>
              <label htmlFor="activo" className={StyleLabel}>
                Estado
              </label>
            </div>
          </div>
        </div>

        {/* <div className="relative z-0  w-full group">
          <div className="w-full grid md:grid-cols-2 md:gap-4 ">
            <div className="relative z-0  w-full group">
              <input
                type="number"
                className={
                  errors.telefonoconvencional ? StyleInputError : StyleInput
                }
                placeholder=" "
                id="telefonoconvencional"
                name="telefonoconvencional"
                {...register("telefonoconvencional", {
                  maxLength: {
                    value: 20,
                    message: "No más de 20 carácteres!",
                  },
                  minLength: {
                    value: 4,
                    message: "Mínimo 4 carácteres",
                  },
                })}
                maxLength="20"
              />
              <label
                htmlFor="telefonoconvencional"
                className={
                  errors.telefonoconvencional ? StyleLabelError : StyleLabel
                }
              >
                Teléfono Convencional <b>(EJ: 04-289-0005)</b>
              </label>
            </div>
            <div className="relative z-0 w-full group">
              <input
                type="text"
                className={errors.correo ? StyleInputError : StyleInput}
                placeholder=" "
                id="correo"
                name="correo"
                {...register("correo", {
                  maxLength: {
                    value: 120,
                    message: "No más de 120 carácteres!",
                  },
                  minLength: {
                    value: 4,
                    message: "Mínimo 4 carácteres",
                  },
                  pattern: MailsRegExp,
                })}
                maxLength="300"
              />
              <label
                htmlFor="correo"
                className={errors.correo ? StyleLabelError : StyleLabel}
              >
                Correo <b>(ejemplo@gmail.com)</b>
              </label>
              <p className=" invisible peer-invalid:visible text-pink-600 text-sm transform -translate-y-5 scale-75 top-5 -z-10 origin-[0]">
                Proporcione una dirección de correo electrónico válida.
              </p>
            </div>
          </div>
        </div> */}

        <div className="relative z-0   w-full group">
          <h1 className="text-lg mb-8 dark:text-white   font-semibold p-2 border-b border-b-gray-400">
            Datos Adicionales
          </h1>
          <div className="w-full grid md:grid-cols-5 md:gap-4 gap-2 pt-2 ">
            <div className="w-full ">
              <div className="relative z-0 mb-2 w-full ">
                <input
                  type="number"
                  className={
                    errors.telefonoconvencional ? StyleInputError : StyleInput
                  }
                  placeholder=" "
                  id="telefonoconvencional"
                  name="telefonoconvencional"
                  {...register("telefonoconvencional", {
                    maxLength: {
                      value: 15,
                      message: "No más de 15 carácteres!",
                    },
                    minLength: {
                      value: 4,
                      message: "Mínimo 4 carácteres",
                    },
                  })}
                  maxLength="15"
                />
                <div
                  htmlFor="telefonoconvencional"
                  className={errors.correo ? StyleLabelError : StyleLabel}
                >
                  <div className=" flex ">
                    Teléfono convencional (Opcional){" "}
                    {/*  <label className="border bg-green-400 relative"> */}
                    <div className="px-2 relative group">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 30 30"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                        />
                      </svg>
                      <div
                        className="absolute  text-white text-sm  -top-8 -left-8 hidden dark:bg-white dark:text-black   
                        rounded-md bg-gray-800 group-hover:flex text-center p-1"
                      >
                        (EJ:042890005)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {DataFinal.esRuc && DataFinal.idTipoIdentificacion === 1 ? (
                <div className="relative z-0 w-full group">
                  <select
                    className={StyleSelect}
                    x-model="item.gst"
                    onChange={handleEsRucChange}
                  >
                    <option value="1">SI</option>
                    <option value="0">NO</option>
                  </select>
                  <label className={StyleLabel}>También es Cédula</label>
                </div>
              ) : DataFinal.esRuc && DataFinal.idTipoIdentificacion === 2 ? (
                <div className="relative z-0 w-full group">
                  <select
                    className={StyleSelect}
                    x-model="item.gst"
                    onChange={handleEsRucChange}
                  >
                    <option value="0">NO</option>
                    <option value="1">SI</option>
                  </select>
                  <label className={StyleLabel}>También es Cédula</label>
                </div>
              ) : getValues().idTipoIdentificacion === 1 ? (
                <div className="relative z-0 w-full group">
                  <select
                    className={StyleSelect}
                    x-model="item.gst"
                    onChange={handleRucChange}
                  >
                    <option value="0">NO</option>
                    <option value="1">SI</option>
                  </select>
                  <label className={StyleLabel}>También es Ruc</label>
                </div>
              ) : null}
            </div>
            <div className="relative z-0  w-full group">
              <select
                name="IsPartRel"
                id="IsPartRel"
                className={StyleSelect}
                x-model="item.gst"
                {...register("IsPartRel", {})}
              >
                <option value="0">NO</option>
                <option value="1">SI</option>
              </select>
              <label htmlFor="IsPartRel" className={StyleLabel}>
                Es Parte Relacionada
              </label>
            </div>
            <div className="relative z-0 mb-2 w-full group">
              <select
                name="IsTransportista"
                id="IsTransportista"
                className={StyleSelect}
                x-model="item.gst"
                {...register("IsTransportista", {})}
              >
                <option value="0">NO</option>
                <option value="1">SI</option>
              </select>
              <label htmlFor="IsTransportista" className={StyleLabel}>
                Es transportista
              </label>
            </div>
            {IsTrasnsportistaObs === "1" ? (
              <div className="relative z-0 mb-2 w-full group">
                <input
                  type="text"
                  className={errors.Placa ? StyleInputError : StyleInput}
                  placeholder=" "
                  id="placa"
                  name="placa"
                  {...register("placa", {
                    maxLength: {
                      value: 10,
                      message: "No más de 10 carácteres!",
                    },
                    minLength: {
                      value: 4,
                      message: "Mínimo 4 carácteres",
                    },
                    required: {
                      value: true,
                      message: "Nombre es requerido",
                    },
                    pattern: UTF8RegExp,
                  })}
                  maxLength="10"
                />
                <label
                  htmlFor="Placant"
                  className={errors.Placa ? StyleLabelError : StyleLabel}
                >
                  Placa <b>(GMT-1234)</b>
                </label>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="">
            <div>
              <div>
                <p
                  onClick={() => setOpenCorreo(!OpenCorreo)}
                  className="text-lg font-semibold p-2 border-b border-b-gray-400 select-none cursor-pointer dark:text-white flex gap-x-2  items-center   font-body"
                >
                  Personalización de correos de envío (Opcional){" "}
                  {!OpenCorreo ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  )}
                </p>
                {OpenCorreo ? (
                  <MailsForm setValue={setValue} watch={watch} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <div className="flex align-middle justify-end my-2">
            <button>
              <a
                onClick={() => navigate("/emision/reporte/contribuyentes")}
                className="text-white bg-[#6B6B6B] hover:bg-gray-600 focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-base px-6 py-2.5 text-center md:mr-5 mb-3 md:mb-0 inline-flex items-center justify-center"
              >
                Cancelar
              </a>
            </button>
            <button
              disabled={IsLoading ? true : false}
              className=" text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-base px-6 py-2.5 text-center md:mr-5 mb-3 md:mb-0 inline-flex items-center justify-center"
            >
              {IsLoading ? (
                <Oval
                  height={18}
                  width={65}
                  color="#FFFFFF"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#233876"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                "Guardar"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditContribuyente;
