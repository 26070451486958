import React from "react";
import { SmallModalInfoComunicate } from "../../features/components/SmallModalInfoComunicate";

const ModalComunicado = () => {
  return (
    <SmallModalInfoComunicate
      buttonModal={"Comunicado"}
      submitText={"INFO"}
      title="Intuito S.A. | Aviso!"
    >
      <div className=" px-5   w-full group">
        <p className="text-xl text-center font-bold ">
          ¡Comunicado importante!
        </p>
        <p className="text-base text-center font-semibold ">
          Cambio de Régimen
        </p>
        <p className="text-base my-4">
          Desde el 1/01/2024 recuerda que el SRI recategorizó y excluyó a los{" "}
          <b> contribuyentes RIMPE</b> (Régimen Impositivo para Microempresas y
          Negocios Populares).
        </p>
        <div >
          <b>Verifica</b> el estado de Régimen de tu RUC en la página web del
          SRI:{" "}
          <div  className="flex justify-center my-2">
            <a
              className="bg-blue-500 w-52 p-1.5 flex items-center rounded-lg text-white text-base"
              target="_blank"
              href="https://srienlinea.sri.gob.ec/sri-en-linea/SriRucWeb/ConsultaRuc/Consultas/consultaRuc"
              rel="noopener noreferrer" //solo borrar en caso de fallo esta linea
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
                />
              </svg>
              SRI consulta de RUC
            </a>
          </div>
        </div>
        <div className="my-4">
          <p><b>Actualiza</b> de ser el caso tu leyenda de Régimen, para realizarlo por favor revisar el siguiente tutorial:</p>
          <p className="flex items-center justify-center gap-x-2 space-x-2">
           
            <a
              className="bg-red-500 p-1.5 flex rounded-lg text-white"
              href="https://youtube.com/shorts/iSzKaiDpNZc?feature=share."
              target="_blank"
              rel="noopener noreferrer" //solo borrar en caso de fallo esta linea
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                />
              </svg>
              Ver Tutorial
            </a>
          </p>
        </div>
        <div className="flex justify-center">
          <p className="italic ">¡Intuito S.A. informa a sus clientes!</p>
        </div>
      </div>
    </SmallModalInfoComunicate>
  );
};
export default ModalComunicado;
