import { createSlice } from "@reduxjs/toolkit";

export const ModalStatusConfiguracion = {
    off: "Off",
    medidas: "medidas",
    trazabilidad: "trazabilidad",
    valoracion: "valoracion",
}
const initialState = {
    modalStatus : ModalStatusConfiguracion.off,
}

const slice = createSlice({
    name: "modalConfiguracion",
    initialState,
    reducers: {
        initializeApp: () => initialState,
        changeConfiguracionModal: (state,action) => {
            console.log(action.payload)
            if(action.payload !== null){
                state.modalStatus = action.payload
            }
        },
        ConfiguracionModalOff: (state,action) => {
            state.modalStatus = ModalStatusConfiguracion.off
        }
    }
})

export const {
    changeConfiguracionModal,
    ConfiguracionModalOff
} = slice.actions

export default slice.reducer