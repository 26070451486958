import React, { createContext, useEffect, useState } from "react";
import { getListV2 } from "../../../services";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import axios from "axios";

export const ReporteMasivoContext = createContext();

const ReporteMasivoProvider = (props) => {
  const empresa = useSelector((store) => store.empresa.empresa);
  const [ListaMasivo, setListaMasivo] = useState([]);
  const [EstadosMasivos, setEstadosMasivos] = useState([]);
  const [start, setStart] = useState(0);

  const [Actualizar, setActualizar] = useState(false);
  const ObtenerMasivos = async () => {
    let data = await getListV2(empresa, "EstadosReporteMasivo");
    // console.log(data);
    setEstadosMasivos(data);
  };

  const initialStatePaginacion = {
    pagina: 1,
    _totalpaginas: 1,
    _totalelementos: 0,
    _links: [],
    _inicioRegistro: 0,
    _totalEnPagina: 0,
  };

  const [documentos, setDocumentos] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [Paginacion, setPaginacion] = useState(initialStatePaginacion);

  const formik = useFormik({

    initialValues: {
      cantidad: 10,
    },


    onSubmit: async (obj) => {
      try {
        await fecthData(obj.cantidad);
      } catch (error) {
        //  console.log(error)
      }
    },
  });

  const fecthData = async (cantidad =  10) => {
    try {
      setLoading(true);
      let res =  await axios.get(
         `api/ReporteMasivo/solicitudes/${empresa.idEmpresa}?start=${start}&cantidad=${cantidad}&Pagina=${Paginacion.pagina}&Cantidad=${cantidad}`
      )

 







      if (!(res.data === undefined)) {
        setPaginacion({
          ...Paginacion,
          _totalpaginas: res.data._totalpaginas,
          _totalelementos: res.data._totalelementos,
          _links: res.data._links,
          _inicioRegistro: res.data._inicioRegistro,
          _totalEnPagina: res.data._totalEnPagina,
          _paginaActual: res.data._paginaActual,

        });
        setDocumentos(res.data._embedded);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setPaginacion(initialStatePaginacion);
      setDocumentos([]);

      //   console.log(error)
    }
  };

  // const ObtenerSolicitudesEmpresa = async () => {
  //   setActualizar(true);
  //   let data = await getListV2(
  //     empresa,
  //     "ObtenerSolicitudesMasivo",
  //     `estado=True&start=${start}&cantidad=${cantidad}&Pagina=${Paginacion.pagina}&Cantidad=${cantidad}`
  //   );
  //   // console.log(data);
  //   setListaMasivo(data);
  //   setActualizar(false);
  // };

  useEffect(() => {
    fecthData()
    ObtenerMasivos();
  }, []);

  return (
    <ReporteMasivoContext.Provider
      value={{
        ListaMasivo,
        EstadosMasivos,
        Actualizar,
        start,
        documentos,
        setStart,
        formik,
        initialStatePaginacion,
        Paginacion,
        setPaginacion,
        setDocumentos,
        Loading
      }}
    >
      {props.children}
    </ReporteMasivoContext.Provider>
  );
};

export default ReporteMasivoProvider;
