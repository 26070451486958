import React, { useContext, useEffect } from "react";
import { getList } from "../../../services/index";
import { EstablecimientosContext } from "./EstablecimientosProvider";
import axios from "axios";
import { rellenarCeros } from "../../../services/Utilitario";
import {
  TableStyle,
  TableStyleDiv,
  TableStyleTBody,
  TableStyleTHead,
  ButtonStyleDefault,
} from "../../../features/Constantes";
import MesageWarningTable from "../../../features/components/MesageWarningTable";
import { Oval } from "react-loader-spinner";

const ListaEstablecimiento = () => {
  const {
    setEditEstablecimiento,
    setPuntosEmisionList,
    setEstablecimientoIdPtoEmision,
    Paginacion,
    formik,
    setPaginacion,
    documentos,
    start,
    setStart,
    cargandoData,
  } = useContext(EstablecimientosContext);

  const checkActivo = async (idEstablecimiento) => {
    try {
      let res = await getList(
        `api/Establecimientos/desactivarEstablecimiento/${idEstablecimiento}`
      );

      if (res) {
        formik.handleSubmit();
      }
    } catch (error) {
      alert("Error al desactivar establecimientos");
    }
  };

  const editarEstablecimiento = async (idEstablecimiento) => {
    try {
      let res = await getList(`api/Establecimientos/${idEstablecimiento}`);
      setEditEstablecimiento(res);
    } catch (error) {}
  };

  const handleClickNextPage = () => {
    setStart(start + 1);
    setPaginacion({ ...Paginacion, pagina: Paginacion.pagina + 1 });
    formik.handleSubmit(formik.values);
  };

  const handleClickBeforePage = () => {
    setStart(start - 1);
    setPaginacion({ ...Paginacion, pagina: Paginacion.pagina - 1 });
    formik.handleSubmit(formik.values);
  };

  const handleClickLastPage = () => {
    setPaginacion({ ...Paginacion, pagina: Paginacion._totalpaginas });
    formik.handleSubmit(formik.values);
  };

  const handleClickFirstPage = () => {
    setPaginacion({ ...Paginacion, pagina: 1 });
    formik.handleSubmit(formik.values);
  };

  useEffect(() => {
  console.log(formik.values.cantidad);
  }, [ formik.values.cantidad]);

  return (
    <>
      <div className="border bg-white dark:bg-gray-700 dark:border-gray-600 rounded-lg px-4 py-2">
        <div className=" py-2">
          <h2 className=" pl-4 text-2xl p-5 rounded-lg bg-[#D6D6D6] dark:bg-gray-700   font-semibold text-gray-700 dark:text-gray-200">
            Establecimientos
          </h2>
        </div>
        <div className="relative  z-0  group">
          <button
            data-bs-toggle="modal"
            data-bs-target="#crearEstablecimiento"
            type="submit"
            className={
              ButtonStyleDefault +
              "bg-[#04BC53] hover:bg-green-600 focus:ring-gray-500"
            }
          >
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline mr-2 -ml-1 w-5 h-5 fill-white"
              >
                <path d="M19 2H9c-1.103 0-2 .897-2 2v5.586l-4.707 4.707A1 1 0 0 0 3 16v5a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4c0-1.103-.897-2-2-2zm-8 18H5v-5.586l3-3 3 3V20zm8 0h-6v-4a.999.999 0 0 0 .707-1.707L9 9.586V4h10v16z"></path>
                <path d="M11 6h2v2h-2zm4 0h2v2h-2zm0 4.031h2V12h-2zM15 14h2v2h-2zm-8 1h2v2H7z"></path>
              </svg>
              Agregar establecimiento
            </>
          </button>
        </div>
      </div>

      {/* <hr className="border-1  pb-5 dark:border-gray-800 " /> */}

      <div className="border bg-white dark:bg-gray-700 dark:border-gray-600 rounded-lg p-4 my-2">
        <div className=" inline-flex z-0 mx-4 my-3  group">
          <h1 className="float-left  mr-2 dark:text-white text-black">
            Mostrar
          </h1>
          <select
            type="text"
            name="cantidad"
            id="cantidad"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cantidad}
            className="origin-left border-gray-300 border pr-2 pl-1  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            placeholder=" "
          >
            <option key="25" value="25">
              25
            </option>
            <option key="50" value="50">
              50
            </option>
            <option key="75" value="75">
              75
            </option>
            <option key="100" value="100">
              100
            </option>
          </select>
          <h1 className="float-left  ml-2 dark:text-white text-black">
            Registros
          </h1>
        </div>
        <div className="px-4 sm:px-6 lg:px-8">
          <div className=" flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle px-1">
                <div className={TableStyleDiv}>
                  <table className={TableStyle}>
                    <thead className={TableStyleTHead + " bg-[#D6D6D6]"}>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3  text-sm font-semibold  sm:pl-6  "
                        >
                          No.
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3  text-sm font-semibold  sm:pl-6  "
                        >
                          Establecimiento
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3  text-sm font-semibold  sm:pl-6  "
                        >
                          Dirección
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3  text-sm font-semibold  sm:pl-6  "
                        >
                          Ciudad
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3  text-sm font-semibold  sm:pl-6  "
                        >
                          Activo
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3  text-sm font-semibold  sm:pl-6  "
                        >
                          Opciones
                        </th>
                      </tr>
                    </thead>
                    <tbody className={TableStyleTBody}>
                      {cargandoData ? (
                        <tr>
                          <td colSpan={6}>
                            <div className="mx-auto my-2 flex align-middle justify-center">
                              <Oval
                                height={25}
                                width={25}
                                color="#FFFFFF"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#233876"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                              />
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {documentos.length > 0 || cargandoData ? (
                            documentos.map((item, index) => {
                              return (
                                <tr key={item.idEstablecimiento}>
                                  <td className=" px-3 py-2 text-sm  lg:table-cell ">
                                    {(index = index + 1)}
                                  </td>
                                  <td className=" px-3 py-2 text-sm  lg:table-cell ">
                                    {rellenarCeros(item.codigo)}
                                  </td>
                                  <td className=" px-3 py-2 text-sm  lg:table-cell ">
                                    {item.direccion}
                                  </td>
                                  <td className=" px-3 py-2 text-sm  lg:table-cell ">
                                    {item.ciudad}
                                  </td>
                                  <td className=" px-3 py-2 text-sm  lg:table-cell ">
                                    <div className="items-center self-center mx-auto">
                                      <input
                                        type="checkbox"
                                        value=""
                                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        onChange={() =>
                                          checkActivo(item.idEstablecimiento)
                                        }
                                        checked={item.activo}
                                      />
                                    </div>
                                  </td>
                                  {/* icono editar tabla */}
                                  <td className=" px-3 py-2 text-sm text-gray-400 lg:table-cell ">
                                    <button
                                      title="Editar"
                                      data-bs-toggle="modal"
                                      data-bs-target="#editarEstablecimiento"
                                      type="submit"
                                      onClick={() =>
                                        editarEstablecimiento(
                                          item.idEstablecimiento
                                        )
                                      }
                                      className=" hover:bg-gray-200  dark:hover:bg-gray-700  rounded-lg p-2  text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4 dark:fill-white stroke-gray-600"
                                        viewBox="0 0 576 512"
                                      >
                                        <path d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z" />
                                      </svg>
                                    </button>
                                    {/* editar punto de Emisión */}
                                    <button
                                      onClick={async () => {
                                        let puntosEmision = await axios.get(
                                          item._links[0].enlace.replaceAll(
                                            "http://",
                                            "https://"
                                          )
                                        );
                                        setEstablecimientoIdPtoEmision(
                                          item.idEstablecimiento
                                        );
                                        setPuntosEmisionList(
                                          puntosEmision.data._embedded
                                        );
                                      }}
                                      title="Editar"
                                      data-bs-toggle="modal"
                                      data-bs-target="#listaPuntoEmision"
                                      type="submit"
                                      className=" hover:bg-gray-200  dark:hover:bg-gray-700  rounded-lg p-2  text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4 dark:fill-white stroke-gray-600"
                                        viewBox="0 0 288 512"
                                      >
                                        <path d="M144 0C64.47 0 0 64.47 0 144c0 71.31 51.96 130.1 120 141.58v197.64l16.51 24.77c3.56 5.34 11.41 5.34 14.98 0L168 483.22V285.58C236.04 274.1 288 215.31 288 144 288 64.47 223.53 0 144 0zm0 240c-52.94 0-96-43.07-96-96 0-52.94 43.06-96 96-96s96 43.06 96 96c0 52.93-43.06 96-96 96zm0-160c-35.28 0-64 28.7-64 64 0 8.84 7.16 16 16 16s16-7.16 16-16c0-17.64 14.34-32 32-32 8.84 0 16-7.16 16-16s-7.16-16-16-16z" />
                                      </svg>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <MesageWarningTable
                              colSpan="6"
                              message="No se encontraron datos para la búsqueda."
                            />
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                  <div className="bg-white dark:bg-gray-700 dark:text-gray-400 px-4 py-3 flex items-center justify-between border-t border-gray-200 dark:border-gray-700 sm:px-6">
                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <span className="text-sm font-normal ml-2 text-gray-400 dark:text-gray-400">
                        Mostrando
                        <span className="font-semibold text-gray-900 dark:text-white">
                          {"\n"} {Paginacion._inicioRegistro} {"\n"}- {"\n"}
                          {Paginacion._totalEnPagina} {"\n"}{" "}
                        </span>
                        de
                        <span className="font-semibold text-gray-900 dark:text-white">
                          {" "}
                          {Paginacion._totalelementos}{" "}
                        </span>{" "}
                        Registros
                      </span>
                      <div>
                        <nav
                          className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                          aria-label="Pagination"
                        >
                          {Paginacion.pagina !== 1 ? (
                            <>
                              <button
                                onClick={handleClickFirstPage}
                                type="button"
                                className="relative inline-flex items-center dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50"
                                disabled=""
                              >
                                <span className="sr-only">First</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                onClick={handleClickBeforePage}
                                type="button"
                                className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                                disabled=""
                              >
                                <span className="sr-only">Previous</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5 "
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="relative inline-flex items-center dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600 px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50"
                                disabled
                              >
                                <span className="sr-only">First</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                type="button"
                                className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                                disabled
                              >
                                <span className="sr-only">Previous</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                            </>
                          )}

                          {Paginacion.pagina <= Paginacion._totalpaginas - 1 ? (
                            <>
                              <button
                                onClick={handleClickNextPage}
                                type="button"
                                className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                              >
                                <span className="sr-only">Next</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                onClick={handleClickLastPage}
                                type="button"
                                className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50  dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                                disabled=""
                              >
                                <span className="sr-only">Last</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                  <path
                                    fillRule="evenodd"
                                    d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                disabled
                                className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50  dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                              >
                                <span className="sr-only">Next</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                type="button"
                                className="relative inline-flex items-center px-2 py-2 rounded-r-md border dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50"
                                disabled
                              >
                                <span className="sr-only">Last</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                  <path
                                    fillRule="evenodd"
                                    d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                            </>
                          )}
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListaEstablecimiento;
