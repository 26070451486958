import React from "react";
import FormContribuyentes from "./FormContribuyentes";
import ListaContribuyentes from "./ListaContribuyentes";
import ReporteContribuyentesProvider from "./ReporteContribuyentesProvider";
import { ModalEmail } from "./ModalCorreo";

const ReporteContribuyente = () => {
  return (
    <div className="space-y-6 xl:mx-4 my-2">
      <div className="">
        <ReporteContribuyentesProvider>
        <div className="py-1">
          <div className=" border dark:border-gray-600 rounded-xl bg-white py-4 dark:bg-gray-700">
            <FormContribuyentes />
            </div> </div>
            <div className="py-2">
          <div className=" p-2 border rounded-xl dark:bg-gray-700 dark:border-gray-600 bg-white">
          <ListaContribuyentes />
          </div></div>
          <ModalEmail />
          {/* <ModalAdd />
            <ModalEdit/> */}
        </ReporteContribuyentesProvider>
      </div>
    </div>
  );
};

export default ReporteContribuyente;
