import React, { useContext, useEffect, useState } from "react";
import MesageError from "../../../features/components/MessageError";
import { Fragment } from "react";
import { ReporteFacturaContext } from "./ReporteFacturaProvider";
import { useSelector } from "react-redux";
import {
  StyleSelect,
  StyleInput,
  StyleLabel,
  ButtonStyleDefault,
} from "../../../features/Constantes";

import { getListV2 } from "../../../services";


const FormFactura = () => {
  const {
    formik,
    cargandoData,
    setPaginacion,
    initialStatePaginacion,
    setStart,
  } = useContext(ReporteFacturaContext);

  const [Estados, setEstados] = useState([]);
  const [Establecimientos, setEstablecimientos] = useState([]);
  const [PuntosEmision, setPuntosEmision] = useState([]);
  const [TipoDocumento, setTipoDocumento] = useState([]);

  const empresa = useSelector((store) => store.empresa.empresa);
  const usuario = useSelector((store) => store.app.user);

  useEffect(() => {
    (async () => {
      const GetAllEstablecimientos = await getListV2(
        empresa,
        "ObtenerEstablecimientosPorEmpresa"
      );
      setEstablecimientos(GetAllEstablecimientos._embedded);
    })();
    (async () => {
      const GetAllEstados = await getListV2(empresa, "GetEstados");
      setEstados(GetAllEstados._embedded);
    })();
    (async () => {
      const GetAllTipoDoc = await getListV2(empresa, "BuscarTiposDocumento");
      if (GetAllTipoDoc == null) return;
      setTipoDocumento(GetAllTipoDoc);
    })();
  }, []);

  const handleChangeSearchReportes = (e) => {
    if (e.target.name === "secuencial") {
      if (e.target.value.length > 18) {
        return e.target.value;
      }
    }

    if (e.target.name === "cliente") {
      if (e.target.value.length > 20) return e.target.value;
    }

    formik.handleChange(e);
  };

  return (
    <>
      <h2 className="my-6 pl-4 text-2xl font-semibold text-gray-700 dark:text-gray-200">
        Reporte de documentos recibidos
      </h2>
      <hr className="border-1 dark:border-gray-800 " />
      <div className="px-4 py-3  ">
        <form
          onSubmit={(e) => {
            setPaginacion(initialStatePaginacion);
            formik.handleSubmit(e);
            setStart(0);
          }}
        >
          <div className="grid md:grid-cols-5 mb-2 md:gap-2 mt-6">
            <div className="relative z-0 lg:mb-0 mb-6  w-full group">
              <input
                type="date"
                className={StyleInput}
                name="fechaInicio"
                id="fechaInicio"
                value={formik.values.fechaInicio}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder=" "
              />

              <label
                htmlFor="fechaInicio"
                className="peer-focus:font-semibold absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
              >
                Fecha inicio
              </label>
            </div>
            <div className="relative z-0 lg:mb-0 mb-6  w-full group">
              <input
                type="date"
                name="fechaFin"
                id="fechaFin"
                value={formik.values.fechaFin}
                onChange={formik.handleChange}
                className={StyleInput}
                placeholder=" "
              />

              <label
                htmlFor="fechaFin"
                className="peer-focus:font-semibold absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
              >
                Fecha fin
              </label>
            </div>

            {/* ESTADO */}

            <div className="relative z-0 lg:mb-0 mb-6  w-full group">
              <select
                type="text"
                name="TipoDocumento"
                id="TipoDocumento"
                className={StyleSelect}
                placeholder=" "
                defaultValue={formik.values.estado}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option key="00" value="">
                  TODOS
                </option>
                {TipoDocumento.map((el) => {
                  return (
                    <option key={el.idTipoDocumento} value={el.codigo}>
                      {el.documento}
                    </option>
                  );
                })}
              </select>

              <label
                htmlFor="estado"
                className="peer-focus:font-semibold absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
              >
                Tipo doc.
              </label>
            </div>
            <div className="relative z-0 lg:mb-0 mb-6  w-full group">
              <select
                type="select"
                className={StyleSelect}
                placeholder=" "
                id="tipoFecha"
                defaultValue={formik.values.tipoFecha}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                  <option key="1" value="1">
                    Fecha emisión
                  </option>
                  <option key="2" value="2">
                    Fecha autorizacion
                  </option>
                  <option key="3" value="3">
                    Fecha registro
                  </option>
              </select>
              <label
                htmlFor="tipoFecha"
                className="peer-focus:font-semibold absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
              >
                Tipo Busqueda
              </label>
              {/* <label
                htmlFor="tipoFecha"
                className={
                  formik.touched.tipoFecha && formik.errors.tipoFecha
                    ? StyleLabelFormError
                    : StyleLabelForm
                }
              >
                TIPO
              </label>
              {formik.touched.tipoFecha && formik.errors.tipoFecha ? (
                <MesageError message={formik.errors.tipoFecha} />
              ) : null} */}
            </div>
          </div>
          <button type="submit" className={ButtonStyleDefault + " mt-2 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 "}>
            {cargandoData ? (
              <>
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline mr-3 w-4 h-4 animate-spin text-white"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                CARGANDO...
              </>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="-ml-0.5 mr-2 h-5 w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
                BUSCAR
              </>
            )}
          </button>

          {/* <button
            onClick={handleDownloadEXCEL}
            type="button"
            className={ButtonStyleDefault + ' bg-gray-500 hover:bg-green-600 focus:ring-gray-500'}
          >
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="-ml-0.5 mr-2 h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>
              Descargar Excel
            </>
          </button> */}

          {/* <button
            onClick={handleDownloadReporteDetallado}
            type="button"
            className={ButtonStyleDefault + ' bg-gray-500 hover:bg-green-600 focus:ring-gray-500'}
          >
            <>
             
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="-ml-0.5 mr-2 h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                />
              </svg>
              Reporte Detallado
            </>
          </button> */}
        </form>
      </div>
      <hr className="border-1  dark:border-gray-800" />
    </>
  );
};

export default FormFactura;
