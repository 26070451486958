import axios from "axios";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { LargeModalForm } from "../../../features/components/LargeModalForm";
import { PostElement } from "../../../services";
import { rellenarCeros } from "../../../services/Utilitario";
import { ReporteFacturaContext } from "./ReporteFacturaProvider";

const ModalAnular = () => {
  const { setAnularElement, AnularElement, formik } = useContext(
    ReporteFacturaContext
  );
  const [IsLoading, setIsLoading] = useState(false);

  const empresa = useSelector((store) => store.empresa.empresa);

  const handleSubmitModal = async () => {
    setIsLoading(true);
    await PostElement(empresa, "AnularDocumento", {
      idDocumentoElectronico: AnularElement.idDocumentoElectronico,
    });
    document.getElementById("anularFacturaModal").click();
    setIsLoading(false);
    formik.handleSubmit(formik.values);
  };

  const getDocType = (codigoTipoDocumento) => {
    switch (codigoTipoDocumento) {
      case "01":
        return "Factura";

      case "07":
        return "Comprobante de Retencion";

      case "04":
        return "Nota de Crédito";

      case "05":
        return "Nota de Debito";

      case "06":
        return "Guia de Remision";

      case "03":
        return "Liquidacion de Compra";

      default:
        return codigoTipoDocumento;
    }
  };

  return (
    <LargeModalForm
      buttonModal={"anularFacturaModal"}
      title="ANULAR DOCUMENTO"
      IsLoading={IsLoading}
      submitText="ANULAR"
      submitModal={handleSubmitModal}
    >
      {AnularElement && (
        <div>
          <div>
            <p>
              <b>Tipo de comprobante:</b>{" "}
              {getDocType(AnularElement.codigoTipoDocumento)} -{" "}
              {`${rellenarCeros(
                AnularElement.establecimiento,
                3
              )}-${rellenarCeros(
                AnularElement.puntoEmision,
                3
              )}-${rellenarCeros(AnularElement.secuencial, 9)}`}
            </p>
            <p>
              <b>Clave de acceso:</b>{" "}
              {AnularElement.claveAcceso}
            </p>
            <p>
              <b>Numero de autorizacion:</b>{" "}
              {AnularElement.claveAcceso}
            </p>
            <p>
              <b>Fecha emisión:</b>{" "}
              {moment(AnularElement.fechaCreacion).format(
                "D/MM/YYYY"
              )}
            </p>
            <p>
              <b>Contribuyente:</b>{" "}
              {AnularElement.idContribuyenteNavigation.razonSocial} -{" "}
              {AnularElement.idContribuyenteNavigation.identificacion}
            </p>
            <p>
              <b>Fecha Autorizacion:</b>{" "}
              {moment(AnularElement.fechaAutorizacion).format(
                "D/MM/YYYY"
              )}
            </p>
            <p>
              <b>Importe Total:</b> ${AnularElement.importeTotal}
            </p>
          </div>
          <div className=" my-3">
            {/* <span className="text-xl text-red-600">
              Esta anulación es solo dentro del ambiente del facturador web,
              proceder a anular aqui previamente se haya anulado en el SRI.
            </span> */}
            <div className="rounded-md bg-yellow-50 dark:bg-gray-800 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div className="ml-3">
                  <h3 className="font-medium text-yellow-800 dark:text-gray-400">
                    Advertencia
                  </h3>
                  <div className="mt-2 text-sm text-yellow-700 dark:text-gray-400">
                    <p>
                      Esta opción permite cambiar el <b>ESTADO</b> de su comprobante de
                      {" "}<b>AUTORIZADO</b> a <b>ANULADO</b> en el sistema de facturación
                      electrónica WEB de <b>IntuitoSA</b>, recordar que toda anulación
                      de comprobante previamente se debe realizar el sistema del{" "}
                      <b>SRI</b>, en el siguiente link tendra un manual para realizar
                      la anulación en el <b>SRI</b>.
                      <a className="flex align-middle justify-center flex-col my-5 mx-auto text-base" href="https://drive.google.com/file/d/1HBe3Ez00bqime1kEQ4BU1-3z-dQQcMk3/view?usp=share_link" target="_blank" rel="noreferrer"><b>Ver PDF.</b></a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </LargeModalForm>
  );
};

export default ModalAnular;
