
import TransferenciasProvider from "./Context/TransferenciasProvider";
import ModalTransferencias from "./ModalTransferencias";
import TransferenciasForm from "./TransferenciasForm";

export default function Transferencias() {

  return (
    <>
      <TransferenciasProvider>
          <TransferenciasForm />
          <ModalTransferencias />
      </TransferenciasProvider>
    </>
  );
}
