export const ImpuestosMap = {
  2: {
    idImpuestoDetalle: "A5B8F607-8D38-4150-97AF-C591F83678D8",
    idImpuesto: 1,
    detalle: "IVA12",
    porcentaje: 0.12,
    codigo: 2,
    observaciones: "IVA 12",
    activo: true,
  },
  8: {
    idImpuestoDetalle: "20FA15DF-58A0-4546-9DB1-BEE1CCCACF3E",
    idImpuesto: 1,
    detalle: "IVA 8",
    porcentaje: 0.08,
    codigo: 8,
    observaciones: "IVA 8",
    activo: true,
  },
  7: {
    idImpuestoDetalle: "F03C7F10-2773-4505-ACCB-B2FA88D62049",
    idImpuesto: 1,
    detalle: "Exento IVA",
    porcentaje: 0,
    codigo: 7,
    observaciones: "Exento IVA",
    activo: true,
  },
  3: {
    idImpuestoDetalle: "8DCD2CFA-5F9D-4FB7-B27A-87447D6CDDE7",
    idImpuesto: 1,
    detalle: "IVA 14",
    porcentaje: 0.14,
    codigo: 3,
    observaciones: "IVA 14",
    activo: true,
  },
  0: {
    idImpuestoDetalle: "81BAF871-E113-48CF-8AA8-670CB43494B6",
    idImpuesto: 1,
    detalle: "IVA 0",
    porcentaje: 0,
    codigo: 0,
    observaciones: "IVA 0",
    activo: true,
  },
  6: {
    idImpuestoDetalle: "8E7CF164-C66B-4312-945F-57AE08C86A33",
    idImpuesto: 1,
    detalle: "NO Objeto de impuesto",
    porcentaje: 0,
    codigo: 6,
    observaciones: "NO Objeto de impuesto",
    activo: true,
  },
  4: {
    idImpuestoDetalle: "BED68281-CF9C-458E-9E0F-517A8FB02528",
    idImpuesto: 1,
    detalle: "IVA 15",
    porcentaje: 0.15,
    codigo: 4,
    observaciones: "IVA 15",
    activo: true,
  },
  5
: {
    idImpuestoDetalle: "2ED52B34-BA6C-475B-A82D-0F7C933412A7",
    idImpuesto: 1,
    detalle: "IVA 5",
    porcentaje: 0.05,
    codigo: 5,
    observaciones: "IVA 5",
    activo: true,
  },
  10: {
    idImpuestoDetalle: "47BFE2B8-3630-4F76-8727-2D6808B862AC",
    idImpuesto: 1,
    detalle: "IVA 13",
    porcentaje: 0.13,
    codigo: 10,
    observaciones: "IVA 13",
    activo: true,
  },
};

export const FormaPagoMap = {
  "19": {
    IdFormaPagoSri: "306D23CC-4F49-EC11-873C-00155D007100",
    Codigo: "19",
    Descripcion: "TARJETA DE CRÉDITO",
    Activo: 1,
    Prioridad: 3,
  },
  "20": {
    IdFormaPagoSri: "316D23CC-4F49-EC11-873C-00155D007100",
    Codigo: "20",
    Descripcion: "OTROS CON UTILIZACION DEL SISTEMA FINANCIERO",
    Activo: 1,
    Prioridad: 1,
  },
  "21": {
    IdFormaPagoSri: "326D23CC-4F49-EC11-873C-00155D007100",
    Codigo: "21",
    Descripcion: "ENDOSO DE TÍTULOS",
    Activo: 1,
    Prioridad: 4,
  },
  "01": {
    IdFormaPagoSri: "3B6D23CC-4F49-EC11-873C-00155D007100",
    Codigo: "01",
    Descripcion: "SIN UTILIZACION DEL SISTEMA FINANCIERO",
    Activo: 1,
    Prioridad: 2,
  },
  "15": {
    IdFormaPagoSri: "3C6D23CC-4F49-EC11-873C-00155D007100",
    Codigo: "15",
    Descripcion: "COMPENSACIÓN DE DEUDAS",
    Activo: 0,
    Prioridad: 9,
  },
  "16": {
    IdFormaPagoSri: "3D6D23CC-4F49-EC11-873C-00155D007100",
    Codigo: "16",
    Descripcion: "TARJETA DE DÉBITO",
    Activo: 0,
    Prioridad: 5,
  },
  "17": {
    IdFormaPagoSri: "3E6D23CC-4F49-EC11-873C-00155D007100",
    Codigo: "17",
    Descripcion: "DINERO ELECTRÓNICO",
    Activo: 0,
    Prioridad: 6,
  },
  "18": {
    IdFormaPagoSri: "3F6D23CC-4F49-EC11-873C-00155D007100",
    Codigo: "18",
    Descripcion: "TARJETA PREPAGO",
    Activo: 0,
    Prioridad: 8,
  },
  "19": {
    IdFormaPagoSri: "306D23CC-4F49-5122-873C-00155D007100",
    Codigo: "19",
    Descripcion: "DINERO CASH",
    Activo: 1,
    Prioridad: 7,
  },
};
