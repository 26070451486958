import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ButtonStyleDefault,
  cargando,
  customStyles,
  sinDatos,
  StyleInput,
  StyleSelectAsync,
} from "../../../features/Constantes";
import { useFormik } from "formik";
import * as Yup from "yup";
import debounce from "lodash.debounce";
import { getList, getListV2 } from "../../../services";
import { useSelector } from "react-redux";
import { EmitirFacturaContext } from "./EmitirFacturaProvider";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import ListaDetalle from "./ListaDetalle";
import { SectionFilter } from "../../../features/components/SectionFilter";
import { Transition } from "@headlessui/react";

const FormBuscarDetalle = () => {
  const empresa = useSelector((store) => store.empresa.empresa);

  /*const [BuscarNombre, setBuscarNombre] = useState(false);
    const [BuscarCodigo, setBuscarCodigo] = useState(false);
    const [BuscarCodigoLupa, setBuscarCodigoLupa] = useState(false);*/
  const permisos = useSelector((store) => store.permisosEmpresa.permisos);
  const [ItemDetalle, setItemDetalle] = useState({});
  const [changeDetalle, setChangeDetalle] = useState("");
  const [IsLoading, setIsLoading] = useState(false);
  const { setDetalleList, DetalleList } = useContext(EmitirFacturaContext);
  const selectAssyncRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      cantidad: 1,
      nombre: "",
      codigo: "",
    },
    validationSchema: Yup.object({
      cantidad: Yup.number()
        .min(1, "La cantidad no puede ser menor o igual a 0")
        .required("Campo Requerido"),
    }),

    onSubmit: async (obj) => {
      setIsLoading(true);
      const verifyItem =
        ItemDetalle !== undefined
          ? ItemDetalle.codigoPrincipal
            ? true
            : false
          : undefined;
      if (verifyItem) {
        if (ItemDetalle.descuento === null) ItemDetalle.descuento = 0;
        if (ItemDetalle.descuento === undefined) ItemDetalle.descuento = 0;
        ItemDetalle.cantidad = obj.cantidad;
        if (ItemDetalle.idImpuestoIvaNavigation) {
          setDetalleList([{ ...ItemDetalle }, ...DetalleList]);
        } else {
          toast.error("Producto sin IVA");
        }
        formik.resetForm();
        selectAssyncRef.current.setValue([], "clear");
        setItemDetalle({});
        setChangeDetalle("");
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      } else {
        toast.info("Asegurate de ingresar el código del producto o servicio", {
          autoClose: 2000,
          closeOnClick: true,
        });
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    },
  });

  useEffect(() => {
    formik.validateForm();
  }, [ItemDetalle]);

  const handleChangeCantidad = (e) => {
    if (e.target.value.length >= 10) {
      return e.target.value;
    }
    if (e.target.value.match(/^\d{0,}(\.\d{0,6})?$/)) {
      formik.handleChange(e);
      return e.target.value;
    }
  };

  const buscarServicio = async (busquedad) => {
    const parametroCodificado = encodeURIComponent(busquedad);
    let res = await getList(
      `api/SearchReportesServicios/ObtenerServicios?busquedad=${parametroCodificado}&idEmpresa=${empresa.idEmpresa}&tipoBusqueda=codigo`
    );
    return res;
  };

  const loadOptions = React.useCallback(
    debounce((inputText, callback) => {
      buscarServicio(inputText).then((options) => callback(options));
    }, 400),
    []
  );
  const [options, setOptions] = useState([]);

  const servicesCount = async () => {
    let res = await getList(
      `api/SearchReportesServicios/servicios/${empresa.idEmpresa}?codigoPrincipal=&start=0&descripcion=&Estado=True&cantidad=3&tipo=0&Pagina=1&Cantidad=10`
    );
    setOptions(res._embedded);
  };

  // const listaEmpresas = options.map((e) => {
  //   return {
  //     idServicio: e.idServicio,
  //     codigoPrincipal: e.codigoPrincipal,
  //     descripcion: e.descripcion,
  //     precioUnitario: e.precioUnitario,
  //     idImpuestoIvaNavigation: e.idImpuestoIvaNavigation,

  //   };
  // });

  // const listaEmpresas = [
  //   {
  //     idServicio: 1,
  //     codigoPrincipal: "001",
  //     descripcion: "Producto 1",
  //     precioUnitario: 10,
  //   },
  //   {
  //     idServicio: 2,
  //     codigoPrincipal: "002",
  //     descripcion: "Producto 2",
  //     precioUnitario: 20,
  //   },
  //   {
  //     idServicio: 3,
  //     codigoPrincipal: "003",
  //     descripcion: "Producto 3",
  //     precioUnitario: 30,
  //   },
  //   // Puedes agregar más elementos aquí si es necesario
  // ];

  useEffect(() => {
    servicesCount();
  }, []);

  const valorSeleccionado = async (valor) => {
    setItemDetalle(valor);
    //console.log(valor);
  };

  const [isOpen, setIsOpen] = useState(true);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-wrap justify-between flex-col lg:px-0 md:px-0 px-1">
        <div className="border rounded-lg  border-gray-300 dark:border-gray-600 w-full">
          <h3 className="lg:flex md:flex flex justify-between items-center  text-sm py-1.5 px-2 text-left bg-[#003B5B]  dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-t-lg font-medium leading-6 text-white">
           

            <div className="group relative flex">
            Productos / Servicios
            {permisos.find((el) => el.codigo === "23") ? (
              <button
                data-bs-toggle="modal"
                data-bs-target="#addProductoModal"
                type="button"
                className={
                  " bg-white hover:bg-gray-50 hover:text-white focus:ring-gray-500 ml-2 rounded-md px-1 py-1 flex justify-center align-middle"
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline w-5 h-5 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
            ) : (
              <></>
            )}
            <div className="absolute mx-auto w-32 z-50 -top-8 -left-4 hidden dark:bg-white dark:text-black     rounded-md bg-gray-800 group-hover:flex text-center p-1">
              <p className="text-white dark:text-black mx-auto text-sm">
                Crear Producto{" "}
              </p>
            </div>
          </div>
            <svg
              onClick={() => setIsOpen(!isOpen)}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className={`w-4 h-4 lg:hidden md:hidden duration-150  ${isOpen ? "transform rotate-180" : ""}`}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </h3>
          <Transition
            show={isOpen}
            enter="transition ease-out duration-300" // Aumenta la duración de entrada
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-300" // Aumenta la duración de salida
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="lg:flex lg:flex-row md:flex md:flex-row  py-4 px-4 flex-col gap-3 justify-center   ">
              <div className="basis-[60%] relative my-auto lg:w-[60%] md:w-[60%] ">
                <label className="block text-[0.813rem] font-medium text-black mb-4 dark:text-white"></label>

                <AsyncSelect
                  placeholder="Digite el nombre del Productos / Servicios"
                  noOptionsMessage={() => sinDatos}
                  styles={customStyles}
                  className={StyleSelectAsync}
                  loadOptions={loadOptions}
                  defaultOptions={options}
                  getOptionLabel={(e) =>
                    e.codigoPrincipal +
                    " - " +
                    e.descripcion +
                    ` -  $${e.precioUnitario}`
                  }
                  getOptionValue={(e) => e.idServicio}
                  defaultInputValue={""}
                  loadingMessage={() => cargando}
                  onChange={(e) => {
                    valorSeleccionado(e);
                    setChangeDetalle(e);
                  }}
                  id="producto"
                  name="producto"
                  ref={selectAssyncRef}
                  value={changeDetalle}
                  options={options}
                />
              </div>
              <div className="basis-[15%] my-auto">
                <label className="block text-[0.813rem] font-medium text-black dark:text-white">
                  {" "}
                  Cantidad:{" "}
                </label>
                <input
                  type="text"
                  className={StyleInput}
                  placeholder=" "
                  id="cantidad"
                  name="cantidad"
                  value={formik.values.cantidad}
                  onChange={handleChangeCantidad}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="basis-1/4 mt-1 ">
                <button
                  type="submit"
                  className=" inline-block mt-3 text-white bg-blue-600 dark:bg-gray-700 w-full lg:w-28 dark:border-none  hover:bg-blue-700  font-semibold p-2 text-[0.813rem] border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:shadow-outline-grayduration-150
              ease-in-out"
                  onClick={formik.handleSubmit}
                  disabled={IsLoading}
                >
                  {IsLoading ? (
                    "Cargando..."
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="inline mr-1   -ml-1 w-5 h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      AGREGAR
                    </>
                  )}
                </button>
              </div>
            </div>
            <div className="px-4 py-2 lg:block md:block   ">
              <ListaDetalle />
            </div>
          </Transition>
        </div>
      </div>
    </form>
  );
};

export default FormBuscarDetalle;
