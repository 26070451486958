import { useContext } from "react";
import ModalComponent from "../../../../../features/components/ModalComponents/ModalComponent";
import { TransferenciasProviderContext } from "./Context/TransferenciasProvider";
export default function ModalTransferencias() {
  const { setIsOpen, isOpen } = useContext(TransferenciasProviderContext);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ModalComponent dimensiones="mediano" isOpen={isOpen} onClose={toggleModal}>
        <div className="">
          <p>La transacción se genero exitosamente</p>
        </div>
      </ModalComponent>
    </>
  );
}
