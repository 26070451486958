import { CrearBodegaForm } from "./CrearBodegaForm";
import { CrearBodegaList } from "./CrearBodegaList";
import { useSelector } from "react-redux";
import { ModalStatusBodega } from "./modalCrearBodega.slice";
import { setModalBase } from "../../Shared/ModalBase";
import { ModalEditarBodega } from "./ModalEditarBodega";
import { ModalEliminarBodega } from "./ModalEliminarBodega";
import { BodegaProvider } from "./context/BodegasContext";

export const CrearBodega = () => {
  const { modalStatus, registroAfectado } = useSelector(
    (state) => state.modalBodega
  );

  return (
    <BodegaProvider>
      <section className="w-full h-full flex flex-col gap-10">
        <CrearBodegaForm />
        <CrearBodegaList />
      </section>
      {modalStatus === ModalStatusBodega.editar &&
        registroAfectado &&
        setModalBase(ModalEditarBodega)}
      {modalStatus === ModalStatusBodega.eliminar &&
        registroAfectado &&
        setModalBase(ModalEliminarBodega)}
    </BodegaProvider>
  );
};
