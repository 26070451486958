import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { EmitirGuiaRemisionContext } from "./EmitirGuiaRemisionProvider";

import {
  StyleInput,
  StyleInputError,
  StyleLabel,
  StyleLabelError,
} from "../../../features/Constantes";
import { LargeModalForm } from "../../../features/components/LargeModalForm";
import { useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { getListV2 } from "../../../services";
import MesageWarningTable from "../../../features/components/MesageWarningTable";
import moment from "moment";

const DestinatarioModal = () => {
  const { setDestinatariosList, DestinatariosList } = useContext(
    EmitirGuiaRemisionContext
  );

  const [IsLoading, setIsLoading] = useState(false);
  const [ErrorSecuencialState, setErrorSecuencialState] = useState(0);
  const [InfoDoc, setInfoDoc] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    setValue("secuencialComprobante", "000-000-000000000");
  }, []);

  const empresa = useSelector((store) => store.empresa.empresa);

  const renderMessage = (val) => {
    switch (val) {
      case 1:
        return (
          <p className=" text-green-500 text-sm transform -translate-y-5 scale-75 top-5 -z-10 origin-[0]">
            El comprobante ingresado es correcto.
          </p>
        );

      case 2:
        return (
          <p className=" text-yellow-500 text-sm transform -translate-y-5 scale-75 top-5 -z-10 origin-[0]">
            El comprobante ingresado existe, pero no esta autorizado.
          </p>
        );

      case 3:
        return (
          <p className=" text-red-600 text-sm transform -translate-y-5 scale-75 top-5 -z-10 origin-[0]">
            El comprobante ingresado no existe o no esta autorizado.
          </p>
        );

      default:
        break;
    }
  };

  const handleSubmitModal = async () => {
    try {
      setIsLoading(true);
      let datas = getValues().secuencialComprobante.split("-");
      let res = await getListV2(
        empresa,
        "GetDetallesBySecuencial",
        `?Establecimiento=${Number(datas[0])}&PuntoEmision=${Number(
          datas[1]
        )}&Secuencial=${Number(datas[2])}`
      );

      if (res !== "" && res !== undefined) {
        let valores = {
          CodDocSustento: "01",
          NumDocSustento: getValues().secuencialComprobante,
          NumAutDocSustento: res.docElectronico.documentoElectronicoDetalle[0]
            ? res.docElectronico.documentoElectronicoDetalle[0]
                .numeroAutorizacion
            : null,
          FechaEmisionDocSustento: moment(res.factura.fechaEmision).format(
            "YYYY-MM-DD"
          ),
          DirDestinatario: res.factura.idContribuyenteNavigation.direccion,
          MotivoTraslado: getValues().motivoTraslado,
          Ruta: getValues().rutaComprobante,
          IdentificacionDestinatario: res.factura.identificacionCliente,
          RazonSocialDestinatario:
            res.factura.idContribuyenteNavigation.razonSocial,
          CodEstabDestino: getValues().establecimientoDestino,
          DocAduaneroUnico: getValues().DocumentoAduaneroComprobante,
          detalles: [...res.factura.detalleFactura],
        };

        setDestinatariosList([...DestinatariosList, valores]);
        handleCloseModal();
        if (
          res.docElectronico.idEstadoDocumentoElectronico.toUpperCase() !==
          "1777AA9D-FA14-49BD-B9F0-ECE6D2952756"
        ) {
          setErrorSecuencialState(2);
        } else {
          setErrorSecuencialState(1);
        }
      }
    } catch (ex) {
     // console.log(ex);
      setErrorSecuencialState(3);
    } finally {
      setIsLoading(false);
      document.getElementById("destinatarioGuiaRemisionModal").click();
    }
  };

  const handleComprobarComprobante = async () => {
    try {
      let datas = getValues().secuencialComprobante.split("-");
      let res = await getListV2(
        empresa,
        "GetDetallesBySecuencial",
        `?Establecimiento=${Number(datas[0])}&PuntoEmision=${Number(
          datas[1]
        )}&Secuencial=${Number(datas[2])}`
      );
      setInfoDoc({
        TipoDoc: "Factura",
        fechaEmision: res.factura.fechaEmision,
        Contribuyente: res.factura.idContribuyenteNavigation.razonSocial,
        ImporteTotal: res.factura.importeTotal,
      });
      if (res !== "" && res !== undefined) {
        if (
          res.docElectronico.idEstadoDocumentoElectronico.toUpperCase() !==
          "1777AA9D-FA14-49BD-B9F0-ECE6D2952756"
        ) {
          setErrorSecuencialState(2);
        } else {
          setErrorSecuencialState(1);
        }
      }
    } catch (ex) {
    //  console.log(ex);
      setErrorSecuencialState(3);
    }
  };

  const handleCloseModal = () => {
    setValue("secuencialComprobante", "");
    setValue("DestinoComprobante", "");
    setValue("motivoTraslado", "");
    setValue("rutaComprobante", "");
    setValue("DocumentoAduaneroComprobante", "");
    setValue("establecimientoDestino", "");
    setInfoDoc(false);
  };

  return (
    <LargeModalForm
      onClose={handleCloseModal}
      buttonModal={"destinatarioGuiaRemisionModal"}
      IsLoading={IsLoading}
      submitText={"Agregar"}
      title="AGREGAR DESTINATARIO"
      submitModal={handleSubmit(handleSubmitModal)}
    >
      <div className="w-full mb-2 md:mb-0 mt-5 grid md:grid-cols-3 md:gap-6">
        <div className="w-full col-span-2">
          <NumberFormat
            format="###-###-#########"
            id="secuencialComprobante"
            name="secuencialComprobante"
            placeholder=" "
            className={
              errors.secuencialComprobante ? StyleInputError : StyleInput
            }
            {...register("secuencialComprobante", {
              required: true,
            })}
            onChange={(e) => setValue("secuencialComprobante", e.target.value)}
            value={getValues().secuencialComprobante}
          />
          <label
            htmlFor="secuencialComprobante"
            className={
              errors.secuencialComprobante ? StyleLabelError : StyleLabel
            }
          >
            Secuencial comprobante
          </label>
          {renderMessage(ErrorSecuencialState)}
        </div>
        <div className="mb-2 w-full group">
          <button
            onClick={handleComprobarComprobante}
            type="button"
            className="  text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center dark:bg-green-600 dark:focus:ring-green-800 dark:hover:bg-green-700"
          >
            COMPROBAR
          </button>
        </div>
      </div>
      <div className="overflow-x-auto  relative overflow-hidden shadow ring-1 ring-black  ring-opacity-5 md:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase divide-y bg-gray-100 dark:bg-gray-600 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-1 px-6 text-center">
                Tipo documento
              </th>
              <th scope="col" className="py-1 px-6 text-center">
                Fecha de Emisión
              </th>
              <th scope="col" className="py-1 px-6 text-center">
                Contribuyente
              </th>
              <th scope="col" className="py-1 px-6 text-center">
                Importe total
              </th>
            </tr>
          </thead>
          {InfoDoc ? (
            <tbody className="">
              <tr className="bg-white dark:bg-gray-800  border-b-gray-600">
                <td className="py-2 px-6 text-center">{InfoDoc.TipoDoc}</td>
                <td className="py-2 px-6 text-center">
                  {moment(InfoDoc.fechaEmision).format("ll")}
                </td>
                <td className="py-2 px-6 text-center">
                  {InfoDoc.Contribuyente}
                </td>
                <td className="py-2 px-6 text-center">
                  ${InfoDoc.ImporteTotal}
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody className="">
              <MesageWarningTable
                className="py-1"
                colSpan="4"
                message="Ingrese un comprobante"
              />
            </tbody>
          )}
        </table>
      </div>
      <div className="relative mt-2 z-0 w-full group">
        <label
          htmlFor="company-website"
          className="block text-sm font-medium text-black dark:text-white"
        >
          {" "}
          Destino{" "}
        </label>
        <input
          type="text"
          className={errors.DestinoComprobante ? StyleInputError : StyleInput}
          placeholder=" "
          id="DestinoComprobante"
          name="DestinoComprobante"
          {...register("DestinoComprobante", {
            required: {
              value: true,
              message: "Nombre es requerido",
            },
            maxLength: {
              value: 300,
              message: "No más de 300 carácteres!",
            },
            minLength: {
              value: 4,
              message: "Mínimo 4 carácteres",
            },
          })}
          maxLength="300"
        />
      </div>
      <div className="w-full mt-2 group flex justify-around">
        <div className="relative z-0  mb-2 w-1/2 group">
          <label
            htmlFor="company-website"
            className="block text-sm font-medium text-black dark:text-white"
          >
            Motivo Traslado xd
          </label>
          <input
            type="text"
            className={errors.MotivoTraslado ? StyleInputError : StyleInput}
            placeholder=" "
            id="motivoTraslado"
            name="motivoTraslado"
            {...register("motivoTraslado", {
              required: {
                value: true,
                message: "Nombre es requerido",
              },
              maxLength: {
                value: 300,
                message: "No más de 300 carácteres!",
              },
              minLength: {
                value: 4,
                message: "Mínimo 4 carácteres",
              },
            })}
            maxLength="300"
          />
        </div>
        <div className="relative z-0 ml-4 w-1/2 group">
          <label
            htmlFor="company-website"
            className="block text-sm font-medium text-black dark:text-white"
          >
            {" "}
            Ruta{" "}
          </label>
          <input
            type="text"
            className={errors.rutaComprobante ? StyleInputError : StyleInput}
            placeholder=" "
            id="rutaComprobante"
            name="rutaComprobante"
            {...register("rutaComprobante", {
              required: {
                value: true,
                message: "Nombre es requerido",
              },
            })}
            maxLength="300"
          />
        </div>
      </div>
      <div className="relative z-0 mt-1 w-full group">
        <label
          htmlFor="company-website"
          className="block text-sm font-medium text-black dark:text-white"
        >
          {" "}
          Documento Aduanero - (Opcional){" "}
        </label>
        <input
          type="text"
          className={
            errors.DocumentoAduaneroComprobante ? StyleInputError : StyleInput
          }
          placeholder=" "
          id="DocumentoAduaneroComprobante"
          name="DocumentoAduaneroComprobante"
          {...register("DocumentoAduaneroComprobante", {
            maxLength: {
              value: 20,
              message: "No más de 20 carácteres!",
            },
            minLength: {
              value: 4,
              message: "Mínimo 4 carácteres",
            },
          })}
          maxLength="20"
        />
      </div>

      <div className="relative z-0 mt-3 w-25 sm:w-full group">
        <label
          htmlFor="company-website"
          className="block text-sm font-medium text-black dark:text-white"
        >
          {" "}
          Establecimiento Destino - (Opcional){" "}
        </label>
        <NumberFormat
          format="###"
          id="establecimientoDestino"
          name="establecimientoDestino"
          {...register("establecimientoDestino", {})}
          onChange={(e) => setValue("establecimientoDestino", e.target.value)}
          value={getValues().establecimientoDestino}
          placeholder=" "
          className={
            errors.establecimientoDestino ? StyleInputError : StyleInput
          }
        />
      </div>
    </LargeModalForm>
  );
};

export default DestinatarioModal;
