import React, { useContext, useEffect, useState } from "react";
import { LargeModalForm } from "../../../../features/components/LargeModalForm";
import { EstablecimientosContext } from "../EstablecimientosProvider";
import {
  getSecuencialesByPuntoEmision,
  submitSecuencialesByPuntoEmision,
} from "./services";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import {
  StyleSelect,
  StyleLabel,
  StyleInput,
  StyleInputError,
  StyleLabelError,
} from "../../../../features/Constantes";
const ModalSecuencial = () => {
  const { idPTESecuencial, setidPTESecuencial } = useContext(
    EstablecimientosContext
  );

  const { register, setValue, handleSubmit, reset, getValues } = useForm();

  const [IsLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (!idPTESecuencial) return;
    getSecuencialesByPuntoEmision(idPTESecuencial)
      .then((el) => {
        // console.log(el)
        reset({
          ...el,
        });
      })
      .finally(() => setIsLoading(false));
  }, [idPTESecuencial]);

  const handleSubmitSecuencial = (e) => {
    try {
      var idToast = toast.loading("Editando Espere...");
      let data = Object.values(e).map((el) => {
        return {
          Codigo: el.Codigo,
          idSecuencial: el.idSecuencial,
        };
      });

      submitSecuencialesByPuntoEmision(data).then((el) => {
        // console.log(el.status);
        if (el.status === 200) {
          toast.update(idToast, {
            render: "Secuencial actualizado con exito!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        }
      });
    } catch (ex) {
      console.error(ex);
    }
  };

  return (
    <LargeModalForm
      IsLoading={false}
      buttonModal={"editarSecuenciales"}
      submitText={"Guardar"}
      title="EDITAR SECUENCIALES"
      submitModal={handleSubmit(handleSubmitSecuencial)}
    >
   <div className="my-4">


      {IsLoading && 
      <div className="flex justify-center items-center">

<svg
                        className="h-9 w-9 mr-1 text-blue-700 animate-spin  "
                        viewBox="0 0 32 32"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>spinner-one-third</title>
                        <path d="M16 0.75c-0.69 0-1.25 0.56-1.25 1.25s0.56 1.25 1.25 1.25v0c7.042 0.001 12.75 5.71 12.75 12.751 0 3.521-1.427 6.709-3.734 9.016v0c-0.226 0.226-0.365 0.538-0.365 0.883 0 0.69 0.56 1.25 1.25 1.25 0.346 0 0.659-0.14 0.885-0.367l0-0c2.759-2.76 4.465-6.572 4.465-10.782 0-8.423-6.828-15.251-15.25-15.251h-0z"></path>
                      </svg>
        </div>}
      <form action="  " >
        <div className="grid md:grid-cols-2  md:gap-4 gap-3  max-w-xl mx-auto  ">
          <p className="text-base font-body dark:text-white">Facturas</p>
          <input
            type="number"
            className={StyleInput}
            {...register("Factura.Codigo")}
          />

<p className="text-base font-body dark:text-white">Comprobante De Retención</p>
          <input
            className={StyleInput}
            type="number"
            {...register("ComprobanteRetencion.Codigo")}
          />

<p className="text-base font-body dark:text-white">Guía Remisión</p>
          <input
            className={StyleInput}
            type="number"
            {...register("GuiaRemision.Codigo")}
          />

<p className="text-base font-body dark:text-white">Liquidación de compra</p>
          <input
            className={StyleInput}
            type="number"
            {...register("LiquidacionCompra.Codigo")}
          />

<p className="text-base font-body dark:text-white">Nota de crédito</p>
          <input
            className={StyleInput}
            type="number"
            {...register("NotaCredito.Codigo")}
          />

<p className="text-base font-body dark:text-white">Nota de débito</p>
          <input
            className={StyleInput}
            type="number"
            {...register("NotaDebito.Codigo")}
          />
        </div>
      </form>
      </div>
      {/* <button onClick={() => console.log(JSON.stringify({...getValues()}))}>Log That MDFK</button> */}
    </LargeModalForm>
  );
};

export default ModalSecuencial;
