import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import EmisionElectronica from "./EmisionElectronica";
import Admin from "./Admin";
import TutorialesModal from "./TutorialesModal";
import ReportesMenu from "./ReportesMenu";
import SRI from "./SRI";
import Suscripciones from "./Suscripciones";
import { InventarioOption } from "./InventarioOption";

const MenuGeneral = (props) => {
  const permisos = useSelector((store) => store.permisosEmpresa.permisos);

  const [reporteEmison, setReporteEmison] = useState([]);
  const [emisionelectronica, setEmisionelectronica] = useState([]);
  const [Inventario, setInventario] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [administracion, setAdministracion] = useState([]);

  const jsonpermiso = {
    activo: true,
    codigo: "25",
    descripcion: "EDITAR USUARIOS",
    idFormulario: "d02c649f-0f96-4510-b499-e7af7409249c",
    modulo: "admin",
    nombre: "EDITAR USUARIOS",
    rolId: "80dd2f96-00f0-4a19-aa3d-01ed447a2970",
  };

  useEffect(() => {
    const permisosActualizados = [...permisos];
    const permisoExistente = permisosActualizados.filter(
      (permiso) => permiso.idFormulario !== jsonpermiso.idFormulario
    );

    permisoExistente.push(jsonpermiso);
    //console.log(permisoExistente);

    setReporteEmison(
      permisoExistente.filter((obj) => obj.modulo === "reporteEmison")
    );
    setEmisionelectronica(
      permisoExistente.filter((obj) => obj.modulo === "emisionElectronica")
    );
    setInventario(
      permisoExistente.filter((obj) => obj.modulo === "inventario")
    );
    setAdmin(permisoExistente.filter((obj) => obj.modulo === "admin"));
    setAdministracion(
      permisoExistente.filter((obj) => obj.modulo === "administracion")
    );
  }, [permisos]);

  return (
    <>
      <ul className="pt-6 text-white ">
        <NavLink exact="true" to="/" className={""}>
          <li
            className=" text-sm flex items-center w-full group   hover:text-white gap-x-4 cursor-pointer p-2 hover:bg-white rounded-md"
            title={!props.OpenMenu ? "Dashboard" : ""}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6 group-hover:text-black"
            >
              <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
              <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
            </svg>

            <span
              className={`${
                !props.OpenMenu && "hidden"
              } origin-left duration-200 group-hover:text-black`}
            >
              Inicio
            </span>
            <span
              className={`${
                !props.openMobil && "hidden"
              } origin-left duration-200`}
            >
              Inicio
            </span>
          </li>
        </NavLink>
      </ul>
      <ul className="text-white">
        {Inventario === undefined ? null : Inventario.length > 0 ? (
          <Admin
            items={Inventario}
            modulo="Base de datos"
            OpenMenu={props.OpenMenu}
            setOpenMenu={props.setOpenMenu}
            openMobil={props.openMobil}
            setOpenMobil={props.setOpenMobil}
            openItem={props.openItem}
            setOpenItem={props.setOpenItem}
            toggleItem={props.toggleItem}
          />
        ) : null}
        {/* {permisos.some((el) => el.codigo === "03") ? (
          <InventarioOption
            OpenMenu={props.OpenMenu}
            modulo="Inventario"
            setOpenMenu={props.setOpenMenu}
            openMobil={props.openMobil}
            setOpenMobil={props.setOpenMobil}

            openItem = {props.openItem}
            setOpenItem = {props.setOpenItem}
            toggleItem={props.toggleItem}
            setSubMenu={props.setSubMenu}
            subMenu={props.subMenu}
          />
        ) : null} */}
        {emisionelectronica === undefined ? null : emisionelectronica.length >
          0 ? (
          <EmisionElectronica
            OpenMenu={props.OpenMenu}
            setOpenMenu={props.setOpenMenu}
            items={emisionelectronica}
            modulo="Emisión Documentos"
            openMobil={props.openMobil}
            setOpenMobil={props.setOpenMobil}
            openItem={props.openItem}
            setOpenItem={props.setOpenItem}
            toggleItem={props.toggleItem}
          />
        ) : null}

        <ReportesMenu
          OpenMenu={props.OpenMenu}
          modulo="Reportes"
          setOpenMenu={props.setOpenMenu}
          openMobil={props.openMobil}
          setOpenMobil={props.setOpenMobil}
          openItem={props.openItem}
          setOpenItem={props.setOpenItem}
          toggleItem={props.toggleItem}
        />

        {/* {permisos.some((el) => el.codigo === "03") ? (
          <NavLink exact="true" to="/emision/reporte/generalUsuario">
            <li className="text-sm flex items-center text-gray-300 hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                />
              </svg>
              <span
                className={`${
                  !props.OpenMenu && "hidden"
                } origin-left duration-200`}
              >
                Reporte Usuario
              </span>
            </li>
          </NavLink>
        ) : null} */}

        {admin === undefined ? null : admin.length > 0 ? (
          <Admin
            items={admin}
            modulo="Mantenimiento"
            OpenMenu={props.OpenMenu}
            setOpenMenu={props.setOpenMenu}
            openMobil={props.openMobil}
            setOpenMobil={props.setOpenMobil}
            openItem={props.openItem}
            setOpenItem={props.setOpenItem}
            toggleItem={props.toggleItem}
          />
        ) : null}
        {administracion === undefined ? null : administracion.length > 0 ? (
          <Admin
            items={administracion}
            modulo="Administración"
            OpenMenu={props.OpenMenu}
            setOpenMenu={props.setOpenMenu}
            openMobil={props.openMobil}
            setOpenMobil={props.setOpenMobil}
            openItem={props.openItem}
            setOpenItem={props.setOpenItem}
            toggleItem={props.toggleItem}
          />
        ) : null}
      </ul>
      {/* <NavLink to="/emision/generar/masivo">reporte masivo</NavLink> */}

      {/* {props.OpenMenu ? (
        <button
          data-bs-toggle="modal"
          data-bs-target="#tutorialesModal"
          className="fixed flex bottom-0 left-0 my-5 duration-300 mx-16 text-gray-300"
        >
          {" "}
          <span className="mr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.25}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
              />
            </svg>
          </span>{" "}
          Tutoriales
        </button>
      ) : (
        <button
          data-bs-toggle="modal"
          data-bs-target="#tutorialesModal"
          className="fixed flex bottom-0 left-0 my-5 duration-300 mx-7 text-gray-300"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.25}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
            />
          </svg>
          <span
            className={`${
              !props.openMobil && "hidden"
            } origin-left pl-1 duration-200`}
          >
            Tutoriales
          </span>
        </button>
      )} */}
      <SRI
        items={Inventario}
        modulo="SRI"
        OpenMenu={props.OpenMenu}
        setOpenMenu={props.setOpenMenu}
        openMobil={props.openMobil}
        setOpenMobil={props.setOpenMobil}
        openItem={props.openItem}
        setOpenItem={props.setOpenItem}
        toggleItem={props.toggleItem}
      />
      {/* <Suscripciones
        items={Inventario}
        modulo="Suscripciones"
        OpenMenu={props.OpenMenu}
        setOpenMenu={props.setOpenMenu}
        openMobil={props.openMobil}
        setOpenMobil={props.setOpenMobil}
      /> */}
    </>
  );
};

export default MenuGeneral;
