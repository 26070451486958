import React from 'react'
import ModalAdd from './ModalAdd';
import EstablecimientosProvider from './EstablecimientosProvider'
import ListaEstablecimiento from './ListaEstablecimiento';
import ModalEdit from './ModalEdit';
import { ListaPuntoEmision } from './ListaPuntoEmision';
import ModalSecuencial from './ModalSecuencial';

const Establecimientos = () => {
  return (
    <div className="space-y-6 mx-4 my-4 ">
      <div className="  dark:bg-gray-900 rounded-lg  ">
        <EstablecimientosProvider>
            <ListaEstablecimiento/>
            <ModalAdd/>
            <ModalEdit/>
            <ModalSecuencial />
            <ListaPuntoEmision/>
        </EstablecimientosProvider>
      </div>
    </div>
  )
}

export default Establecimientos;