import AjusteInventarioForm from "./AjusteInventarioForm";
import AjusteInventarioProvider from "./Context/AjusteInventarioProvider";
import ModalAjusteInventario from "./ModalAjusteInventario";



export default function AjusteInventario() {

  return (
    <>
      <AjusteInventarioProvider>
          <AjusteInventarioForm />
          <ModalAjusteInventario />
      </AjusteInventarioProvider>
    </>
  );
}
