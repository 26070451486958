import React, { useContext, useState } from "react";

import FormLiquidacion from "./FormLiquidacion";

import EmitirLiquidacionProvider, {
  EmitirLiquidacionContext,
} from "./EmitirLiquidacionProvider";
import FormBuscarDetalle from "./FormBuscarDetalle";
import ListaDetalle from "./ListaDetalle";
import TotalDocumento from "./TotalDocumento";
import FormaPago from "./FormaPago";
import Modals from "./Modals";
import ModalGuardar from "./ModalGuardar";
import InformacionAdicional from "../../../features/components/InformacionAdicional";
import BuscarLiquidacionModal from "./BuscarLiquidacionModal";
import ReembolsoProvider from "../../../features/hooks/Reembolso/ReembolsoProvider";
import Reembolso from "./Reembolso";
import MobileSubmitButton from "./MobileSubmitButton";
import SubmitButtonLiq from "./SubmitButtonLiq";
import { Transition } from "@headlessui/react";
import ReporteServicioProvider from "../../Mantenimiento/ProductosServicios/ReporteServicioProvider";
import ModalAdd from "../../Mantenimiento/ProductosServicios/ModalAdd";

//https://tailwindcomponents.com/component/invoice-generator-build-with-tailwindcss-and-alpinejs

const EmitirLiquidacion = () => {
  const [valueOptions, setValueOptions] = useState("FORMAPAGO");

  const ClassAvite =
    "text-sm py-1.5 w-full cursor-pointer   p-2 px-2 text-center bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-tl-lg font-medium leading-6 text-white";

  const ClassInactive =
    "text-sm py-1.5 w-full cursor-pointer  p-2 px-2 text-center bg-gray-400 dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-tl-lg font-medium leading-6 text-white";

  const ClassInactive2 =
    "text-sm py-1.5 w-full cursor-pointer  p-2 px-2 text-center bg-gray-400 dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-tr-lg font-medium leading-6 text-white";

  const ClassActive2 =
    "text-sm py-1.5 w-full cursor-pointer  p-2 px-2 text-center bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-tr-lg font-medium leading-6 text-white";
  const [isOpen, setIsOpen] = useState(true);
  const [isOpen2, setIsOpen2] = useState(true);


  return (
    <ReembolsoProvider>
      <EmitirLiquidacionProvider>
        <div className=" p-4 ">
          <div className="    dark:bg-gray-900  ">
            <FormLiquidacion />

            <div className="   bg-white lg:px-4 md:px-4 dark:bg-gray-900    ">
              <FormBuscarDetalle />
              <ListaDetalle />
              <Reembolso />
              <div className="lg:grid md:grid hidden lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-2 lg:mt-4">
                <div className="relative z-0 border dark:border-gray-600 rounded-lg  w-full group">
                  <div className="flex space-x-0 w-full">
                    <h3
                      onClick={() => setValueOptions("FORMAPAGO")}
                      className={
                        valueOptions === "FORMAPAGO"
                          ? ClassAvite
                          : ClassInactive
                      }
                    >
                      Forma de pago
                    </h3>
                    <h3
                      onClick={() => setValueOptions("INFORMACIONADICIONAL")}
                      className={
                        valueOptions === "INFORMACIONADICIONAL"
                          ? ClassActive2
                          : ClassInactive2
                      }
                    >
                      Información adicional (Opcional)
                    </h3>
                  </div>
               
                  <div className="p-4">
                    {valueOptions === "FORMAPAGO" ? (
                      <FormaPago
                        valueOptions={valueOptions}
                        setValueOptions={setValueOptions}
                      />
                    ) : (
                      <InformacionAdicional
                        Contexto={EmitirLiquidacionContext}
                        valueOptions={valueOptions}
                        setValueOptions={setValueOptions}
                      />
                    )}{" "}
                  </div>
                </div>
                <div className="  border rounded-lg dark:border-gray-600 w-full ">
                  <TotalDocumento />
                </div>
              </div>
              <div className="lg:hidden md:hidden border rounded-b-lg lg:mx-1 md:px-1 my-1">
                <div className="text-sm py-1.5   px-2 text-left bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 dark:border-gray-700 rounded-t-lg font-medium leading-6 text-white flex justify-between">
                  <h3 className="flex w-full justify-between items-center">
                    Forma de pago
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      onClick={() => setIsOpen(!isOpen)}
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className={`w-4 h-4 ${isOpen ? "rotate-180" : ""}`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </h3>
                </div>
                <Transition
                  show={isOpen}
                  className={"mx-4"}
                  enter="transition ease-out duration-300" // Aumenta la duración de entrada
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-300" // Aumenta la duración de salida
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <FormaPago />
                </Transition>
              </div>

              <div className="lg:hidden md:hidden border rounded-b-lg my-1">
                <div className="text-sm py-1.5  px-2 text-left bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 dark:border-gray-700 rounded-t-lg font-medium leading-6 text-white flex justify-between">
                  <h3 className="flex w-full justify-between items-center">
                    Información adicional (Opcional)
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      onClick={() => setIsOpen2(!isOpen2)}
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className={`w-4 h-4 ${isOpen2 ? "rotate-180" : ""}`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </h3>
                </div>
                <Transition
                  show={isOpen2}
                  className={"mx-4"}
                  enter="transition ease-out duration-300" // Aumenta la duración de entrada
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-300" // Aumenta la duración de salida
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <InformacionAdicional
                    Contexto={EmitirLiquidacionContext}
                    valueOptions={valueOptions}
                    setValueOptions={setValueOptions}
                  />
                </Transition>
              </div>
              <div className="lg:hidden md:hidden  border rounded-lg dark:border-gray-600 w-full ">
                <TotalDocumento />
              </div>

              <div className="flex py-4 lg:justify-end justify-center">
                <SubmitButtonLiq />
              </div>
            </div>
          </div>
        </div>
        <Modals />
        <ModalGuardar />
        <ReporteServicioProvider>
            <ModalAdd />
          </ReporteServicioProvider>
        <BuscarLiquidacionModal />
      </EmitirLiquidacionProvider>
    </ReembolsoProvider>
  );
};

export default EmitirLiquidacion;
