import React, { useContext } from "react";
import Paises01 from "../../../../../data/pais01.json";
import InputText from "../../../../../features/components/common/InputText";
import SelectForm from "../../../../../features/components/common/SelectForm";
import { FacturaExportacionContext } from "./FacturaExportacionProvider";
import InputNumber from "../../../../../features/components/common/InputNumber";
import InputNumberUF from "../../../../../features/components/common/InputNumberUF";

const ExportacionForm = () => {
  const { IsActiveExportacion, setListExportacion } = useContext(FacturaExportacionContext);

  const Paises = Paises01.map((el) => {
    return { value: el.Codigo, name: el.Pais };
  });
  const handleInputChange = (fieldName, value) => {
    setListExportacion((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  if (!IsActiveExportacion) return <></>;

  return (
    <>
    <div className="border rounded-lg mb-4 ">
    <h3 className="flex text-sm py-1.5   px-2 text-left bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-t-lg font-medium leading-6 text-white">
        Datos de exportación{" "}
      </h3>

      <div>
        <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 p-4  lg:gap-3 gap-2">
          <div>
            <SelectForm
             id={"Paises"}
              name={"exportacion.paisAdquisicion"}
              title={"País adquisicion:"}
              validations={{ required: true }}
              options={Paises}
            />
          </div>
          <div>
            <SelectForm
             id={"Paises"}
              name={"exportacion.paisOrigen"}
              title={"País origen:"}
              validations={{ required: true }}
              options={Paises}
            />
          </div>
          <div>
            <SelectForm
             id={"Paises"}
              name={"exportacion.paisDestino"}
              title={"País destino:"}
              validations={{ required: true }}
              options={Paises}
            />
          </div>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 p-4 gap-3 pt-2 ">
          <div>
            <InputText
              name={"exportacion.incotermLugar"}
              title={"Lugar incoterm:"}
              validations={{
                required: true,
                maxLength: {
                  value: 300,
                  message: "No más de 300 carácteres!",
                },
              }}
              max="300"
            />
          </div>
          <div>
            <InputText
              name={"exportacion.puertoEmbarque"}
              title={"Puerto embarque:"}
              validations={{
                required: true,
                maxLength: {
                  value: 300,
                  message: "No más de 300 carácteres!",
                },
              }}
              max="300"
            />
          </div>
          <div>
            <InputText
              name={"exportacion.puertoDestino"}
              title={"Puerto destino:"}
              validations={{
                required: true,
                maxLength: {
                  value: 300,
                  message: "No más de 300 carácteres!",
                },
              }}
              max="300"
            />
          </div>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-2 p-4  my-3">
          <div>
            <InputText
              name={"exportacion.incotermFactura"}
              title={"Incoterm factura: *"}
              validations={{
                required: true,
                maxLength: {
                  value: 10,
                  message: "No más de 10 carácteres!",
                },
              }}
          
              max="10"
            />
          </div>
          <div>
            <InputText
              name={"exportacion.incotermTotalSinImp"}
              title={"Incoterm total sin impuestos:"}
              validations={{
                required: true,
                maxLength: {
                  value: 10,
                  message: "No más de 10 carácteres!",
                },
              }}
              max="10"
            />
          </div>
        </div>

        <hr />

        <div className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-1 gap-2 p-4 pt-7">
          <div>
            <InputNumberUF
              name={"exportacion.fleteInternacional"}
              title={"Flete internacional:"}
              validations={{
                required: true,
                maxLength: {
                  value: 14,
                  message: "No más de 14 carácteres!",
                },
              }}
              max="14"
              onChange={(event) => {
                const value = event.target.value;
                handleInputChange("fleteInternacional", value);
              }}
            />
          </div>
          <div>
            <InputNumberUF
              name={"exportacion.seguroInternacional"}
              title={"Seguro internacional:"}
              validations={{
                required: true,
                maxLength: {
                  value: 14,
                  message: "No más de 14 carácteres!",
                },
              }}
              max="14"
              onChange={(event) => {
                const value = event.target.value;
                handleInputChange("seguroInternacional", value);
              }}
            />
          </div>
          <div>
            <InputNumberUF
              name={"exportacion.gastosAduaneros"}
              title={"Gastos aduaneros:"}
              validations={{
                required: true,
                maxLength: {
                  value: 14,
                  message: "No más de 14 carácteres!",
                },
              }}
              max="14"
              onChange={(event) => {
                const value = event.target.value;
                handleInputChange("gastosAduaneros", value);
              }}
            />
          </div>
          <div>
            <InputNumberUF
              name={"exportacion.gastosTransporteOtros"}
              title={"Gastos transporte:"}
              validations={{
                required: true,
                maxLength: {
                  value: 14,
                  message: "No más de 14 carácteres!",
                },
              }}
              max="14"
              onChange={(event) => {
                const value = event.target.value;
                handleInputChange("gastosTransporteOtros", value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
   
    </>
  );
};

export default ExportacionForm;
