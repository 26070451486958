import React from 'react';
import EmailsModal from '../ReporteEmisionGeneral/EmailsModal';
import FormFacturaUsuario from './FormFacturaUsuario';
import ListaFacturasUsuario from './ListaFacturasUsuario';
import ReporteFacturaUsuarioProvider from './ReporteFacturaUsuarioProvider';
import { ModalTrazabilidadUsuario } from './ModalTrazabilidadUsuario';
import EmailsModalUsuario from './EmailsModalUsuario';




const ReporteEmitidosGeneralContribuyente = () => {
    return (
        <div className="space-y-6 xl:mx-4 my-2">
            <div className="bg-white shadow  dark:bg-gray-900 sm:rounded-lg md:py-2 md:px-4 py-2 px-2">
                <ReporteFacturaUsuarioProvider>
                    <FormFacturaUsuario />
                    <ListaFacturasUsuario />
                    <ModalTrazabilidadUsuario />
                    <EmailsModalUsuario />
                </ReporteFacturaUsuarioProvider>
            </div>
        </div>
    );
};

export default ReporteEmitidosGeneralContribuyente;