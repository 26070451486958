import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getListV2 } from "../../../services";

import { EmitirDocContext } from "./EmitirDocProvider";

import { roundToTwo } from "../../../services/Utilitario";
import {
  StyleInput,
  StyleLabel,
  StyleSelect,
} from "../../../features/Constantes";
import { LargeModalForm } from "../../../features/components/LargeModalForm";
import InputNumber from "../../../features/components/common/InputNumber";

const Modals = () => {
  const { DetalleItem, setDetalleItem, DetalleList, setDetalleList } =
    useContext(EmitirDocContext);


  const [TempDetalleItem, setTempDetalleItem] = useState({});
  const [ImpuestosList, setImpuestosList] = useState([]);
  const [InformacionAdicionalList, setInformacionAdicionalList] = useState([]);
  const [motivoValue, setMotivoValue] = useState(TempDetalleItem.motivo || '');
  const empresa = useSelector((store) => store.empresa.empresa);

  useEffect(() => {
    // console.log('---------------a')
    setTempDetalleItem({ ...DetalleItem });

    // console.log(DetalleItem);

    if (DetalleItem.servicioDetalle) {
      let listItems = [];
      DetalleItem?.servicioDetalle?.forEach((el) => {
        listItems.push(el);
      });
      setInformacionAdicionalList(listItems);
    }

    (async () => {
      let data = await getListV2(
        empresa,
        "GetAllimpDetalles",
        "?activo=true&idImpuesto=1"
      );
      setImpuestosList(data._embedded);
    })();

    setMotivoValue(DetalleItem.motivo || '');
  }, [DetalleItem]);

  const handleChangeImpuesto = (e) => {
    const index = e.target.selectedOptions[0].getAttribute("data-index");

    const item = {
      ...TempDetalleItem,
      [e.target.name]: e.target.value,
      idImpuestoIvaNavigation: { ...ImpuestosList[index] },
    };
    setTempDetalleItem(item);
  };

  const calcularValorNeto = (item) =>
    roundToTwo(parseFloat(item.precioUnitario) * parseFloat(item.cantidad));

  const calcularValorConDescuento = (item) =>
    roundToTwo(calcularValorNeto(item) - parseFloat(item.descuento));

  const calcularTotal = (item) => {
    if (item.descuento === undefined) return 0;
    if (item.descuento === null) return 0;
    let valorTotal = roundToTwo(
      item.idImpuestoIvaNavigation
        ? calcularValorConDescuento(item) +
            calcularValorConDescuento(item) *
              item.idImpuestoIvaNavigation.porcentaje
        : calcularValorConDescuento(item)
    );
    return valorTotal;
  };
  const handleMotivoChange = (e) => {
    setMotivoValue(e.target.value);

   
    setTempDetalleItem({
      ...TempDetalleItem,
      motivo: e.target.value,
    });
  };
  const handleSubmitModal = () => {

    if (calcularTotal(TempDetalleItem) >= 0) {
      DetalleList[TempDetalleItem.index] = {
        ...TempDetalleItem,
        servicioDetalle: [...InformacionAdicionalList],
      };
      const porcentaje = DetalleList[TempDetalleItem.index].idImpuestoIvaNavigation
        ? DetalleList[TempDetalleItem.index].idImpuestoIvaNavigation.porcentaje
        : 0;
      DetalleList[TempDetalleItem.index].porcentaje = porcentaje;
      setDetalleList([...DetalleList]);
      //item.idImpuestoIvaNavigation.porcentaje = porcentaje;

      document.getElementById("editDetalleDebitoModalclose").click();
      setDetalleItem({});
      setTempDetalleItem({});
    }
  };

  return (
    <LargeModalForm
      buttonModal={"editDetalleDebitoModal"}
      submitText={"Guardar"}
      title="EDITAR DETALLE"
      submitModal={handleSubmitModal}
    >
      <div className="w-full  mb-2 md:mb-0 my-2 ">
        <div className="relative z-0 mb-2 w-full group">
        <label className={
       
        
       StyleLabel + " -translate-x-0 -translate-y-0"
   } >
            MOTIVO
          </label>
        <input
        type="text"
        className={StyleInput}
        placeholder=" "
        id="motivo"
        name="motivo"
        value={motivoValue}
        onChange={handleMotivoChange}
        
      />
          
        </div>
        <div className="relative z-0 mb-2 w-full group">
          <div className="w-full grid md:grid-cols-2 md:gap-2 mb-2 ">
            <div>
            <InputNumber
              identifier="valor"
              name="CANTIDAD"
              decimalMax={4}
              value={TempDetalleItem.valor}
              onChange={(e) =>
                setTempDetalleItem({
                  ...TempDetalleItem,
                  valor: e.target.value,
                })
              }
            />
            </div>
        
            <div className="relative z-0 mb-2 w-full group">
            <label className={
       
        
       StyleLabel + " -translate-x-0 -translate-y-0"
   } >
            IVA
          </label>
              <select
                type="text"
                className={StyleSelect}
                placeholder=" "
                name="idImpuestoIva"
                id="idImpuestoIva"
                onChange={handleChangeImpuesto}
                value={TempDetalleItem.idImpuestoIva}
              >
                {ImpuestosList.map((el, index) => (
                  <option
                    key={index}
                    data-index={index}
                    value={el.idImpuestoDetalle}
                  >
                    {el.detalle}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </LargeModalForm>
  );
};

export default Modals;
