
import EgresoProvider from "./Context/EgresoProvider";
import EgresoForm from "./EgresoForm";
import ModalEgreso from "./ModalEgreso";

export default function Egreso() {

  return (
    <>
      <EgresoProvider>
          <EgresoForm />
          <ModalEgreso />
      </EgresoProvider>
    </>
  );
}
