import React, { useEffect, createContext, useState } from 'react';
import { useSelector } from 'react-redux'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getListV2 } from '../../../services';

export const ReporteUsuariosContext = createContext();

const ReporteUsuariosProvider = (props) => {

    const empresa = useSelector(store => store.empresa.empresa);
    const [start, setStart] = useState(0);

    const [documentos, setDocumentos] = useState([]);
    const [cargandoData, setCargandoData] = useState(false);
    const [ActivarModalAdd, setActivarModalAdd] = useState(false);
    const [ActivarModalEdit, setActivarModalEdit] = useState(false);
    const [IsLoading, setIsLoading] = useState(false);

    const initialStatePaginacion = {
        pagina: 1,
        _totalpaginas: 1,
        _totalelementos: 0,
        _links: [],
        _inicioRegistro: 0,
        _totalEnPagina: 0
    };
    const [Paginacion, setPaginacion] = useState(initialStatePaginacion);

    const initialState = {
        identificacion: "",
        idUsuario: '',
        nombre: "",
        esAdministrador: 'True',
        activo: "True",
        formularios: []
    }
    const [NewItem, setNewItem] = useState(initialState);

    const formik = useFormik({
        initialValues: {
            estado: '',
            identificacion: '',
            username: '',
            nombre: '',
            cantidad: 50
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .max(128, 'Tamaño del campo 20'),
            nombre: Yup.string()
                .max(128, 'Tamaño del campo 20'),
        }),
        onSubmit: async obj => {
            try {
                setCargandoData(true);

                await fetchData(obj.estado, obj.identificacion, obj.username, obj.nombre, obj.cantidad);

                setCargandoData(false);
            } catch (error) {
               // console.log(error)
            }

            setCargandoData(false)
        }
    });



    useEffect(() => {
        formik.handleSubmit();
    }, [])

    const fetchData = async (
        estado = '',
        identificacion = '',
        username = '',
        nombre = '',
        cantidad = ''
    ) => {
        try {
            const res = await getListV2(
                empresa,
                'GetUsuariosByEmpresa',
                `?estado=${estado}&start=${start}&identificacion=${identificacion}&username=${username}&nombre=${nombre}&Pagina=${Paginacion.pagina}&Cantidad=${cantidad}`
            )



            if (!(res === undefined)) {
                setPaginacion({

                    ...Paginacion,
                    _totalpaginas: res._totalpaginas,
                    _totalelementos: res._totalelementos,
                    _links: res._links,
                    _inicioRegistro: res._inicioRegistro,
                    _totalEnPagina: res._totalEnPagina

                });


             //   console.log(res._totalEnPagina);
                setDocumentos(res._embedded)
            }

        } catch (error) {

           // console.log(error)

        }
    }

    return (
        <ReporteUsuariosContext.Provider value={{ initialStatePaginacion, start, setStart, documentos, cargandoData, formik, ActivarModalAdd, setActivarModalAdd, ActivarModalEdit, setActivarModalEdit, NewItem, setNewItem, initialState, Paginacion, setPaginacion, setIsLoading, IsLoading }}>
            {
                props.children
            }
        </ReporteUsuariosContext.Provider>
    )
}

export default ReporteUsuariosProvider;