export const StyleInputForm =       'block pt-2.5  px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'
export const StyleInputFormReactSelect =       ' text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 dark:text-gray-400 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none  focus:ring-0 focus:border-blue-600 peer'
export const StyleInputFormError =  'block pt-2.5 px-0 w-full text-sm text-red-600 bg-transparent border-0 border-b-2 border-red-600 appearance-none dark:text-white dark:border-red-600 dark:focus:border-red-600 focus:outline-none focus:ring-0 focus:border-red-600 peer'
//export const StyleLabelForm =       "peer-focus:font-semibold absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 font-semibold"
export const StyleLabelForm =       "peer-focus:font-semibold absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 font-semibold"
export const StyleLabelFormError =  "peer-focus:font-semibold absolute text-md text-red-600 dark:text-red-600 duration-300 transform -translate-y-4 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-red-600 peer-focus:dark:text-red-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 font-semibold"
export const StyleSelectForm =      "z-[1000000] block pt-2.5 px-0 w-full text-sm text-gray-500 bg-transparent rounded-lg   dark:text-gray-400 dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer  "
//export

export const StyleInputDefault =        'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'
export const StyleInputDefaultError =   'block py-2.5 px-0 w-full text-sm text-red-600 bg-transparent border-0 border-b-2 border-red-600 appearance-none dark:text-white dark:border-red-600 dark:focus:border-red-600 focus:outline-none focus:ring-0 focus:border-red-600 peer'
export const StyleLabelDefault =        "peer-focus:font-semibold absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-semibold"
export const StyleLabelDefaultError =   "peer-focus:font-semibold absolute text-md text-red-600 dark:text-red-600 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-red-600 peer-focus:dark:text-red-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-semibold"

export const StyleSelectDefault = "block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer form-select"
export const StyleSelectDefaultError = "block py-2.5 px-0 w-full text-sm text-red-900 bg-transparent border-0 border-b-2 border-red-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-600 peer form-select"
export const sinDatos ="Sin Datos";
export const cargando ="Cargando...";

export const CodigoImpuestoMap = {
  0: "IVA 0",
  2: "IVA 12",
  3: "IVA 14",
  6: "NO OBJETO IMP",
  7: "EXENTO IVA",
  8: "IVA 8",
  4: "IVA 15",
  5: "IVA 5",
  10: "IVA 13",

};

export const customStyles = {
  control: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    border: 0,
    height: "auto",
    color: 'white',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    position: "absolute",
    left: 0,
    top: `calc(100% + 1px)`,
    width: "100%",
    zIndex: 200,
    overflowX: "hidden",
    color: 'rgb(158 158 158 / var(--tw-text-opacity))',
  }),
  menuList: (provided) => ({
    ...provided,
    overflowY: "auto",
    zIndex: 200,
    overflowX: "hidden",
    height: 195,
    color: 'rgb(158 158 158 / var(--tw-text-opacity))',
  }),
  option: (provided) => ({
    ...provided,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'rgb(158 158 158 / var(--tw-text-opacity))',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  input: (provided) => ({
    ...provided,
    color: 'rgb(158 158 158 / var(--tw-text-opacity))',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
};



// Estilos Nuevos
export const StyleLabel='block dark:text-gray-300 text-sm font-medium text-slate-700 transform -translate-y-14  top-2  -z-10 origin-[0] peer-focus:text-blue-600'
export const StyleInput = 'peer   pl-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500  flex-1 block w-full form-control border py-1.5 rounded-md sm:text-sm border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1'
export const StyleInputDisabled = 'peer  pl-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white  flex-1 block w-full form-control border py-1.5 rounded-md sm:text-sm border-gray-300 text-white bg-gray-500'
export const StyleInput2 = 'peer pl-2 flex-1 block w-full rounded-none form-control border py-1.5 rounded-r-md sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1 '
export const StyleInputError = 'peer pl-2 dark:bg-gray-700  flex-1 block w-full form-control border py-1.5 rounded-md sm:text-sm border-red-500 border-2 focus:outline-none focus:border-red-500 focus:ring-red-500 focus:ring-0 dark:text-white'
export const StyleLabelError='block text-sm font-medium text-red-500 transform -translate-y-14  top-2  -z-10 origin-[0] peer-focus:text-red-600'
export const StyleInPutFloat = 'dark:bg-gray-700 dark:border-gray-600  block w-full py-1.5 pl-2  text-sm form-control border text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'
export const LabelFloat = 'absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1'
 export const StyleSelect ='peer dark:bg-gray-700 dark:border-gray-600  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 block w-full py-1.5 pl-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
 export const StyleSelectError ='peer dark:bg-gray-700 dark:border-gray-600  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 block w-full py-1.5 pl-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
 export const StyleSelectAsync = 'block w-full dark:bg-gray-700 dark:border-gray-600 text-red-600 py-0 pl-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'; 
 export const StyleCardDasb = 'group col-span-1 dark:bg-gray-700 flex flex-col text-center bg-gray-50 hover:bg-[#E5F4FD]  rounded-lg shadow  hover:drop-shadow-xl divide-y divide-gray-200  hover:scale-105 duration-300 transition ease-in-out delay-150'
 export const StyleSvg = 'w-24 h-24 flex-shrink-0 mx-auto rounded-md group-hover:stroke-black group-hover:duration-300 dark:stroke-gray-50'
 export const StyleTextCardDasb = 'mt-6 text-gray-900 text-sm font-medium group-hover:text-black group-hover:duration-300 dark:text-white'
 export const StyleCardDasbInactive = 'group col-span-1 flex flex-col text-center bg-gray-500 rounded-lg divide-y divide-gray-200'
 export const StyleSvgInactive = 'w-24 h-24 flex-shrink-0 mx-auto text-white rounded-md'
 export const StyleTextCardDasbInactive = 'mt-6 text-gray-900 text-sm text-white font-medium'
 export const LabelPago = 'ext-gray-800 dark:bg-gray-700 dark:border-gray-600  dark:text-white max-w-xs text-right border bg-gray-100 rounded-l-lg px-1 py-2  flex-1 text-sm font-semibold'
 export const DiseñoPago = 'lg:ml-5 ml-2 border rounded-r-lg px-1 py-2 bg-gray-100 dark:bg-gray-700 dark:border-gray-600 text-center '
 export const DiseñoExportacion = 'lg:ml-5 ml-2 border rounded-r-lg px-1 py-2 bg-gray-100 dark:bg-gray-700 dark:border-gray-600 text-center '
 export const TableStyleDiv = ' overflow-x-auto  overflow-auto  shadow ring-1 ring-black ring-opacity-5'
 export const TableStyle = 'min-w-full table-fixed bg-white divide-y divide-gray-300 dark:divide-gray-700'
 export const TableStyleTHead= 'text-center  bg-white  divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-800 dark:text-gray-400'
 export const TableStyleTBody= 'text-center text-gray-900 dark:text-gray-400 divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-800'
 // Style Buttones
 export const ButtonStyleDefault = 'inline-flex mr-2 lg:mb-0 mb-6  items-center px-4 py-2 border border-transparent shadow-sm text-sm leading-5 font-body rounded-md text-white   focus:outline-none focus:ring-2 focus:ring-offset-2 '
 