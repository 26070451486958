import React, { useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { DiseñoExportacion, DiseñoPago } from "../../../features/Constantes";
import { EmitirFacturaContext } from "../EmitirFactura/EmitirFacturaProvider";
import { FacturaExportacionContext } from "./hooks/FacturaExportacion/FacturaExportacionProvider";
import { useForm } from "react-hook-form";
import TotalesComponents from "../../../features/components/TotalesComponents";

const TotalDocumento = () => {
  const {
    Totales,
    incrementarContador,
    decrementarContador,
    incrementarContadorIVA5,
    decrementarContadorIVA5,
    incrementarContadorIVA13,
    decrementarContadorIVA13,
    incrementarContadorIVA8,
    decrementarContadorIVA8,
    incrementarContadorIVA12,
    decrementarContadorIVA12,
  } = useContext(EmitirFacturaContext);

  const { listExportacion, IsActiveExportacion } = useContext(
    FacturaExportacionContext
  );
  const [totalImporte, setTotalImporte] = useState(0);

  useEffect(() => {
    const importeTotal =
      Number(listExportacion.fleteInternacional || 0) +
      Number(listExportacion.seguroInternacional || 0) +
      Number(listExportacion.gastosAduaneros || 0) +
      Number(listExportacion.gastosTransporteOtros || 0) +
      (Totales.importeTotal || 0);
    setTotalImporte(importeTotal);
    if (IsActiveExportacion !== true) {
      const importeTotal2 = Totales.importeTotal || 0;
      setTotalImporte(importeTotal2);
    }
  }, [listExportacion, Totales.importeTotal, IsActiveExportacion]);
  return (
    <TotalesComponents
    Totales={Totales}
    totalImporte={totalImporte}
    DiseñoExportacion={DiseñoExportacion}
    DiseñoPago={DiseñoPago}
    IsActiveExportacion={IsActiveExportacion}
    listExportacion={listExportacion}
    incrementarContador={incrementarContador}
    decrementarContador={decrementarContador}
    incrementarContadorIVA5 = {incrementarContadorIVA5}
    decrementarContadorIVA5 = {decrementarContadorIVA5}
    incrementarContadorIVA13 = {incrementarContadorIVA13}
    decrementarContadorIVA13 = {decrementarContadorIVA13}
    incrementarContadorIVA8 = {incrementarContadorIVA8}
    decrementarContadorIVA8 = {decrementarContadorIVA8}
    incrementarContadorIVA12 = {incrementarContadorIVA12}
    decrementarContadorIVA12 = {decrementarContadorIVA12}
  />
  );
};

export default TotalDocumento;