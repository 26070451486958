import { useFormContext } from "react-hook-form";


export const InputGlobal = ({ name, validations, pattern, disabled, onChange, max, value, type, title, isHook, isPlaceHolder }) => {


    const { register, formState: { errors } } = useFormContext()


    const StyleInput =
        "border-gray-300 focus:outline-none left-0 relative focus:border-blue-500 focus:ring-blue-500 focus:ring-1 ring-0 w-full border rounded-md pl-2 text-xs py-2 space-x-0";
    const StyleInputError =
        "border-red-500 focus:outline-none left-0 relative  focus:border-red-500 focus:ring-red-500 focus:ring-1 ring-0 w-full border rounded-md pl-1 text-xs py-1.5 space-x-0";

    return (
        <div className="text-xs  ">
            <label
                htmlFor={title}
                className={
                    errors[name]
                        ? "w-full text-red-600 my-auto flex flex-col gap-2"
                        : "w-full text-gray-600 my-auto flex flex-col gap-2"
                }
            >
                {title}
                <input
                    type={type ? type : "text"}
                    {...register(name, validations)}
                    pattern={pattern}
                    disabled={disabled ? disabled: false}
                    placeholder={isPlaceHolder}
                    onChange={onChange}
                    maxLength={max}
                    value={value}
                    className={errors[name] ? StyleInputError : StyleInput}
                    id={title}
                />
                {errors[name] && errors[name].type === "required" && (
                    <span className="text-red-600 text-[10px]">El Campo {title.toLowerCase()} es requerido.</span>
                )}
            </label>
        </div>
    )

}







