import React from "react";
import FormListaEmpresas from "./FormListaEmpresas";
import ReporteListaEmpresasProvider from "./ListaEmpresasProvider";
import ListaEmpresasTable from "./ListaEmpresasTable";

const ListaEmpresas = () => {
  return (
    <div className="mx-4 my-4">
      <ReporteListaEmpresasProvider>
        <div className="border rounded-lg bg-white dark:bg-gray-700 dark:border-gray-600 space-y-2 ">
          <FormListaEmpresas/>
          </div>
          <div className=" border rounded-lg space-x-2 space-y-2 my-2 bg-white dark:bg-gray-700 dark:border-gray-600 ">
            <ListaEmpresasTable/>
          </div>
        
      </ReporteListaEmpresasProvider>
    </div>
  );
};

export default ListaEmpresas;
