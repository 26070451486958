import { createContext, useState } from "react";
import { useForm } from "react-hook-form";
import { getFechaInicio } from "../../../../../../services/Utilitario";
import { v4 as uuidv } from "uuid";

export const EgresoProviderContext = createContext();

const EgresoProvider = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const methods = useForm({
    defaultValues: { fechaIngreso: getFechaInicio() },
  });
  const [number, setNumber] = useState(1);
  const padNumber = (num, size) => {
    let s = "0000" + num;
    return s.substr(s.length - size);
  };
  const [rows, setRows] = useState([
    {
      codigo: "",
      cuenta: "",
      cantidad: "",
      costo: "",
      lote: "",
      fechaCaducidad: "",
    },
  ]);

  const Agg = (data) => {
    data.IdEgreso = uuidv();
    data.Detalle = rows;
    setIsOpen(true);
    console.log(data);
    const existingData = JSON.parse(localStorage.getItem("Transacciones")) || [];
    
    const updatedData = [...existingData, data];
    localStorage.setItem("Transacciones", JSON.stringify(updatedData));
    setNumber(number + 1);
    methods.reset();
    setRows([
      {
        codigo: "",
        cuenta: "",
        cantidad: "",
        costo: "",
        lote: "",
        fechaCaducidad: "",
      },
    ]);
  };

  return (
    <EgresoProviderContext.Provider
      value={{
        rows,
        setRows,
        methods,
        setIsOpen,
        isOpen,
        Agg,
        padNumber,
        number
      }}
    >
      {props.children}
    </EgresoProviderContext.Provider>
  );
};

export default EgresoProvider;
