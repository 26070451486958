import React, { useContext, useEffect } from "react";
import { EmitirDocContext } from "../EmitirDocProvider";
import { LargeModalForm } from "../../../../features/components/LargeModalForm";
import InfoOpcionalImpuesto from "./InfoOpcionalImpuesto";
import AdicionalesImpuesto from "./AdicionalesImpuesto";
import { useForm } from "react-hook-form";
import { ModalAddImpuestoContext } from "./ModalAddImpuestoProvider";
import MainFields from "./MainFields";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  rellenarCeros,
  roundNumber,
  roundToTwo,
} from "../../../../services/Utilitario";

const ModalAdd = () => {
  const { setDataFromAdd, DataFromAdd, IsEdit, setIsEdit, EditElement } = useContext(EmitirDocContext);

  const {
    RentaList,
    setRentaList,
    IvaList,
    setIvaList,
    FormaPagoList,
    setFormaPagoList,
    setTotalImpToFormPago,
    DividendosList,
    setDividendosList,
    PagoLocExt,
    isLoad,
    setIsLoad
  } = useContext(ModalAddImpuestoContext);

  const notify = (msg) => toast.warn(msg);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    watch,
    setError,
  } = useForm("onBlur");

  useEffect(() => {
    resetForm();
  }, []);

  useEffect(() => {
    if (IsEdit) {
      let element = DataFromAdd[EditElement];
      if (element === undefined) return;
      let rentaList = element.Retenciones.filter((el) => el.isRenta);
      let ivaList = element.Retenciones.filter((el) => !el.isRenta);

      setIvaList(ivaList);
      setRentaList(rentaList);
      setFormaPagoList(element.Pago);

      setValue("CodSustento", element.CodSustento);
      setValue("CodDocSustento", element.CodDocSustento);
      setValue("PagoLocExt", element.PagoLocExt);
      setValue("NumDocSustento", element.NumDocSustento);
      setValue("NumAutDocSustento", element.NumAutDocSustento);
      setValue("FechaEmisionDocSustento", element.FechaEmisionDocSustento);
      setValue("TotalSinImpuestos", element.TotalSinImpuestos);
      setValue("ImporteTotal", element.ImporteTotal);

      setValue("TipoRegi", element.TipoRegi);
      setValue("FechaRegistroContable", element.FechaRegistroContable);
      setValue("PaisEfecPago", element.PaisEfecPago);
      setValue("AplicConvDobTrib", element.AplicConvDobTrib);
      //setValue("PagExtSujRetNorLeg", element.PagExtSujRetNorLeg);
      setValue("PagoRegFis", element.PagoRegFis);
    }
  }, [DataFromAdd, EditElement, IsEdit]);

  const fieldValidator = (el) => {
    if (el === "") return null;
    if (el === undefined) return null;
    if (el <= 0) return null;
    if (el.length < 0) return null;

    return el;
  };

  const handleSubmitModal = () => {
    setIsLoad(true);

    if (DataFromAdd.find((el) => el.NumDocSustento === getValues().NumDocSustento)) {
      setError("NumDocSustento", "asdsaadas");
      return;
    }

    let SumaDeRentas = RentaList.reduce((total, el) => {
      let porcentajeImpuesto = JSON.parse(el.TipoImpuesto).porcentaje;
      return total + roundToTwo(el.BaseImponible * (porcentajeImpuesto + 1));
    }, 0);

    let SumaDeRentasSinImp = RentaList.reduce((total, el) => total + roundToTwo(el.BaseImponible), 0);

    const data = {
      CodSustento: fieldValidator(getValues().CodSustento),
      CodDocSustento: fieldValidator(getValues().CodDocSustento),
      NumDocSustento: fieldValidator(getValues().NumDocSustento),
      FechaEmisionDocSustento: fieldValidator(
        getValues().FechaEmisionDocSustento
      ),
      NumAutDocSustento: fieldValidator(
        getValues().NumAutDocSustento
          ? getValues().NumAutDocSustento.trim()
          : ""
      ),
      TotalComprobantesReembolso: fieldValidator(
        getValues().TotalComprobantesReembolso
      ),
      TotalBaseImponibleReembolso: fieldValidator(
        getValues().TotalBaseImponibleReembolso
      ),
      TotalImpuestoReembolso: fieldValidator(
        getValues().TotalImpuestoReembolso
      ),
      PagExtSujRetNorLeg:
        fieldValidator(getValues().PagExtSujRetNorLeg) || false,
      TotalSinImpuestos: roundToTwo(fieldValidator(SumaDeRentasSinImp)),
      ImporteTotal: roundToTwo(fieldValidator(SumaDeRentas)),
      FechaRegistroContable: fieldValidator(getValues().FechaRegistroContable),
      PagoLocExt: fieldValidator(getValues().PagoLocExt),
      TipoRegi:
        PagoLocExt === "02"
          ? fieldValidator(getValues().TipoRegi)
            ? fieldValidator(getValues().TipoRegi)
            : "01"
          : null,
      PaisEfecPago: fieldValidator(
        getValues().PaisEfecPago === "" && getValues().PagoLocExt === "01"
          ? "593"
          : getValues().PaisEfecPago
      ),
      AplicConvDobTrib: fieldValidator(getValues().AplicConvDobTrib) || false,
      //PagExtSujRetNorLeg: getValues().PagExtSujRetNorLeg,
      PagoRegFis: fieldValidator(getValues().PagoRegFis),
      Retenciones: fieldValidator([...RentaList, ...IvaList]),
      Pago: fieldValidator([...FormaPagoList]),
      //Dividendos: fieldValidator([...DividendosList]),
      ImpuestosDocSustento: fieldValidator(
        RentaList.map((el) => {
          let TipoImp = JSON.parse(el.TipoImpuesto);
          return {
            codigoPorcentaje: TipoImp.codigo,
            baseImponible: el.BaseImponible,
            valorImpuesto: roundNumber(
              el.BaseImponible * TipoImp.porcentaje,
              2
            ),
            tipoImpuesto: 1,
            tipoImpuesto2: TipoImp.codigo,
          };
        })
      ),
    };

    if (data.NumAutDocSustento) {
      if (
        data.NumAutDocSustento.length !== 10 &&
        data.NumAutDocSustento.length !== 37 &&
        data.NumAutDocSustento.length !== 49
      ) {
        notify("El Codigo Autorizacion Documento sustento no es valido. Debes ingresar 10, 37 o 49 dígitos", { type: 'info', autoClose: 2000 });
        setTimeout(() => { setIsLoad(false) }, 2000);
        return;
      }
    }

    var NumDocSustento = data.NumDocSustento.replace(/\s/g, "").length;

    if (data.PaisEfecPago === null) {
      notify("Falta agregar el pais efectuado pago!", { type: 'info', autoClose: 2000, });
      setTimeout(() => { setIsLoad(false) }, 2000);
      return
    }
    if (data.Retenciones === null || data.Retenciones.length < 1) {
      notify("Falta agregar un impuesto o una renta valida!", { type: 'info', autoClose: 2000, });
      setTimeout(() => {
        setIsLoad(false);
      }, 2000);
      return;
    }
    if (data.ImpuestosDocSustento === null || data.ImpuestosDocSustento.length < 1) {
      notify("Falta agregar un documento sustento!", { type: 'info', autoClose: 2000, });
      setTimeout(() => {
        setIsLoad(false);
      }, 2000);
      return
    }
    if (data.Pago === null || data.Pago.length < 1) {
      notify("Falta agregar una forma de pago!", { type: 'info', autoClose: 2000, });
      setTimeout(() => {
        setIsLoad(false);
      }, 2000);
      return;
    }

    let totalFormaPago = 0;
    data.Pago.map((el) => (totalFormaPago += el.valor));

    if (roundToTwo(Number(data.ImporteTotal)) !== roundToTwo(totalFormaPago)) {
      notify("El total de la forma de pago no coincide con el total del documento!", { type: 'warning', autoClose: 2000 });
      setTimeout(() => { setIsLoad(false) }, 2000);
      return;
    }

    if (NumDocSustento !== 17) {
      notify("El Numero Documento sustento no cumple el formato", { type: 'warning', autoClose: 2000 });
      setTimeout(() => { setIsLoad(false) }, 2000);
      return;
    } else {
      setDataFromAdd([...DataFromAdd, data]);
      setDataFromAdd([...DataFromAdd, data]);
      document.getElementById("addImpuestoRetencionModalclose").click();
      resetForm();
      setTimeout(() => { setIsLoad(false) }, 2000);
    }

    setTimeout(() => { setIsLoad(false) }, 2000);
  };

  const handleSubmitEditModal = () => {
    setIsLoad(true);

    let SumaDeRentas = RentaList.reduce((total, el) => {
      let porcentajeImpuesto = JSON.parse(el.TipoImpuesto).porcentaje;
      return total + roundToTwo(el.BaseImponible * (porcentajeImpuesto + 1));
    }, 0);

    const data = {
      CodSustento: fieldValidator(getValues().CodSustento),
      CodDocSustento: fieldValidator(getValues().CodDocSustento),
      NumDocSustento: fieldValidator(getValues().NumDocSustento),
      FechaEmisionDocSustento: fieldValidator(
        getValues().FechaEmisionDocSustento
      ),
      NumAutDocSustento: fieldValidator(
        getValues().NumAutDocSustento
          ? getValues().NumAutDocSustento.trim()
          : ""
      ),
      TotalComprobantesReembolso: fieldValidator(
        getValues().TotalComprobantesReembolso
      ),
      TotalBaseImponibleReembolso: fieldValidator(
        getValues().TotalBaseImponibleReembolso
      ),
      TotalImpuestoReembolso: fieldValidator(
        getValues().TotalImpuestoReembolso
      ),
      PagExtSujRetNorLeg:
        fieldValidator(getValues().PagExtSujRetNorLeg) || false,
      TotalSinImpuestos: roundToTwo(
        fieldValidator(getValues().TotalSinImpuestos)
      ),
      ImporteTotal: SumaDeRentas,
      FechaRegistroContable: fieldValidator(getValues().FechaRegistroContable),
      PagoLocExt: fieldValidator(getValues().PagoLocExt),
      TipoRegi: fieldValidator(getValues().TipoRegi),
      PaisEfecPago: fieldValidator(getValues().PaisEfecPago) || 593,
      AplicConvDobTrib: fieldValidator(getValues().AplicConvDobTrib) || false,
      PagoRegFis: fieldValidator(getValues().PagoRegFis),
      Retenciones: fieldValidator([...RentaList, ...IvaList]),
      Pago: fieldValidator([...FormaPagoList]),
      Dividendos: fieldValidator([...DividendosList]),
      ImpuestosDocSustento: fieldValidator(
        RentaList.map((el) => {
          let TipoImp = JSON.parse(el.TipoImpuesto);
          return {
            codigoPorcentaje: TipoImp.codigo,
            baseImponible: el.BaseImponible,
            valorImpuesto: roundNumber(
              el.BaseImponible * TipoImp.porcentaje,
              2
            ),
            tipoImpuesto: 1,
            tipoImpuesto2: TipoImp.codigo,
          };
        })
      ),
    };

    if (data.NumAutDocSustento) {
      if (data.NumAutDocSustento.length !== 10 && data.NumAutDocSustento.length !== 49) {
        notify("El Codigo Autorizacion Documento sustento no es valido!", { type: 'error', autoClose: 2000 });
        setTimeout(() => { setIsLoad(false) }, 2000);
        return
      }
    }

    var NumDocSustento = data.NumDocSustento.replace(/\s/g, "").length;

    if (data.Retenciones === null || data.Retenciones.length < 1) {
      notify("Falta agregar un impuesto o una renta valida!", { type: 'info', autoClose: 2000 });
      setTimeout(() => { setIsLoad(false) }, 2000);
      return;
    }

    if (data.Pago === null || data.Pago.length < 1) {
      notify("Falta agregar una forma de pago!", { type: 'info', autoClose: 2000 });
      setTimeout(() => { setIsLoad(false) }, 2000);
      return;
    }

    if (Number(data.ImporteTotal) !== SumaDeRentas) {
      notify("El total de la forma de pago no coincide con el total del documento!", { type: 'info', autoClose: 2000 });
      setTimeout(() => { setIsLoad(false) }, 2000);
      return;
    }

    if (NumDocSustento !== 17) {
      notify("El Numero Documento sustento no cumple el formato", { type: 'info', autoClose: 2000 });
      setTimeout(() => { setIsLoad(false) }, 2000);
      return;
    } else {
      DataFromAdd[EditElement] = data;
      setDataFromAdd([...DataFromAdd]);
      document.getElementById("addImpuestoRetencionModalclose").click();
      resetForm();
      setIsEdit(false);
      setTimeout(() => { setIsLoad(false) }, 2000);
    }
  };

  const resetForm = () => {
    setRentaList([]);
    setIvaList([]);
    setFormaPagoList([]);
    setDividendosList([]);
    setTotalImpToFormPago(0);

    setValue("NumDocSustento", "");
    setValue("NumAutDocSustento", "");
    setValue("FechaEmisionDocSustento", "");
    setValue("TotalSinImpuestos", "");
    setValue("ImporteTotal", "");

    setValue("TipoRegi", "");
    setValue("FechaRegistroContable", "");
    setValue("PaisEfecPago", "");
    setValue("AplicConvDobTrib", false);
    setValue("PagExtSujRetNorLeg", false);
    setValue("PagoRegFis", false);
  };

  return (
    <LargeModalForm
      buttonModal={"addImpuestoRetencionModal"}
      submitText={IsEdit ? "Editar" : "Agregar"}
      title={IsEdit ? "Editar impuesto" : "Agregar impuesto"}
      submitModal={IsEdit ? handleSubmit(handleSubmitEditModal) : handleSubmit(handleSubmitModal)}
      IsLoading={isLoad}
    >
      <MainFields
        watch={watch}
        register={register}
        setValue={setValue}
        getValues={getValues}
        setError={setError}
        errors={errors}
      />
      <InfoOpcionalImpuesto register={register} setValue={setValue} />
      <AdicionalesImpuesto getValues={getValues} />

    </LargeModalForm>
  );
};

export default ModalAdd;
