import React, { useContext } from "react";
import {
  TableStyle,
  TableStyleDiv,
  TableStyleTBody,
  TableStyleTHead,
} from "../../../features/Constantes";
import MesageWarningTable from "../../../features/components/MesageWarningTable";
import { ReporteMasivoContext } from "./ReporteMasivoProvider";
import moment from "moment";
import { useSelector } from "react-redux";

const TablaReporteMasivo = () => {
  const usuario = useSelector((store) => store.app.user);
  const empresa = useSelector((store) => store.empresa.empresa);
  const {
    EstadosMasivos,
    ObtenerSolicitudesEmpresa,
    Actualizar,
    Paginacion,
    setPaginacion,
    formik,
    start,
    setStart,
    documentos,
    initialStatePaginacion,
    Loading
  } = useContext(ReporteMasivoContext);
  
console.log("Paginacion", Paginacion)

  const handleClickNextPage = () => {
    setStart(start + 1);
    setPaginacion({ ...Paginacion, pagina: Paginacion.pagina + 1 });
    formik.handleSubmit(formik.values);
  };

  const handleClickBeforePage = () => {
    setStart(start - 1);
    setPaginacion({ ...Paginacion, pagina: Paginacion.pagina - 1 });
    formik.handleSubmit(formik.values);
  };

  const handleClickLastPage = () => {
    setPaginacion({ ...Paginacion, pagina: Paginacion._totalpaginas });
    formik.handleSubmit(formik.values);
  };

  const handleClickFirstPage = () => {
    setPaginacion({ ...Paginacion, pagina: 1 });
    formik.handleSubmit(formik.values);
  };
  return (
    <div className="">
      <div className="">
        <div className="bg-white shadow dark:bg-gray-900 sm:rounded-lg md:py-2 md:px-4 py-2 px-2">
          <div className="bg-gray-300 dark:bg-gray-700 rounded-lg px-2 py-5">
            <h1 className=" text-2xl font-semibold text-gray-700 dark:text-gray-200">
              Reporte masivo de comprobantes
            </h1>
          </div>

          {Loading ? (
            <button className="px-4 rounded-xl my-2 py-2 border flex bg-blue-600">
              <svg
                aria-hidden="true"
                role="status"
                className="-ml-1 mr-2 h-5 w-5 my-auto animate-spin text-white"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>

              <p className="text-white">Cargando</p>
            </button>
          ) : (
            <button
            onClick={(e) => {
              setStart(0);
              setPaginacion(initialStatePaginacion);
              formik.handleSubmit(e);
            }}
              className="px-4 rounded-xl my-2 py-2 border flex bg-blue-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 28 28"
                stroke-width="2"
                stroke="currentColor"
                class="w-5 h-5 my-auto"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                  stroke="white"
                />
              </svg>

              <p className="text-white">Actualizar</p>
            </button>
          )}
        </div>
      </div>
      <div className=" py-3">
        <div className=" p-2 bg-white dark:bg-gray-700 rounded-xl ">
          <div className="px-4 sm:px-6 lg:px-8 ">
            <div className=" flex flex-col rounded-lg">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle px-1">
                  <div className={TableStyleDiv + " whitespace-nowrap"}>
                    <table className={TableStyle}>
                      <thead className={TableStyleTHead}>
                        <tr>
                          <th
                            scope="col"
                            className="py-2 text-sm font-semibold bg-gray-300 dark:bg-gray-700"
                          >
                            Empresa
                          </th>
                          <th
                            scope="col"
                            className="py-2 text-sm font-semibold bg-gray-300 dark:bg-gray-700"
                          >
                            Tipo solicitud
                          </th>
                          <th
                            scope="col"
                            className="py-2 text-sm font-semibold bg-gray-300 dark:bg-gray-700"
                          >
                            Fecha de solicitud
                          </th>
                          <th
                            scope="col"
                            className="py-2 text-sm font-semibold bg-gray-300 dark:bg-gray-700"
                          >
                            Archivo
                          </th>
                        </tr>
                      </thead>
                      <tbody className={TableStyleTBody}>
                        {documentos.length < 1 ? (
                          <MesageWarningTable
                            colSpan="11"
                            message="No se encontraron datos para la búsqueda."
                            className="py-1"
                          />
                        ) : null}
                        {documentos.length >= 1 &&
                          documentos.length >= 1 &&
                          documentos.map((obj, index) => {
                            const Estado = EstadosMasivos.find(
                              (el) => el.idEstadosMasivo === obj.idEstadosMasivo
                            );


                            return (
                              <tr key={index} className="">
                                <td className="  my-auto text-sm lg:table-cell mx-auto">
                                  {empresa.razonSocial}
                                </td>
                                <td className=" px-3 py-3.5 flex items-center justify-center  space-x-1">
                                  <p className="my-1">
                                    {obj.idTipoSolicitudMasivoNavigation.nombre}
                                  </p>
                                </td>
                                <td className=" flex justify-center items-center my-auto text-sm lg:table-cell">
                                  {moment(obj.fechaCreacion).format("LLL")}
                                </td>
                                <td className="  text-sm lg:table-cell">
                                  {(() => {
                                    switch (Estado?.codigo) {
                                      case 1: //
                                        return (
                                          <div className="flex justify-center items-center">
                                            <button
                                              disabled
                                              className="flex justify-center cursor-progress items-center border px-4 py-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                                            >
                                              <svg
                                                aria-hidden="true"
                                                role="status"
                                                className="-ml-1 mr-2 h-5 w-5 animate-spin text-white"
                                                viewBox="0 0 100 101"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                  fill="#E5E7EB"
                                                />
                                                <path
                                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                  fill="currentColor"
                                                />
                                              </svg>

                                              <p className="my-auto text-white">
                                                Cargando
                                              </p>
                                            </button>
                                          </div>
                                        );
                                      case 2:
                                        return (
                                          <div className="flex justify-center items-center">
                                            <button
                                              disabled
                                              className="flex justify-center  items-center border px-4 py-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-red-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="-ml-1 mr-2 h-5 w-5 text-white"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>

                                              <p className="my-auto text-white">
                                                Error
                                              </p>
                                            </button>
                                          </div>
                                        );

                                      case 3:
                                        return (
                                          <div className="flex justify-center items-center">
                                            <a
                                              href={obj.urlDescarga}
                                              download={obj.urlDescarga}
                                              className="flex justify-center items-center border px-4 py-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900"
                                            >
                                              <svg
                                                className="-ml-1 mr-2 h-5 w-5 text-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                  clip-rule="evenodd"
                                                />
                                              </svg>

                                              <p className="my-auto text-white">
                                                Descargar
                                              </p>
                                            </a>
                                          </div>
                                        );

                                      case 4:
                                        return (
                                          <div className="flex justify-center items-center">
                                            <button
                                              disabled
                                              className="flex justify-center  items-center border px-4 py-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="-ml-1 mr-2 h-5 w-5 text-white"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M6.72 5.66l11.62 11.62A8.25 8.25 0 006.72 5.66zm10.56 12.68L5.66 6.72a8.25 8.25 0 0011.62 11.62zM5.105 5.106c3.807-3.808 9.98-3.808 13.788 0 3.808 3.807 3.808 9.98 0 13.788-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>

                                              <p className="my-auto text-white">
                                                Cancelado
                                              </p>
                                            </button>
                                          </div>
                                        );

                                      default:
                                        break;
                                    }
                                  })()}
                                </td>
                                {/* <td className="  text-sm lg:table-cell">
                            {Estado.nombre === "ERROR" ? <p>ERROR</p> : <></>}
                            {Estado.nombre === "CANCELADO" ? (
                              <p>CANCELADO</p>
                            ) : (
                              <></>
                            )}
                            {Estado.nombre === "ERROR" ? (
                              <div className="flex justify-center items-center">
                                <button
                                  disabled
                                  className="flex justify-center cursor-progress items-center border px-4 py-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                                >
                                 
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="-ml-1 mr-2 h-5 w-5 text-red-500"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                                      clipRule="evenodd"
                                    />
                                  </svg>

                                  <p className="my-auto">Error</p>
                                </button>
                              </div>
                            ) : (
                              <></>
                            )}
                            {obj.urlDescarga === null ? (
                              <div className="flex justify-center items-center">
                                <button
                                  disabled
                                  className="flex justify-center cursor-progress items-center border px-4 py-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                                >
                                  <svg
                                    aria-hidden="true"
                                    role="status"
                                    className="-ml-1 mr-2 h-5 w-5 animate-spin text-blue-500"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="#E5E7EB"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentColor"
                                    />
                                  </svg>

                                  <p className="my-auto">Cargando</p>
                                </button>
                              </div>
                            ) : (
                              <div className="flex justify-center items-center">
                                <a
                                  href={obj.urlDescarga}
                                  download={obj.urlDescarga}
                                  className="flex justify-center items-center border px-4 py-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900"
                                >
                                  <svg
                                    className="-ml-1 mr-2 h-5 w-5 text-green-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>

                                  <p className="my-auto">Descargar</p>
                                </a>
                              </div>
                            )}
                          </td> */}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>

                  <>
                  <div className="bg-white dark:bg-gray-700 dark:text-gray-400 px-4 py-3 flex items-center justify-between border-t border-gray-200 dark:border-gray-700 sm:px-6">
                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <span className="text-sm font-normal ml-2 text-gray-400 dark:text-gray-400">
                        Mostrando
                        <span className="font-semibold text-gray-900 dark:text-white">
  {"\n"}
  {(
    Paginacion._paginaActual &&
    Paginacion._totalEnPagina &&
    Paginacion._paginaActual - 1 > 0
  ) ? (
    Paginacion._totalEnPagina < 10
      ? Math.max(Paginacion._totalelementos - Paginacion._totalEnPagina, 1)
      : (Paginacion._paginaActual - 1) * Paginacion._totalEnPagina
  ) : 1}
  {"\n"} - {"\n"}
  {(
    Paginacion._paginaActual &&
    Paginacion._totalEnPagina &&
    Paginacion._totalpaginas &&
    Paginacion._totalelementos
  ) ? (
    Paginacion._paginaActual === Paginacion._totalpaginas
      ? Paginacion._totalelementos
      : Math.max(Paginacion._totalEnPagina * Paginacion._paginaActual, 1)
  ) : 1}
  {" "}
  {"\n"}{" "}
</span>

                        de
                        <span className="font-semibold text-gray-900 dark:text-white">
                          {" "}
                          {Paginacion._totalelementos}{" "}
                        </span>{" "}
                        Registros
                      </span>
                      <div>
                        <nav
                          className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                          aria-label="Pagination"
                        >
                          {Paginacion.pagina !== 1 ? (
                            <>
                              <button
                                onClick={handleClickFirstPage}
                                type="button"
                                className="relative inline-flex items-center dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50"
                                disabled=""
                              >
                                <span className="sr-only">First</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                onClick={handleClickBeforePage}
                                type="button"
                                className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                                disabled=""
                              >
                                <span className="sr-only">Previous</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5 "
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="relative inline-flex items-center dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600 px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50"
                                disabled
                              >
                                <span className="sr-only">First</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                type="button"
                                className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                                disabled
                              >
                                <span className="sr-only">Previous</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                            </>
                          )}

                          {Paginacion.pagina <= Paginacion._totalpaginas - 1 ? (
                            <>
                              <button
                                onClick={handleClickNextPage}
                                type="button"
                                className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                              >
                                <span className="sr-only">Next</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                onClick={handleClickLastPage}
                                type="button"
                                className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50  dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                                disabled=""
                              >
                                <span className="sr-only">Last</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                  <path
                                    fillRule="evenodd"
                                    d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                disabled
                                className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50  dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                              >
                                <span className="sr-only">Next</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                type="button"
                                className="relative inline-flex items-center px-2 py-2 rounded-r-md border dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50"
                                disabled
                              >
                                <span className="sr-only">Last</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                  <path
                                    fillRule="evenodd"
                                    d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                            </>
                          )}
                        </nav>
                      </div>
                    </div>
                  </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TablaReporteMasivo;
