import React, { useContext, Fragment } from "react";
import useVistaPrevia from "../hooks/useVistaPrevia";
import { Menu, Transition } from "@headlessui/react";
import { EmitirDocContext } from "./EmitirDocProvider";
import {
  getFechaV1,
  getNoventaDiasAtras,
  rellenarCeros,
  roundToTwo,
} from "../../../services/Utilitario";
import { useSelector } from "react-redux";
const MobileButtonOptionsNotaC = () => {
  const {
    formik,
    DetalleList,
    Totales,
    InformacionAdicionalList,
    setDetalleList,
    setSaveData,
    setSaveDataMessage,
    setSaveDataSvg,
    setsaveEstado,
  } = useContext(EmitirDocContext);
  const empresa = useSelector((store) => store.empresa.empresa);
  const { GetVistaPreviaNotaCredito, IsLoading } = useVistaPrevia();
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const handleVistaPrevia = async () => {
    let parsedInfo = {
      IdentificacionContribuyente: formik.values.clienteIdentificacion,
      RazonSocialContribuyente: formik.values.clienteRazonSocial,
      correoContribuyente: formik.values.clienteEmail,
      Direccion: formik.values.clienteDireccion,
      TelefonoCelular: formik.values.clienteTelefono,
      CodigoComprobanteModificar: formik.values.codigoComprobanteModificar,
      NumeroComprobanteModificar: formik.values.numeroComprobanteModificar,
      Motivo: formik.values.motivo,
      FechaEmisionComprobanteModificar: getFechaV1(
        formik.values.fechaEmisionComprobanteModificar
      ),
      Ambiente: process.env.REACT_APP_ENVIRONMENT,
      IdEmpresa: empresa.idEmpresa,
      RucEmpresa: empresa.ruc,
      CodDoc: 4,
      FechaEmision: getFechaV1(formik.values.fechaEmision),
      ImporteTotal: Totales.importeTotal,
      TotalSinImpuestos: Totales.totalSinImpuesto,
      TotalDescuento: Totales.totalDescuentos,
      InformacionAdicionalCredito: InformacionAdicionalList.map((x) => {
        return {
          Descripcion: x.nombre,
          Valor: x.valor,
        };
      }),
      DetalleNotaCredito: DetalleList.map((e) => {
        return {
          Descripcion: e.descripcion,
          Cantidad: e.cantidad,
          CodigoPrincipal: e.codigoPrincipal,
          PrecioUnitario: e.precioUnitario,
          Descuento: 0,
          ImpuestoCodigoIva: 2,
          ImpuestoCodigoPorcentajeIva: e.idImpuestoIvaNavigation.codigo,
          PrecioTotalSinImpuesto: roundToTwo(e.subTotal),
          ImpuestoBaseImponibleIva: roundToTwo(e.subTotal),
          ImpuestoValorIva: e.iva === undefined ? 0 : roundToTwo(e.iva),
          Tarifa: roundToTwo(e.idImpuestoIvaNavigation.porcentaje * 100),
          InformacionAdicionalProductoCredito:
            e.servicioDetalle === undefined
              ? []
              : e.servicioDetalle === null
              ? []
              : e.servicioDetalle.map((x) => {
                  return {
                    Valor: x.nombre,
                    Descripcion: x.valor,
                  };
                }),
        };
      }),
      IdImpuestoNoObjectoImpuesto:
        Totales.subTotalNoObjetoIva !== 0
          ? "8E7CF164-C66B-4312-945F-57AE08C86A33"
          : "",
      IdImpuestoExentoIva:
        Totales.subTotalExentoIva !== 0
          ? "F03C7F10-2773-4505-ACCB-B2FA88D62049"
          : "",
      IdImpuestoIVA12:
        Totales.subTotalIva !== 0 ? "A5B8F607-8D38-4150-97AF-C591F83678D8" : "",
      IdImpuestoIVA0:
        Totales.subTotalIva0 !== 0
          ? "81BAF871-E113-48CF-8AA8-670CB43494B6"
          : "",
      //IdImpuestoIVA14= 8DCD2CFA-5F9D-4FB7-B27A-87447D6CDDE7
      TotalTotalIva0: 0,
      TotalTotalIva: roundToTwo(Totales.totalIva),
      TotalNoObjetoImpuesto: 0,
      TotalExentoIva: 0,
      TotalBaseIva0: Totales.subTotalIva0,
      TotalBaseIva: roundToTwo(Totales.subTotalIva),
      TotalBaseNoObjetoImpuesto: Totales.subTotalNoObjetoIva,
      TotalBaseExentoIva: Totales.subTotalExentoIva,
      Establecimiento: formik.values.establecimiento,
      PuntoEmision: formik.values.puntoEmision,
    };
    if (formik.values.clienteEmail !== null)
      if (formik.values.clienteEmail.trim() !== "")
        parsedInfo.InformacionAdicionalCredito.push({
          Valor: formik.values.clienteEmail,
          Descripcion: "email",
        });

    let data = await GetVistaPreviaNotaCredito(parsedInfo);
    let byteCharacters = atob(data);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: "application/pdf;base64" });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  return (
    <Menu as="div" className="relative  text-left my-auto lg:hidden md:hidden ">
      <div>
        <Menu.Button className="flex w-full justify-center dark:text-white  px-2  text-sm font-medium text-gray-700 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
            />
          </svg>
        </Menu.Button>  
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm"
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-5 w-5 mr-1 text-gray-400 "
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                  Limpiar
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default MobileButtonOptionsNotaC;
