import React, { createContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const globalToastifyContext = createContext();

const GlobalToastifyProvider = (props) => {
  return (
    <globalToastifyContext.Provider value={{ Toast: toast}}>
    
      {props.children}
    </globalToastifyContext.Provider>
  );
};

export default GlobalToastifyProvider;
