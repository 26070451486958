import React from "react";
import useReembolso from "../../../features/hooks/Reembolso/useReembolso";

const Reembolso = () => {
  const { ReembolsosTable, ReembolsoModal } = useReembolso();

  return (
    <>
      <ReembolsosTable />
      <ReembolsoModal />
    </>
  );
};

export default Reembolso;