import React, { useContext } from "react";
import { SmallModalInfoSri } from "../../../features/components/SmallModalInfoSri";
import { ReporteContribuyentesContext } from "./ReporteContribuyentesProvider";
export const ModalEmail = () => {
  const { InfoEmail } = useContext(ReporteContribuyentesContext);

  return (
    <SmallModalInfoSri buttonModal={"EmailModal"} title="Correos">
      <div>
        <div className="flex flex-col m-auto max-h-80 overflow-x-hidden">
          <div className="sm:-mx-6 ">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div className=" ring-1 ring-gray-300 ">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-[#DBDBDB]">
                  <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Información</th>
                  </tr>
                  </thead>
                  <tbody>
                    {InfoEmail.split(",").map((el) => {
                      if (el === "") return <></>;
                      return (
                        <tr className="bg-white border-b">
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            <h1 style={{whiteSpace: "pre-wrap"}}>{el}</h1>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SmallModalInfoSri>
  );
};
