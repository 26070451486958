import React, { createContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

export const ReporteListaEmpresasContext = createContext();


const ReporteListaEmpresasProvider = (props) => {

    const [start, setStart] = useState(0);

    const initialStatePaginacion = {
        pagina: 1,
        _totalpaginas: 1,
        _totalelementos: 0,
        _links: [],
        _inicioRegistro:0,
        _totalEnPagina:0
    }
    const [Paginacion, setPaginacion] = useState(initialStatePaginacion);

    const [documentos, setDocumentos] = useState([])
    const [cargandoData, setCargandoData] = React.useState(false);
    const [DetalleItem, setDetalleItem] = useState({});
    const [ActivarModalDetalle, setActivarModalDetalle] = useState(false);
    const [ActivarModalAdd, setActivarModalAdd] = useState(false);

    const formik = useFormik({
        initialValues: {
            fechaCreacion:'',
            razonSocial: '',
            nombreComercial: '',
            RUC: '',
            estado: '',
            cantidad: 10,
            Pagina: 1,
            tipo:0
        },
        validationSchema: Yup.object({
            razonSocial: Yup.string(),
            nombreComercial: Yup.string()

        }),
        onSubmit: async obj => {
            try {
                setCargandoData(true)

                await fetchData();

                setCargandoData(false)
            } catch (error) {
             //   console.log(error)
            }

            setCargandoData(false)
        }
    });


    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = async (
        fechaCreacion = formik.values.fechaCreacion,
        razonSocial = formik.values.razonSocial,
        nombreComercial = formik.values.nombreComercial,
        estado = formik.values.estado,
        cantidad = formik.values.cantidad,
        Pagina = Paginacion.pagina,
        RUC = formik.values.RUC
    ) => {
        try {
            //const res = await axios.get(`/api/empresas?razonSocial=${razonSocial}&start=${start}&nombreComercial=${nombreComercial}&Activo=${estado}&Cantidad=${cantidad}&Pagina=${Pagina}&RUC=${RUC}`)
            const res = await axios.get(`/api/empresas?fechaCreacion=${fechaCreacion}&razonSocial=${razonSocial}&start=${start}&nombreComercial=${nombreComercial}&Activo=${estado}&Cantidad=${cantidad}&Pagina=${Pagina}&RUC=${RUC}`)
            
            if (!(res.data === undefined)) {
                setPaginacion({
                    ...Paginacion,
                    _totalpaginas: res.data._totalpaginas,
                    _totalelementos: res.data._totalelementos,
                    _links: res.data._links,
                    _inicioRegistro:res.data._inicioRegistro,
                    _totalEnPagina:res.data._totalEnPagina
                })
                setDocumentos(res.data._embedded)
            }
                
        } catch (error) {
            setDocumentos([]);
            setPaginacion(initialStatePaginacion);
           
          //  console.log(error)
     
        }
    }

    return (
        <ReporteListaEmpresasContext.Provider value={{ start,setStart, ActivarModalDetalle, setActivarModalDetalle, documentos, cargandoData, formik, DetalleItem, setDetalleItem, setDocumentos, ActivarModalAdd, setActivarModalAdd, Paginacion, setPaginacion, initialStatePaginacion }}>
            {
                props.children
            }
        </ReporteListaEmpresasContext.Provider>
    )
}

export default ReporteListaEmpresasProvider;