import React, { useState } from "react";
import { CardPlanes } from "../Components/CardPlanes";
import { PlanNatural } from "../data/PlanNatural";
import { PlanRuc } from "../data/PlanRuc";
import { PlanJuridica } from "../data/PlanJuridica";

const FormFirmaelect = () => {
  const itemTexts = PlanNatural.map((item) => item.text);
  const itemTexts2 = PlanRuc.map((item) => item.text);
  const itemTexts3 = PlanJuridica.map((item) => item.text);
  const [Value, setValue] = useState("1ANIO");
  const precios = {
    "1ANIO": "18.00",
    "2ANIO": "28.00",
    "3ANIO": "44.69",
    "4ANIO": "55.89",
    "5ANIO": "67.09",
  };

  const active =
    "relative inline-flex items-center px-4 py-2 rounded-l-md border transition-colors duration-300  border-gray-300 bg-[#00314D] text-white text-sm font-medium text-gray-700  focus:z-10 focus:outline-none  ";
  const inactive =
    "relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500";

  return (
    <div className="  ">
      <div className="bg-white dark:bg-gray-700 rounded-md p-3">
        <div className="bg-[#DBDBDB] dark:bg-gray-800 rounded-md p-4 ">
          <p className="font-semibold text-2xl dark:text-white">Planes de Firma Electrónica</p>
          <p  className="dark:text-white">Nueva Firma Electrónica</p>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-700 p-4 my-4 rounded-lg">
        <div className="my-10  justify-center items-center grid  gap-x-2">
          <p className="dark:text-white text-center mb-6">Seleccione la vigencia:</p>
          <span className="relative z-0 inline-flex shadow-sm rounded-md ">
            <button
              onClick={() => setValue("1ANIO")}
              type="button"
              className={Value === "1ANIO" ? active : inactive}
            >
              1 Año
            </button>
            <button
              onClick={() => setValue("2ANIO")}
              type="button"
              className={
                Value === "2ANIO"
                  ? "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-[#00314D] text-white text-sm font-medium   focus:z-10 focus:outline-none "
                  : "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 transition-colors duration-300"
              }
              //className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              2 Años
            </button>
            <button
              onClick={() => setValue("3ANIO")}
              type="button"
              className={
                Value === "3ANIO"
                  ? "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-[#00314D] text-white text-sm font-medium   focus:z-10 focus:outline-none "
                  : "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 transition-colors duration-300"
              }
            >
              3 Años
            </button>
            <button
              onClick={() => setValue("4ANIO")}
              type="button"
              className={
                Value === "4ANIO"
                  ? "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-[#00314D] text-white text-sm font-medium   focus:z-10 focus:outline-none "
                  : "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 transition-colors duration-300"
              }
            >
              4 Años
            </button>
            <button
              onClick={() => setValue("5ANIO")}
              type="button"
              className={
                Value === "5ANIO"
                  ? "-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-[#00314D] text-sm font-medium text-white  focus:z-10 focus:outline-none "
                  : "-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              }
              //className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              5 Años
            </button>
          </span>
        </div>

        <div className="grid lg:grid-cols-2 gap-10 max-w-[75rem] my-6 mx-auto">
          {/* <CardPlanes
          link="https://formularios.certificados.intuitosa.com/nueva-firma/NATURAL_CEDULA"
          lista={itemTexts}
          titulo="Persona Natural con Cédula / Pasaporte"
          precio={precios[Value] || null}
         
        /> */}
          <CardPlanes
            link="https://formularios.certificados.intuitosa.com/nueva-firma/NATURAL_RUC"
            lista={itemTexts2}
            titulo="Persona Natural con RUC"
            descripcion="Firma y factura electrónicamente"
            precio={precios[Value] || null}
          />
          <CardPlanes
            link="https://formularios.certificados.intuitosa.com/nueva-firma/JURIDICA"
            lista={itemTexts3}
            titulo="Persona Jurídica"
            descripcion="Firma y factura electrónicamente a nombre de la compañía constituida"
            precio={precios[Value] || null}
          />
        </div>
      </div>
    </div>
  );
};

export default FormFirmaelect;
