import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    permisos: []
}

const slice = createSlice({
    name: 'permisosEmpresa',
    initialState,
    reducers: {
        initializeApp: () => initialState,
        cargarPermisos: (state, action) => {
            if (action.payload != null) {
                state.permisos = action.payload;
            }
        },
    },
})

export const {
    cargarPermisos,
} = slice.actions

export default slice.reducer
